import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/account.vue")),
  article: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/article.vue")),
  default: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/default.vue")),
  error: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/error.vue")),
  events: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/events.vue")),
  "main-without-footer": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/main-without-footer.vue")),
  overviews: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/overviews.vue")),
  "page-bea": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/page-bea.vue")),
  "price-assessments": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/price-assessments.vue")),
  services: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/services.vue"))
}
<template>
  <div
    class="absolute flex h-full w-full min-w-full origin-left items-center justify-between overflow-hidden transition-all duration-700 ease-in-out"
    :class="classesContainerActive"
    :style="backgroundImageGradient"
    @click="handlerClickMobile"
  >
    <!-- Image -->
    <div
      class="absolute top-0 aspect-video h-full w-full mix-blend-multiply grayscale filter md:aspect-auto"
    >
      <img
        :src="articleImage"
        class="min-h-full w-full object-cover lg:h-full"
        :alt="articleTitle"
        data-testid="image-slice"
      />
    </div>
    <div
      class="z-10 flex h-full w-full flex-col justify-between px-5 pt-7 md:px-8 md:py-5 lg:px-10 lg:pt-8"
    >
      <div class="h-fit text-white">
        <div
          class="mb-3 flex flex-col items-start justify-start md:flex-row md:items-baseline"
        >
          <ImageCommon
            :src="SOURCE_LOGO"
            classes="5xl:h-6 h-3.5 md:h-4"
            alt="Benchmark Source Logo"
          />
          <p class="font-lato ml-2 hidden text-sm font-bold uppercase md:block">
            {{ containerTitle }}
          </p>
        </div>

        <a :href="articleRoute">
          <h2
            class="line-clamp-3 text-sm font-bold md:mb-3 md:line-clamp-2 md:text-xl lg:leading-6"
            data-testid="title-slice"
          >
            {{ articleTitle }}
          </h2>
        </a>
        <div
          class="hidden text-xs md:line-clamp-2 md:text-lg"
          v-html="articleDescription"
        />
      </div>

      <ButtonCommon
        variant="tertiary-dark"
        extra-classes="hidden md:flex !w-fit mt-3 lg:mt-10"
        :link="articleRoute"
        data-testid="read-more"
      >
        Read More
      </ButtonCommon>
    </div>
  </div>
</template>
<script setup lang="ts">
/** components */
import ButtonCommon from '~/components/common/Button.vue'

/** constants */
import { DEVICE_SIZES } from '~/src/constants/common'
import { SOURCE_LOGO } from '~/src/constants/source'

/** components */
import { useScreenSize } from '~/composables/useScreenSize'

/** utils */
import { getClassesContainerActive } from '~/utils/carousel'

/** types */
import type { TArticleNode } from '~/src/types/wordpress-content'
import ImageCommon from '~/src/components/common/images/ImageCommon.vue'
import { CLOUDINARY_IMAGE_SIZES } from '~/src/constants/cloudinary'

/** props */
const props = withDefaults(
  defineProps<{
    article: TArticleNode
    indexMaximum: number
    carouselIndex: number
    articleIndex: number
  }>(),
  {
    article: null,
  },
)

/** app */
const { $cloudinary, $config } = useNuxtApp()
const { activeDevice } = useScreenSize()

/** refs */
const containerTitle = ref<string>('Latest Article')

/** computed */
const classesContainerActive = computed(() =>
  getClassesContainerActive({
    articleIndex: props.articleIndex,
    carouselIndex: props.carouselIndex,
    indexMaximum: props.indexMaximum,
  }),
)
const articleTitle = computed(() => props.article.title)
const articleDescription = computed(() => props.article.excerpt)
const articleRoute = computed(
  () => `${$config.public.sourceUrl}/article/${props.article.slug}`,
)
const articleImage = computed(() =>
  $cloudinary.resize(
    props.article.featuredImage.node.mediaItemUrl,
    CLOUDINARY_IMAGE_SIZES['hero-image'],
  ),
)
const backgroundImageGradient = computed(() => {
  if (activeDevice.value === DEVICE_SIZES.TABLET) {
    return { background: 'linear-gradient(#884609, #884609 80%)' }
  }
  return { background: 'linear-gradient(#884609 25%, #884609)' }
})

/** methods */
const handlerClickMobile = () => {
  if (activeDevice.value === DEVICE_SIZES.MOBILE) {
    window.open(articleRoute.value, '_self')
  }
}
</script>

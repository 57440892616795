<template>
  <div data-testid="hero-event" @click="handlerClickMobile">
    <div class="absolute top-0 aspect-video h-full w-full md:aspect-auto">
      <ImageCommon
        fetch-priority="auto"
        :src="eventImage"
        :alt="eventTitle"
        classes="w-full min-w-full object-cover md:h-auto md:min-h-full lg:h-full lg:w-full"
        :height="144"
        :width="405"
      />
    </div>

    <!-- Content -->
    <div
      class="relative flex h-full flex-col items-center justify-between px-5 py-7 text-white md:aspect-auto md:items-start md:px-8 md:py-5 lg:px-10 lg:pt-8"
    >
      <div class="flex flex-col justify-start md:gap-3">
        <p class="mb-3 md:mb-0">
          <ImageCommon
            fetch-priority="auto"
            :src="EVENTS_LOGO"
            alt="Benchmark Events"
            classes="h-[17px] md:h-5 5xl:h-8"
          />
        </p>

        <a
          v-if="isAbsoluteLink"
          :href="eventPath"
          target="_blank"
          class="text-sm font-bold leading-none md:text-xl"
        >
          {{ eventTitle }}
        </a>
        <nuxt-link
          v-else
          no-prefetch
          :to="eventPath"
          class="text-xs font-bold leading-none md:text-xl"
        >
          {{ eventTitle }}
        </nuxt-link>

        <p
          v-if="eventValid"
          class="hidden md:line-clamp-1 md:text-lg lg:line-clamp-none"
        >
          {{ formattedDateRange }}, {{ formattedLocation }}
        </p>
        <p v-else class="hidden md:block md:text-lg">
          Sign up to our newsletter to be notified as soon as the next event is
          announced
        </p>
      </div>

      <div class="hidden space-x-6 md:flex">
        <ButtonCommon
          v-if="eventValid"
          variant="tertiary-dark"
          :link="eventPath"
          data-testid="read-more"
        >
          Learn More
        </ButtonCommon>
        <ButtonCommon
          v-else
          variant="tertiary-dark"
          data-testid="read-more"
          @click="$modal.open('newsletter')"
        >
          Newsletter sign up
        </ButtonCommon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/** components */
import ButtonCommon from '~/components/common/Button.vue'
import ImageCommon from '~/src/components/common/images/ImageCommon.vue'

/** utils */
import { useScreenSize } from '~/composables/useScreenSize'

/** utils */
import { formatDateRange } from '~/utils/date'

/** types */
import type { TEvent } from '~/src/types/wordpress-content'

/** constants */
import { CLOUDINARY_IMAGE_SIZES } from '~/src/constants/cloudinary'
import { EVENTS_FALLBACK_IMAGE, EVENTS_LOGO } from '~/src/constants/events'

/** app */
const { $cloudinary, $modal } = useNuxtApp()
const $router = useRouter()
const { isMobile } = useScreenSize()

/** props */
const props = withDefaults(
  defineProps<{
    event: TEvent | null
    loading: boolean
  }>(),
  {
    event: null,
    loading: true,
  },
)

/** computed */
const eventValid = computed(() => props.event?.title && props.event?.url)
const formattedDateRange = computed(() => {
  if (eventValid.value) {
    return formatDateRange(
      props.event.startDateTimeUTC,
      props.event.endDateTimeUTC,
    )
  }
  return ''
})
const formattedLocation = computed(() =>
  eventValid.value && props.event?.location ? `${props.event?.location}` : '',
)
const eventPath = computed(() =>
  eventValid.value ? props.event.url : '/events',
)
const eventImage = computed(() => {
  if (!eventValid.value || !props.event.backgroundImage?.sourceUrl) {
    return EVENTS_FALLBACK_IMAGE
  }
  return $cloudinary.resize(
    props.event.backgroundImage?.sourceUrl,
    CLOUDINARY_IMAGE_SIZES['hero-event'],
  )
})
const eventTitle = computed(() =>
  eventValid.value
    ? props.event.title
    : 'There are no upcoming events at the moment',
)
const isAbsoluteLink = computed(() => {
  if (!eventPath.value) return undefined
  return (
    eventPath.value.startsWith('https') || eventPath.value.startsWith('http')
  )
})

const handlerClickMobile = () => {
  if (isMobile) {
    if (isAbsoluteLink.value) {
      window.open(eventPath.value, '_blank')
    } else {
      $router.push(eventPath.value)
    }
  }
}
</script>

import { defineNuxtPlugin } from 'nuxt/app'
import { USER_INFO } from '~/src/constants/common'
import { logError } from '~/utils/log-error'
import {
  loadLiveChat,
  openLiveChat,
  refreshLiveChat,
  removeLiveChat,
} from '~/src/utils/hubspot'
import { useLiveChatStore } from '~/stores/liveChat'

export default defineNuxtPlugin({
  name: 'bmAuth',
  dependsOn: ['cookies', 'restClient'],
  async setup(nuxtApp) {
    const { $cookies, $restClient }: any = nuxtApp
    const user = $cookies.get(USER_INFO)

    const { setEnable } = useLiveChatStore()

    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    const initLiveChat = (email: string) => {
      setEnable(false)
      $restClient
        .getUserHubspot()
        .then(({ token }) => {
          loadLiveChat({ email, token })
          setEnable(true)
        })
        .catch((e) => {
          setEnable(false)
          logError(e)
        })
    }

    if (user) {
      initLiveChat(user.email)
    }

    return {
      provide: {
        hubspotLiveChat: {
          loadLiveChat,
          openLiveChat,
          removeLiveChat,
          initLiveChat,
          refreshLiveChat,
        },
      },
    }
  },
})

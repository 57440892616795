import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { TONNE_ID, USD_ID } from '~/constants/all-prices/chart-builder'
import { getCatalogWithBlockedProducts } from '~/utils/allPrices/chartBuilder/config'
import { useChartBuilderUnitsMeasurementStore } from '~/stores/chartBuilder/unitsMeasurement'
import { useChartBuilderConfigStore } from '~/stores/chartBuilder/config'

export const useChartBuilderCatalogStore = defineStore(
  'chartBuilderCatalog',
  () => {
    const unitsMeasurementStore = useChartBuilderUnitsMeasurementStore()
    const configStore = useChartBuilderConfigStore()
    const currencies = ref<any[]>([])
    const units = ref<any[]>([])
    const products = ref<any[]>([])

    const hasProducts = computed(() => products.value.length > 0)

    const filteredUnits = computed(() => {
      const hasOnlyPayable = unitsMeasurementStore.hasOnlyPayable
      const hasOnlyKWH = unitsMeasurementStore.hasOnlyKWH

      if (hasOnlyPayable)
        return units.value.filter((unit) => unit.value === 'percentage')

      if (hasOnlyKWH) return units.value.filter((unit) => unit.value === 'kWh')

      return units.value
    })

    const filteredProducts = computed(() => {
      const selectedUnits = unitsMeasurementStore.selectedUnits
      const completedProducts = configStore.products.filter(
        (product) => product.completed,
      )

      return [...products.value].map((product) => {
        const index = completedProducts.findIndex((p) => p.id === product.id)
        if (index === -1)
          return getCatalogWithBlockedProducts(product, selectedUnits)
        return { ...product, isSelected: true }
      })
    })

    const setCurrencies = (newCurrencies: any[]) => {
      currencies.value = newCurrencies
    }

    const setUnits = (newUnits: any[]) => {
      units.value = newUnits
    }

    const setProducts = (newProducts: any[]) => {
      products.value = newProducts
    }

    const selectCurrency = (name: string) => {
      currencies.value = currencies.value.map((currency) => ({
        ...currency,
        isSelected: currency.name === name,
      }))
    }

    const selectUnit = (symbol: string) => {
      units.value = units.value.map((unit) => ({
        ...unit,
        isSelected: unit.symbol === symbol,
      }))
    }

    const updateProduct = ({ id, product }: { id: string; product: any }) => {
      const index = products.value.findIndex((p) => p.id === id)
      if (index === -1) return
      products.value.splice(index, 1, { ...products.value[index], ...product })
    }

    const reset = () => {
      currencies.value = currencies.value.map((currency) => ({
        ...currency,
        isSelected: false,
      }))
      units.value = units.value.map((unit) => ({
        ...unit,
        isSelected: false,
      }))
      products.value = products.value.map((product) => ({
        ...product,
        isSelected: false,
      }))
    }

    const autoPopulateCurrency = () => {
      const index = currencies.value.findIndex((item) => item.id === USD_ID)
      if (index === -1) return
      currencies.value.splice(index, 1, {
        ...currencies.value[index],
        isSelected: true,
      })
    }

    const autoPopulateUnit = () => {
      const index = units.value.findIndex((item) => item.id === TONNE_ID)
      if (index === -1) return
      units.value.splice(index, 1, { ...units.value[index], isSelected: true })
    }

    return {
      currencies,
      units,
      products,
      hasProducts,
      filteredUnits,
      filteredProducts,
      setCurrencies,
      setUnits,
      setProducts,
      selectCurrency,
      selectUnit,
      updateProduct,
      reset,
      autoPopulateCurrency,
      autoPopulateUnit,
    }
  },
)

<template>
  <CommonModal
    modal-name="contact"
    modal-classes="h-full w-full max-w-contact-form-container rounded-xl bg-white shadow-dp-01 max-h-[85vh] lg:max-h-[800px] relative md:pl-4"
    modal-content-classes="h-full overflow-y-auto"
    modal-header-classes="top-5 right-5 absolute !px-0 z-10"
    button-close-classes="bg-white p-2 rounded-full outline-none absolute top-0 right-0"
  >
    <template #content>
      <FormWithSideImage />
    </template>
  </CommonModal>
</template>

<script setup lang="ts">
/** components */
import CommonModal from '~/components/common/Modal.vue'

const FormWithSideImage = defineAsyncComponent(
  () => import('~/components/contact/ContactFormWithSideImage.vue'),
)
</script>

import { ref, computed } from 'vue'
import { useNuxtApp } from '#app'
import { logError } from '~/utils/log-error'

type TVimeoData = {
  player_embed_url?: string
  play?: {
    progressive?: Array<{ width: number }>
  }
  pictures?: {
    sizes: Array<{ link: string }>
  }
}

type TVimeoVideo = {
  link?: string
  height?: number
  width?: number
}

export function useGetVimeoData() {
  /*-------------------------------------
    State
  -------------------------------------*/
  const vimeoData = ref<TVimeoData | null>(null)
  const { $config, $modal } = useNuxtApp()

  const backgroundOverlay = 'rgba(0, 0,0, 70%)'

  const vimeoVideo = computed<TVimeoVideo | null>(() => {
    const link = vimeoData.value?.player_embed_url
    const urls = vimeoData.value?.play?.progressive

    if (urls?.length > 0) {
      urls.sort((a, b) => b.width - a.width)

      return { ...urls[0], link }
    }
    return null
  })

  const videoCover = computed(() => {
    if (!vimeoVideo.value) return null

    const picture = vimeoData.value?.pictures?.sizes[0]
    if (picture) {
      return `${picture.link.split('-d_')[0]}-d_${getVideoRatio('x')}?r=pad`
    }
    return null
  })

  const vimeoSource = computed(() => {
    if (vimeoVideo.value) {
      return vimeoVideo.value.link
    }
    return ''
  })

  const videoRatio = computed(() => {
    if (vimeoVideo.value) {
      return getVideoRatio()
    }
    return '1920:1080'
  })

  const getVimeoData = async (vimeoId) => {
    const vimeoAuthToken = $config.public.vimeoAuthToken
    try {
      const response = await fetch(`https://api.vimeo.com/videos/${vimeoId}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${vimeoAuthToken}`,
          'Content-Type': 'application/json',
        },
      })
      vimeoData.value = await response.json()
      return vimeoData.value
    } catch (error) {
      logError(error)
    }
  }

  const getVideoRatio = (character = ':') => {
    if (vimeoVideo.value) {
      return `${vimeoVideo.value.width}${character}${vimeoVideo.value.height}`
    }
    return `1920${character}1080`
  }

  const handlerPlay = () => {
    $modal.open('videoIntro')
  }

  return {
    vimeoData,
    backgroundOverlay,
    vimeoVideo,
    videoCover,
    vimeoSource,
    videoRatio,
    getVimeoData,
    getVideoRatio,
    handlerPlay,
  }
}

import kebabCase from 'lodash/kebabCase'
import camelCase from 'lodash/camelCase'

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()

export const titleCase = (str: string): string =>
  str
    .split(' ')
    .map((w) => capitalize(w))
    .join(' ')

interface CamelAndPascalCase {
  strLower: string
  strUpper: string
  capitalizeWord: string
  strKebabCase: string
}

export const camelAndPascalCase = (str: string): CamelAndPascalCase => {
  const strLower = camelCase(str)
  const strUpper = strLower.toUpperCase()
  const strArray = str.split('-').join(' ')
  const capitalizeWord = titleCase(strArray).replace(/\s+/g, '')
  const strKebabCase = kebabCase(str)

  return { strLower, strUpper, capitalizeWord, strKebabCase }
}

export const snakeCaseToKebabCase = (str: string): string =>
  str.replace(/_/g, '-')

export const escapeSpecialCharacter = (html: string): string => {
  const specialCharacter = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
  }

  return html.replace(/[&<>"'/]/g, (match) => specialCharacter[match] || match)
}

export const createElementFromString = (html: string): HTMLElement => {
  const root = document.createElement('div')
  root.innerHTML = html.trim()
  return root.firstChild as HTMLElement
}

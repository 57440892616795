/** constants */
import { CAN_PREVIEW_CONTENT_ROLES } from '~/src/constants/auth'

/** types */
import type { TUserRole, TUserRoles } from '~/src/types/auth'

export const getUserRolesByPayload = (roles: TUserRoles) =>
  roles.nodes.map((role) => role.name)

export const canPreviewContentByRole = (role: TUserRole) =>
  CAN_PREVIEW_CONTENT_ROLES.includes(role)

export const canPreviewContentByRoles = (roles: string[]) =>
  roles.some((role: TUserRole) => canPreviewContentByRole(role))

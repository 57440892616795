<template>
  <component
    :is="clientOnly ? 'client-only' : 'div'"
    class="flex grow flex-col"
  >
    <section
      v-if="isPreviewSite && !userCanPreviewContent"
      class="mx-auto flex max-w-xl grow flex-col justify-center px-36 py-40 text-center text-lg"
    >
      <client-only>
        <div v-if="user === undefined || user === null">
          <TheLoader />
        </div>
        <div>
          <button
            data-testid="login-button"
            class="underline"
            @click.prevent="$modal.open('signIn')"
          >
            Login
          </button>
          with an author, editor, or administrator account to see the content
          preview.
        </div>
      </client-only>
    </section>

    <slot v-else />
  </component>
</template>

<script setup lang="ts">
/** components */
import TheLoader from '~/components/TheLoader.vue'

/** stores */
import useAuthStore from '~/stores/auth'

/** app */
const { user } = useAuthStore()

/** props */
withDefaults(
  defineProps<{
    clientOnly?: boolean
  }>(),
  {
    clientOnly: false,
  },
)

/** app */
const { $config, $modal } = useNuxtApp()

/** computed */
const userCanPreviewContent = computed(() => user?.canPreviewContent ?? false)

const isPreviewSite = computed(() => $config.public.isPreviewSite)
</script>

import { defineNuxtPlugin } from 'nuxt/app'
import { BACKEND_VERSION_COOKIE } from '~/src/constants/common'

export default defineNuxtPlugin({
  name: 'backend-version-watcher',
  enforce: 'post',
  dependsOn: ['cookies', 'bmAuth'],
  setup(nuxtApp) {
    const { $bmAuth, $cookies }: any = nuxtApp

    const getBackendVersion = async (): Promise<number> => {
      const response = await $bmAuth.getBackendVersion()
      return response || 0
    }

    const getBackendVersionCookieValue = (): number => {
      return $cookies.get(BACKEND_VERSION_COOKIE)
    }

    const isBackendValid = async () => {
      const backendVersion = await getBackendVersion()
      const cookieBackendVersion = getBackendVersionCookieValue()

      /**
       * This validation is implemented this way because we currently have server issues,
       * which could cause this request to fail at any time.
       * This would result in a zero value, leading to the user’s session being closed, which we do not want.
       * This error should only occur when the versions differ.
       */
      if (backendVersion === 0) {
        return true
      }

      return backendVersion === cookieBackendVersion
    }

    const invalidateSession = () => {
      const isAuthenticated = $bmAuth.isAuthenticated()
      isBackendValid().then((isSameVersion) => {
        if (!isAuthenticated) return
        if (!isSameVersion) $bmAuth.logout()
      })
    }

    return {
      provide: {
        backendVersionWatcher: {
          invalidateSession,
        },
      },
    }
  },
})

<template>
  <div class="mb-5">
    <div class="flex flex-col items-center gap-x-7 lg:flex-row lg:items-start">
      <div class="flex w-full flex-col font-semibold">
        <p class="mb-2 text-xs">
          {{ label }}
        </p>
        <div
          class="flex max-h-[34px] w-full flex-row items-center justify-end rounded border border-r-0 border-s-500 pl-4 font-lato"
        >
          <p v-if="attachmentCounter">
            <span class="font-bold">
              {{ attachmentCounter }}
            </span>
            file(s) added.</p
          >
          <CommonButton
            variant="primary"
            type="button"
            class="text-md !h-auto w-auto !rounded-l-none !py-1"
            @click="$modal.open('fileUploader')"
          >
            Select Files
          </CommonButton>
        </div>
      </div>

      <p v-if="errorText" class="font-lato text-sm text-error">
        {{ errorText }}
      </p>
      <p
        v-else-if="helpText"
        class="text-center font-lato text-sm text-surface-em-medium lg:text-left"
      >
        {{ helpText }}
      </p>
    </div>

    <CommonFileUploaderModal
      v-show="$modal.getModal('fileUploader')"
      ref="formidable-file-uploader-modal"
      @set-files="setFiles"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import CommonButton from '~/components/common/Button.vue'
import CommonFileUploaderModal from '~/components/common/FileUploaderModal.vue'
import type { TFile, TFileUploaderField } from '~/src/types/download'

const props = withDefaults(defineProps<TFileUploaderField>(), {
  name: null,
  id: null,
  helpText: '',
  errorText: '',
})

const emit = defineEmits(['update:modelValue'])
const { $modal } = useNuxtApp()
const files = ref<Array<TFile>>([])
const attachmentCounter = computed(() => files.value.length)

watch(
  () => props.modelValue,
  (newValue) => {
    files.value = newValue
    if (!newValue?.length) reset()
  },
)

const setFiles = (newFiles: Array<TFile>) => {
  files.value = newFiles
  emit('update:modelValue', newFiles)
}

const reset = () => {
  const modalRef = ref<InstanceType<typeof CommonFileUploaderModal>>()
  modalRef.value?.reset()
}
</script>

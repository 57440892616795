import { useAuthentication } from '~/composables/useAuthentication'

const REDIRECTS = {
  sustainability: 'sustainability-index',
  'capacity-database': 'cathode',
}

export default defineNuxtRouteMiddleware((to) => {
  const path = to.path.replace(/\/$/, '').split('/')
  const market = path[1]
  const page = path[2]
  const subPage = path[3] || ''
  const { isAuthenticated } = useAuthentication()

  if (!isAuthenticated()) return

  const redirect = REDIRECTS[page]

  if (!redirect) return

  if (redirect === subPage) return

  return navigateTo(`/${market}/${page}/${redirect}`)
})

<template>
  <div class="relative mx-auto h-full w-[90%]">
    <div v-if="loading" class="absolute left-0 top-44 w-full lg:top-72">
      <TheLoader />
    </div>

    <div v-else class="h-full">
      <div class="flex h-full flex-col items-center justify-center">
        <h3 class="text-s-900 mb-12 text-3xl font-bold">
          {{ title }}
        </h3>
        <SharedCommonButtonDownload
          ref="buttonDownloadReport"
          data-testid="download-report-by-id"
          class="text-xl md:w-auto"
          :download-file="reportData"
          variant="primary"
          text="Download report"
        />
        <CommonButton
          v-if="reportCategory"
          class="mt-5 inline-block w-full justify-center"
          variant="tertiary"
          :link="returnUrl"
        >
          All reports
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import TheLoader from '~/components/TheLoader.vue'
import CommonButton from '~/components/common/Button.vue'
import SharedCommonButtonDownload from '~/components/shared/common/ButtonDownload.vue'
import type { TFileDownloadInfo } from '~/src/types/download'
import type { TMarket } from '~/src/types/markets'

const props = withDefaults(
  defineProps<{
    reportCategory: string
    reportId: string
    product?: TMarket
  }>(),
  {
    reportCategory: null,
    product: null,
  },
)

const loading = ref(true)
const reportData = ref<TFileDownloadInfo>()
const title = ref('')
const route = useRoute()

const returnUrl = computed(() => {
  const queryParams = window.location.search || ''
  return `/${route.path.split('/').slice(1, 4).join('/')}${queryParams}`
})

const { $downloadManager } = useNuxtApp()

const onInit = async () => {
  try {
    if (props.reportCategory && props.reportId) {
      const report = await $downloadManager.getDataFileByIdAndCategory(
        props.reportCategory,
        props.reportId,
      )
      reportData.value = report
      reportData.value.category = props.reportCategory
      title.value = report.post_title
    }
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await onInit()
  setTimeout(() => {
    const button = document.querySelector(
      '[data-testid="download-report-by-id"]',
    ) as HTMLElement | null
    button?.click()
  }, 500)
})
</script>

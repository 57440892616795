<template>
  <ReportsDownloadFilePage
    v-if="reportId"
    :key="`report-page-lithium-${marketSlug}`"
    :report-category="reportCategory"
    :report-id="reportId"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ReportsDownloadFilePage from '~/components/reports/DownloadFilePage.vue'
import {
  ALL_MARKETS,
  CATHODE,
  ESG,
  FORECASTS,
  GIGAFACTORY_ASSESSMENT,
  LITHIUM_ION_BATTERIES,
  MARKET,
  MARKET_ASSESSMENTS,
  PRICE_ASSESSMENTS,
  SUBSCRIPTION_MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT,
} from '~/constants/services/constants'

definePageMeta({ layout: 'overviews', middleware: ['has-active-membership'] })

const route = useRoute()
const marketsMap = ALL_MARKETS.reduce(
  (acc, market) => {
    acc[market.slug] = market
    return acc
  },
  {} as Record<string, (typeof ALL_MARKETS)[number]>,
)

const marketSlug = ref(route.params.market as string)
const market = ref(marketsMap[marketSlug.value] || null)
const subCategory = ref(route.params.subCategory as string)
const reportCategory = computed(() => {
  if (!market.value) return null
  if (
    (marketSlug.value === CATHODE.slug &&
      subCategory.value === MARKET_ASSESSMENTS.slug) ||
    (marketSlug.value === LITHIUM_ION_BATTERIES.slug &&
      subCategory.value === PRICE_ASSESSMENTS.slug)
  ) {
    return SUBSCRIPTION_MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT
  }
  if (
    marketSlug.value === LITHIUM_ION_BATTERIES.slug &&
    subCategory.value === MARKET_ASSESSMENTS.slug
  ) {
    return GIGAFACTORY_ASSESSMENT.categoryReportPricing
  }

  switch (subCategory.value) {
    case ESG.singularSlug:
      return market.value?.categoryReportEsg
    case FORECASTS.singularSlug:
      return market.value?.categoryReportForecast
    case MARKET_ASSESSMENTS.slug:
    case PRICE_ASSESSMENTS.slug:
      return market.value?.categoryReportPricing
    default:
      return null
  }
})
const reportId = ref<string | null>(null)

const { getSeoInfo } = useSeo()
const seoInfo = getSeoInfo({
  title: `${MARKET.name}: ${market.value?.name || ''} Report`,
  description:
    ALL_MARKETS[marketSlug.value]?.seo.priceAssessments.reportDescription,
})
useHead(seoInfo)

onMounted(() => {
  reportId.value = route.params.id as string
})
</script>

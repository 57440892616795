export function getArticleTerms(market, categories) {
  if (categories) {
    const formattedCategories = categories
      .map((category) => `"${category}"`)
      .join(', ')
    return `terms: [${formattedCategories}]`
  }

  const category = market.toLowerCase().replace(' ', '-')
  return `terms: ["${category}"]`
}

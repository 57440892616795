const BLOG_ARCHIVE = {
  '/graftech-takeover-zeros-in-on-new-energy-defence':
    '/blog-archive/graftech-takeover-zeros-in-on-new-energy-defence',
  '/europe-closes-in-on-conflict-minerals':
    '/blog-archive/europe-closes-in-on-conflict-minerals',
  '/disruptive-technology-supply-chain-impact':
    '/blog-archive/disruptive-technology-supply-chain-impact',
  '/benchmark-to-speak-at-foundry-seminar-germany':
    '/blog-archive/benchmark-to-speak-at-foundry-seminar-germany',
  '/china-delays-flake-graphite-restarts':
    '/blog-archive/china-delays-flake-graphite-restarts',
  '/battery-grade-graphite-set-for-record-year':
    '/blog-archive/battery-grade-graphite-set-for-record-year',
  '/flinders-graphite-start-up-doubles-europes-production-capability':
    '/blog-archive/flinders-graphite-start-up-doubles-europes-production-capability',
  '/will-teslas-gigafactory-sink-or-swim':
    '/blog-archive/will-teslas-gigafactory-sink-or-swim',
  '/inside-australias-graphite-boom':
    '/blog-archive/inside-australias-graphite-boom',
  '/batteries-leave-lithium-hydroxide-facing-tight-supply':
    '/blog-archive/batteries-leave-lithium-hydroxide-facing-tight-supply',
  '/china-admits-40-of-magnetic-rare-earths-supply-is-illegal':
    '/blog-archive/china-admits-40-of-magnetic-rare-earths-supply-is-illegal',
  '/asbury-expands-graphite-and-carbon-foothold-into-europe':
    '/blog-archive/asbury-expands-graphite-and-carbon-foothold-into-europe',
  '/tesla-gigafactory-one-year-ahead-of-schedule':
    '/blog-archive/tesla-gigafactory-one-year-ahead-of-schedule',
  '/the-battery-megafactories-are-coming':
    '/blog-archive/the-battery-megafactories-are-coming',
  '/has-china-reached-peak-graphite':
    '/blog-archive/has-china-reached-peak-graphite',
  '/deutsche-bank-evs-at-the-tipping-point':
    '/blog-archive/deutsche-bank-evs-at-the-tipping-point',
  '/how-battery-markets-can-learn-from-smartphone-disruption':
    '/blog-archive/how-battery-markets-can-learn-from-smartphone-disruption',
  '/sqm-issues-caution-over-electric-vehicles':
    '/blog-archive/sqm-issues-caution-over-electric-vehicles',
  '/benchmark-to-speak-at-phoenix-battery-event':
    '/blog-archive/benchmark-to-speak-at-phoenix-battery-event',
  '/timeline-battery-commercialisation':
    '/blog-archive/timeline-battery-commercialisation',
  '/apple-puts-batteries-at-the-centre-of-its-ev-masterplan':
    '/blog-archive/apple-puts-batteries-at-the-centre-of-its-ev-masterplan',
  '/supply-chain-20-20': '/blog-archive/supply-chain-20-20',
  '/a-new-generation-for-critical-minerals':
    '/blog-archive/a-new-generation-for-critical-minerals',
  '/benchmark-magazine-launches': '/blog-archive/benchmark-magazine-launches',
  '/atacama-floods-lithium-impact-analysed':
    '/blog-archive/atacama-floods-lithium-impact-analysed',
  '/fall-in-battery-costs-exceed-expectation':
    '/blog-archive/fall-in-battery-costs-exceed-expectation',
  '/satellite-images-show-teslas-gigafactory-ambitions':
    '/blog-archive/satellite-images-show-teslas-gigafactory-ambitions',
  '/teslas-gigafactory-batteries-could-power-3-5m-homes':
    '/blog-archive/teslas-gigafactory-batteries-could-power-3-5m-homes',
  '/tesla-evolves-with-low-cost-utility-battery-launch':
    '/blog-archive/tesla-evolves-with-low-cost-utility-battery-launch',
  '/benchmark-world-tour-dates-announced':
    '/blog-archive/benchmark-world-tour-dates-announced',
  '/benchmark-launches-graphite-price-index':
    '/blog-archive/benchmark-launches-graphite-price-index',
  '/benchmark-2-launched-europes-conflict-crackdown':
    '/blog-archive/benchmark-2-launched-europes-conflict-crackdown',
  '/tesla-powerwall-priced-for-impact-not-profit':
    '/blog-archive/tesla-powerwall-priced-for-impact-not-profit',
  '/toronto-to-host-battery-seminar-and-graphite-field-trip':
    '/blog-archive/toronto-to-host-battery-seminar-and-graphite-field-trip',
  '/europes-conflict-minerals-conundrum':
    '/blog-archive/europes-conflict-minerals-conundrum',
  '/hong-kong-to-host-battery-supply-chain-briefing':
    '/blog-archive/hong-kong-to-host-battery-supply-chain-briefing',
  '/lg-chem-stakes-claim-in-china-ev-battery-push':
    '/blog-archive/lg-chem-stakes-claim-in-china-ev-battery-push',
  '/chart-panasonic-predicts-lithium-ion-utility-storage-surge':
    '/blog-archive/chart-panasonic-predicts-lithium-ion-utility-storage-surge',
  '/toronto-new-speakers-announced-for-battery-supply-chain-seminar':
    '/blog-archive/toronto-new-speakers-announced-for-battery-supply-chain-seminar',
  '/graphic-minerals-used-in-teslas-powerwall':
    '/blog-archive/graphic-minerals-used-in-teslas-powerwall',
  '/tesla-begins-gigafactory-sourcing-with-lithium-supply-deal':
    '/blog-archive/tesla-begins-gigafactory-sourcing-with-lithium-supply-deal',
  '/teslas-elon-musk-more-gigafactory-lithium-deals-to-come-potentially-nevada':
    '/blog-archive/teslas-elon-musk-more-gigafactory-lithium-deals-to-come-potentially-nevada',
  '/goldman-sachs-wind-solar-the-new-shale-gas':
    '/blog-archive/goldman-sachs-wind-solar-the-new-shale-gas',
  '/climate-change-we-need-a-new-argument':
    '/blog-archive/climate-change-we-need-a-new-argument',
  '/benchmarkimformed-launch-graphite-supply-chain-2016':
    '/blog-archive/benchmarkimformed-launch-graphite-supply-chain-2016',
  '/chinas-ev-surge-sees-it-top-japan-as-no-1-battery-separator-producer':
    '/blog-archive/chinas-ev-surge-sees-it-top-japan-as-no-1-battery-separator-producer',
  '/vws-redemption-lies-in-electric-cars-flat-battery-packs':
    '/blog-archive/vws-redemption-lies-in-electric-cars-flat-battery-packs',
  '/faradays-future-begins-with-1bn-ev-plant-in-nevada':
    '/blog-archive/faradays-future-begins-with-1bn-ev-plant-in-nevada',
  '/chart-lithiums-price-surges-commodities-crash':
    '/blog-archive/chart-lithiums-price-surges-commodities-crash',
  '/the-future-is-now': '/blog-archive/the-future-is-now',
  '/cobalt-brings-tech-giants-supply-chains-into-the-spotlight':
    '/blog-archive/cobalt-brings-tech-giants-supply-chains-into-the-spotlight',
  '/real-world-graphene': '/blog-archive/real-world-graphene',
  '/batteries-core-to-a-fourth-industrial-revolution-world-economic-forum':
    '/blog-archive/batteries-core-to-a-fourth-industrial-revolution-world-economic-forum',
  '/tesla-powerwall-begins-to-ship-what-does-this-mean':
    '/blog-archive/tesla-powerwall-begins-to-ship-what-does-this-mean',
  '/chart-the-connected-car-when-cars-become-smartphones':
    '/blog-archive/chart-the-connected-car-when-cars-become-smartphones',
  '/gigafactory-30-of-output-for-tesla-energy-utility-batteries':
    '/blog-archive/gigafactory-30-of-output-for-tesla-energy-utility-batteries',
  '/lithium-prices-experiencing-strongest-ever-surge':
    '/blog-archive/lithium-prices-experiencing-strongest-ever-surge',
  '/south-koreas-rise-to-lithium-prominence':
    '/blog-archive/south-koreas-rise-to-lithium-prominence',
  '/cdi-on-cobalt-conflict-minerals-unjustified-stigmatisation-of-producers':
    '/blog-archive/cdi-on-cobalt-conflict-minerals-unjustified-stigmatisation-of-producers',
  '/chart-flake-graphite-capacity-utilisation-drops-below-50':
    '/blog-archive/chart-flake-graphite-capacity-utilisation-drops-below-50',
  '/comment-lithiums-state-of-affairs':
    '/blog-archive/comment-lithiums-state-of-affairs',
  '/tesla-will-need-a-lot-of-graphite-lithium-but-china-will-need-more':
    '/blog-archive/tesla-will-need-a-lot-of-graphite-lithium-but-china-will-need-more',
  '/cobalt-conflict-minerals-the-need-for-supply-chain-leaders':
    '/blog-archive/cobalt-conflict-minerals-the-need-for-supply-chain-leaders',
  '/graphite-demand-from-lithium-ion-batteries-to-more-than-treble-in-4-years':
    '/blog-archive/graphite-demand-from-lithium-ion-batteries-to-more-than-treble-in-4-years',
  '/total-makes-1-1bn-battery-bid-for-foothold-in-the-new-oil':
    '/blog-archive/total-makes-1-1bn-battery-bid-for-foothold-in-the-new-oil',
  '/elon-musk-our-lithium-ion-batteries-should-be-called-nickel-graphite':
    '/blog-archive/elon-musk-our-lithium-ion-batteries-should-be-called-nickel-graphite',
  '/note-of-thanks-london-and-dresden-lithium-ion-battery-seminars':
    '/blog-archive/note-of-thanks-london-and-dresden-lithium-ion-battery-seminars',
  '/chinas-graphite-shortage-fears-eased-by-june-rainfall':
    '/blog-archive/chinas-graphite-shortage-fears-eased-by-june-rainfall',
  '/cobalt-supply-tightens-as-battery-demand-looms':
    '/blog-archive/cobalt-supply-tightens-as-battery-demand-looms',
  '/eu-to-tackle-china-on-critical-mineral-trade':
    '/blog-archive/eu-to-tackle-china-on-critical-mineral-trade',
  '/amg-plans-lithium-entry-with-2018-production':
    '/blog-archive/amg-plans-lithium-entry-with-2018-production',
  '/lithium-contract-prices-begin-q3-move-towards-high-china-levels':
    '/blog-archive/lithium-contract-prices-begin-q3-move-towards-high-china-levels',
  '/early-bird-delegate-rate-ends-soon-for-graphite-supply-chain-2016':
    '/blog-archive/early-bird-delegate-rate-ends-soon-for-graphite-supply-chain-2016',
  '/tesla-faces-raw-material-reality-with-expanded-gigafactory':
    '/blog-archive/tesla-faces-raw-material-reality-with-expanded-gigafactory',
  '/lithium-ion-battery-exports-reach-new-peak-in-h1':
    '/blog-archive/lithium-ion-battery-exports-reach-new-peak-in-h1',
  '/cobalt-price-gains-begin-h2-surge-likely':
    '/blog-archive/cobalt-price-gains-begin-h2-surge-likely',
  '/the-lithium-ion-revolution-four-slides-from-the-benchmark-world-tour-2016':
    '/blog-archive/the-lithium-ion-revolution-four-slides-from-the-benchmark-world-tour-2016',
  '/a-decade-of-growing-pains-not-just-samsungs-problem':
    '/blog-archive/a-decade-of-growing-pains-not-just-samsungs-problem',
  '/imerys-turns-to-africa-for-its-graphite-future-first-move-by-major-to-diversify-from-china':
    '/blog-archive/imerys-turns-to-africa-for-its-graphite-future-first-move-by-major-to-diversify-from-china',
  '/do-drc-politics-spell-further-price-rises-for-cobalt-why-19-december-is-a-date-to-mark':
    '/blog-archive/do-drc-politics-spell-further-price-rises-for-cobalt-why-19-december-is-a-date-to-mark',
  '/graphite-supply-chain-2016-final-programme-announced':
    '/blog-archive/graphite-supply-chain-2016-final-programme-announced',
  '/graphite-supply-chain-2016-top-10-discussion-points':
    '/blog-archive/graphite-supply-chain-2016-top-10-discussion-points',
  '/strong-lithium-price-signs-for-2017-show-real-demand-story':
    '/blog-archive/strong-lithium-price-signs-for-2017-show-real-demand-story',
  '/china-threat-sparks-hitachis-five-fold-graphite-anode-expansion':
    '/blog-archive/china-threat-sparks-hitachis-five-fold-graphite-anode-expansion',
  '/china-begins-graphite-overhaul':
    '/blog-archive/china-begins-graphite-overhaul',
  '/a-year-in-review-2016': '/blog-archive/a-year-in-review-2016',
  '/bigger-than-giga-teslas-plan-for-3-more-battery-megafactories':
    '/blog-archive/bigger-than-giga-teslas-plan-for-3-more-battery-megafactories',
  '/bmo-capital-markets-to-host-toronto-vancouver':
    '/blog-archive/bmo-capital-markets-to-host-toronto-vancouver',
  '/donald-trump-and-elon-musks-unholy-alliance':
    '/blog-archive/donald-trump-and-elon-musks-unholy-alliance',
  '/one-month-to-go-benchmark-world-tour-toronto-vancouver-free':
    '/blog-archive/one-month-to-go-benchmark-world-tour-toronto-vancouver-free',
  '/full-circle': '/blog-archive/full-circle',
  '/benchmark-world-tour-2017-launches-in-vancouver-lithium-cobalt-high-on-agenda':
    '/blog-archive/benchmark-world-tour-2017-launches-in-vancouver-lithium-cobalt-high-on-agenda',
  '/world-depends-on-a-lithium-economy-delegates-hear-at-benchmark-world-tour-2017-toronto':
    '/blog-archive/world-depends-on-a-lithium-economy-delegates-hear-at-benchmark-world-tour-2017-toronto',
  '/lithium-ion-batteries-are-now-selling-for-under-140kwh-new-york-hears-on-benchmark-world-tour-2017':
    '/blog-archive/lithium-ion-batteries-are-now-selling-for-under-140kwh-new-york-hears-on-benchmark-world-tour-2017',
  '/benchmark-to-guest-lecture-on-lithium-ion-batteries-at-university-of-oxford':
    '/blog-archive/benchmark-to-guest-lecture-on-lithium-ion-batteries-at-university-of-oxford',
  '/electric-bus-manufacturer-proterra-to-speak-at-cathodes-2017-battery-conference':
    '/blog-archive/electric-bus-manufacturer-proterra-to-speak-at-cathodes-2017-battery-conference',
  '/benchmark-minerals-cited-in-financial-times-uk-electric-vehicle-industry-article':
    '/blog-archive/benchmark-minerals-cited-in-financial-times-uk-electric-vehicle-industry-article',
  '/chinas-battery-push-and-elon-musks-tesla-benchmark-quoted-in-bloomberg-article':
    '/blog-archive/chinas-battery-push-and-elon-musks-tesla-benchmark-quoted-in-bloomberg-article',
  '/basf-targets-cathode-supply-security-with-norilsk-nickel-cobalt-negotiations':
    '/blog-archive/basf-targets-cathode-supply-security-with-norilsk-nickel-cobalt-negotiations',
  '/cobalts-shift-to-battery-grade-needed-an-independent-price-to-reflect-it':
    '/blog-archive/cobalts-shift-to-battery-grade-needed-an-independent-price-to-reflect-it',
  '/benchmark-minerals-and-bloomberg-reach-agreement-to-cite-lithium-prices':
    '/blog-archive/benchmark-minerals-and-bloomberg-reach-agreement-to-cite-lithium-prices',
  '/electric-car-boom-drives-rush-to-minings-90-billion-hub-benchmark-quoted-in-bloomberg-article':
    '/blog-archive/electric-car-boom-drives-rush-to-minings-90-billion-hub-benchmark-quoted-in-bloomberg-article',
  '/after-electric-cars-what-more-will-it-take-for-batteries-to-change-the-face-of-energy-benchmark-quoted-in-the-economist-print-edition':
    '/blog-archive/after-electric-cars-what-more-will-it-take-for-batteries-to-change-the-face-of-energy-benchmark-quoted-in-the-economist-print-edition',
  '/cobalt-sulphate-prices-continue-rise-on-battery-demand-metal-remains-stable':
    '/blog-archive/cobalt-sulphate-prices-continue-rise-on-battery-demand-metal-remains-stable',
  '/pole-position-how-china-is-racing-ahead-in-the-electric-car-industry':
    '/blog-archive/pole-position-how-china-is-racing-ahead-in-the-electric-car-industry',
  '/over-1000-people-will-see-the-benchmark-world-tour-2017':
    '/blog-archive/over-1000-people-will-see-the-benchmark-world-tour-2017',
  '/price-chart-cobalt-lithium-ion-batteries-and-evs':
    '/blog-archive/price-chart-cobalt-lithium-ion-batteries-and-evs',
  '/benchmark-invited-to-testify-to-us-senate-on-lithium-ion-battery-supply-chains':
    '/blog-archive/benchmark-invited-to-testify-to-us-senate-on-lithium-ion-battery-supply-chains',
  '/benchmarks-written-and-verbal-testimony-to-us-senate-view-now':
    '/blog-archive/benchmarks-written-and-verbal-testimony-to-us-senate-view-now',
  '/romeo-power-to-speak-at-graphite-supply-chain-2017-on-worlds-most-energy-dense-battery-packs':
    '/blog-archive/romeo-power-to-speak-at-graphite-supply-chain-2017-on-worlds-most-energy-dense-battery-packs',
  '/china-is-constructing-four-graphite-anode-megafactories-for-the-lithium-ion-battery-surge':
    '/blog-archive/china-is-constructing-four-graphite-anode-megafactories-for-the-lithium-ion-battery-surge',
  '/benchmark-invite-to-speak-at-lme-week-opener':
    '/blog-archive/benchmark-invite-to-speak-at-lme-week-opener',
  '/benchmark-invited-back-speak-at-university-of-oxford':
    '/blog-archive/benchmark-invited-back-speak-at-university-of-oxford',
  '/lme-zeros-in-on-electric-vehicles-as-london-comes-of-age':
    '/blog-archive/lme-zeros-in-on-electric-vehicles-as-london-comes-of-age',
  '/benchmark-mineral-intelligence-cited-in-cnn-electric-vehicle-feature':
    '/blog-archive/benchmark-mineral-intelligence-cited-in-cnn-electric-vehicle-feature',
  '/al-gores-fund-releases-ev-batteries-and-sustainability-report-using-benchmark-mineral-intelligence-data':
    '/blog-archive/al-gores-fund-releases-ev-batteries-and-sustainability-report-using-benchmark-mineral-intelligence-data',
  '/where-is-new-lithium-ion-battery-capacity-located':
    '/blog-archive/where-is-new-lithium-ion-battery-capacity-located',
  '/mining-journals-top-20-most-influential-people-in-mining-2017-benchmark-mineral-intelligence-gets-honorary-mention':
    '/blog-archive/mining-journals-top-20-most-influential-people-in-mining-2017-benchmark-mineral-intelligence-gets-honorary-mention',
  '/benchmark-mineral-intelligence-cited-in-recent-cnbc-article-focussing-on-the-rising-number-of-lithium-ion-battery-megafactories-and-the-global-battery-arms-race':
    '/blog-archive/benchmark-mineral-intelligence-cited-in-recent-cnbc-article-focussing-on-the-rising-number-of-lithium-ion-battery-megafactories-and-the-global-battery-arms-race',
  '/how-much-lithium-and-cobalt-be-recycled-from-a-lithium-ion-battery':
    '/blog-archive/how-much-lithium-and-cobalt-be-recycled-from-a-lithium-ion-battery',
  '/the-times-cites-benchmark-on-battery-raw-material-pricing':
    '/blog-archive/the-times-cites-benchmark-on-battery-raw-material-pricing',
  '/benchmark-to-keynote-bank-of-america-merrill-lynch-energy-storage-conference':
    '/blog-archive/benchmark-to-keynote-bank-of-america-merrill-lynch-energy-storage-conference',
  '/qinghai-targets-mammoth-lithium-carbonate-facilities':
    '/blog-archive/qinghai-targets-mammoth-lithium-carbonate-facilities',
  '/lithium-price-assessment-new-prices-confirmed':
    '/blog-archive/lithium-price-assessment-new-prices-confirmed',
  '/graphite-price-assessment-new-prices-confirmed':
    '/blog-archive/graphite-price-assessment-new-prices-confirmed',
  '/benchmark-mineral-intelligence-launches-lithium-and-battery-supply-chain-forecasting-and-consultancy-division':
    '/blog-archive/benchmark-mineral-intelligence-launches-lithium-and-battery-supply-chain-forecasting-and-consultancy-division',
  '/benchmark-mineral-intelligence-signs-expert-china-analyst-to-expand-lithium-graphite-anode-and-cobalt-prices':
    '/blog-archive/benchmark-mineral-intelligence-signs-expert-china-analyst-to-expand-lithium-graphite-anode-and-cobalt-prices',
  '/deutsche-bank-partners-with-benchmark-for-world-tour-2018-lithium-ion-solid-state-vanadium-flow-batteries-on-agenda':
    '/blog-archive/deutsche-bank-partners-with-benchmark-for-world-tour-2018-lithium-ion-solid-state-vanadium-flow-batteries-on-agenda',
  '/albemarle-to-keynote-benchmarks-2nd-annual-lithium-ion-battery-supply-chain-conference':
    '/blog-archive/albemarle-to-keynote-benchmarks-2nd-annual-lithium-ion-battery-supply-chain-conference',
  '/benchmark-bloomberg-to-host-world-tour-2018-new-york-solid-power-to-speak-on-solid-state-batteries':
    '/blog-archive/benchmark-bloomberg-to-host-world-tour-2018-new-york-solid-power-to-speak-on-solid-state-batteries',
  '/benchmark-launches-cobalt-industrys-first-ever-battery-metal-price-first-assessment-at-97000-tonne':
    '/blog-archive/benchmark-launches-cobalt-industrys-first-ever-battery-metal-price-first-assessment-at-97000-tonne',
  '/lg-chem-huayou-cobalt-deal-reshapes-cathode-landscape-for-electric-vehicle-batteries':
    '/blog-archive/lg-chem-huayou-cobalt-deal-reshapes-cathode-landscape-for-electric-vehicle-batteries',
  '/panasonic-reduces-teslas-cobalt-consumption-by-60-in-6-years':
    '/blog-archive/panasonic-reduces-teslas-cobalt-consumption-by-60-in-6-years',
  '/benchmark-world-tour-2018-north-america-download-presentations-now':
    '/blog-archive/benchmark-world-tour-2018-north-america-download-presentations-now',
  '/benchmark-to-keynote-aabc-battery-conference-on-lithium-supply-and-ev-penetration-rates':
    '/blog-archive/benchmark-to-keynote-aabc-battery-conference-on-lithium-supply-and-ev-penetration-rates',
  '/nickel-v-cobalt-the-secret-ev-battle-for-the-lithium-ion-battery':
    '/blog-archive/nickel-v-cobalt-the-secret-ev-battle-for-the-lithium-ion-battery',
  '/plight-of-the-pra-and-how-benchmark-became-the-lithium-industrys-reference-price':
    '/blog-archive/plight-of-the-pra-and-how-benchmark-became-the-lithium-industrys-reference-price',
  '/benchmarks-lithium-ion-battery-ev-world-tour-arrives-in-london-berlin':
    '/blog-archive/benchmarks-lithium-ion-battery-ev-world-tour-arrives-in-london-berlin',
  '/big-oil-publicly-acknowledges-lithium-for-first-time-cites-lithium-industry-reference-price':
    '/blog-archive/big-oil-publicly-acknowledges-lithium-for-first-time-cites-lithium-industry-reference-price',
  '/a-new-era-for-commodities-as-sustained-lithium-price-run-marks-onset-of-ev-demand-benchmark-minerals':
    '/blog-archive/a-new-era-for-commodities-as-sustained-lithium-price-run-marks-onset-of-ev-demand-benchmark-minerals',
  '/benchmark-world-tour-2018-europe-download-presentations-here':
    '/blog-archive/benchmark-world-tour-2018-europe-download-presentations-here',
  '/general-motors-to-keynote-benchmarks-annual-lithium-ion-battery-supply-chain-conference':
    '/blog-archive/general-motors-to-keynote-benchmarks-annual-lithium-ion-battery-supply-chain-conference',
  '/have-we-underestimated-the-energy-storage-market':
    '/blog-archive/have-we-underestimated-the-energy-storage-market',
  '/teslas-gigafactory-to-be-worlds-biggest-battery-plant-but-china-will-dominate-electric-vehicle-lithium-ion-production-for-next-decade':
    '/blog-archive/teslas-gigafactory-to-be-worlds-biggest-battery-plant-but-china-will-dominate-electric-vehicle-lithium-ion-production-for-next-decade',
  '/chinas-lithium-price-decline-is-not-the-full-picture-to-an-industry-surging':
    '/blog-archive/chinas-lithium-price-decline-is-not-the-full-picture-to-an-industry-surging',
  '/financial-times-cites-benchmark-on-cobalts-role-in-electric-vehicles-solid-state-batteries':
    '/blog-archive/financial-times-cites-benchmark-on-cobalts-role-in-electric-vehicles-solid-state-batteries',
  '/benchmark-features-on-the-global-lithium-podcast':
    '/blog-archive/benchmark-features-on-the-global-lithium-podcast',
  '/benchmark-minerals-addresses-zero-emission-vehicle-summit':
    '/blog-archive/benchmark-minerals-addresses-zero-emission-vehicle-summit',
  '/download-lithium-cobalt-prices-enter-new-period-of-volatility-as-era-of-ev-begins-vanadium-awaits-lithium-ion-moment':
    '/blog-archive/download-lithium-cobalt-prices-enter-new-period-of-volatility-as-era-of-ev-begins-vanadium-awaits-lithium-ion-moment',
  '/lithium-prices-iosco-and-exchange-traded-lithium-the-rush-to-join-the-lithium-pricing-industry':
    '/blog-archive/lithium-prices-iosco-and-exchange-traded-lithium-the-rush-to-join-the-lithium-pricing-industry',
  '/benchmark-minerals-simon-moores-talks-lithium-ion-battery-megafactories-with-cobalt27':
    '/blog-archive/benchmark-minerals-simon-moores-talks-lithium-ion-battery-megafactories-with-cobalt27',
  '/benchmark-minerals-lithium-presentation-to-london-metal-exchange-download':
    '/blog-archive/benchmark-minerals-lithium-presentation-to-london-metal-exchange-download',
  '/money-never-sleeps-byds-micheal-austin-keynotes-benchmark-minerals-week-2018':
    '/blog-archive/money-never-sleeps-byds-micheal-austin-keynotes-benchmark-minerals-week-2018',
  '/has-glencore-given-electric-vehicles-the-extra-push-to-engineer-cobalt-out-of-a-battery':
    '/blog-archive/has-glencore-given-electric-vehicles-the-extra-push-to-engineer-cobalt-out-of-a-battery',
  '/exchange-traded-lithium-what-are-benchmark-minerals-key-points-for-the-lme':
    '/blog-archive/exchange-traded-lithium-what-are-benchmark-minerals-key-points-for-the-lme',
  '/benchmark-mineral-intelligence-featured-in-article-on-lithium-prices-in-the-wall-street-journal':
    '/blog-archive/benchmark-mineral-intelligence-featured-in-article-on-lithium-prices-in-the-wall-street-journal',
  '/western-australian-lithium-rising-benchmark-minerals-lithium-price-cited-by-the-financial-times':
    '/blog-archive/western-australian-lithium-rising-benchmark-minerals-lithium-price-cited-by-the-financial-times',
  '/what-has-defined-the-lithium-ion-battery-supply-chain-in-h2-2018-presentation-download':
    '/blog-archive/what-has-defined-the-lithium-ion-battery-supply-chain-in-h2-2018-presentation-download',
  '/heilongjiang-china-tops-90000-tpa-spherical-graphite-output-as-ev-battery-demand-surges':
    '/blog-archive/heilongjiang-china-tops-90000-tpa-spherical-graphite-output-as-ev-battery-demand-surges',
  '/benchmark-minerals-invited-to-speak-at-the-royal-institution-on-rise-of-the-lithium-ion-battery':
    '/blog-archive/benchmark-minerals-invited-to-speak-at-the-royal-institution-on-rise-of-the-lithium-ion-battery',
  '/are-big-oil-auto-energy-the-next-major-investors-for-lithium':
    '/blog-archive/are-big-oil-auto-energy-the-next-major-investors-for-lithium',
  '/benchmark-minerals-launches-new-lithium-price-index':
    '/blog-archive/benchmark-minerals-launches-new-lithium-price-index',
  '/lithium-supply-revisited': '/blog-archive/lithium-supply-revisited',
  '/benchmark-minerals-to-speak-at-baml-deutsche-bank-new-york-this-week':
    '/blog-archive/benchmark-minerals-to-speak-at-baml-deutsche-bank-new-york-this-week',
  '/benchmark-minerals-exchange-traded-lithium-breakfast-the-takeaways':
    '/blog-archive/benchmark-minerals-exchange-traded-lithium-breakfast-the-takeaways',
  '/toyota-panasonic-deal-offers-ev-fast-track-for-auto-battery-majors':
    '/blog-archive/toyota-panasonic-deal-offers-ev-fast-track-for-auto-battery-majors',
  '/who-is-winning-the-global-lithium-ion-battery-arms-race':
    '/blog-archive/who-is-winning-the-global-lithium-ion-battery-arms-race',
  '/benchmark-minerals-summoned-to-us-senate-again-for-energy-storage-hearing-webcast':
    '/blog-archive/benchmark-minerals-summoned-to-us-senate-again-for-energy-storage-hearing-webcast',
  '/benchmark-minerals-launches-lithium-carbonate-and-hydroxide-price-indexes':
    '/blog-archive/benchmark-minerals-launches-lithium-carbonate-and-hydroxide-price-indexes',
  '/benchmarks-minerals-simon-moores-warns-senate-us-a-bystander-in-battery-raw-materials':
    '/blog-archive/benchmarks-minerals-simon-moores-warns-senate-us-a-bystander-in-battery-raw-materials',
  '/benchmark-hails-overwhelming-reaction-to-senate-hearing-on-energy-ev-supply-chains':
    '/blog-archive/benchmark-hails-overwhelming-reaction-to-senate-hearing-on-energy-ev-supply-chains',
  '/benchmark-world-tour-2019-cape-town-download-presentations-here':
    '/blog-archive/benchmark-world-tour-2019-cape-town-download-presentations-here',
  '/benchmark-cited-by-members-of-us-international-trade-commission-graphite-cobalt-evs':
    '/blog-archive/benchmark-cited-by-members-of-us-international-trade-commission-graphite-cobalt-evs',
  '/energy-density-and-the-challenges-of-electrification-for-heavy-duty-vehicles':
    '/blog-archive/energy-density-and-the-challenges-of-electrification-for-heavy-duty-vehicles',
  '/china-lithium-field-trip-2019-announced':
    '/blog-archive/china-lithium-field-trip-2019-announced',
  '/announced-china-graphite-anodes-field-trip-2019':
    '/blog-archive/announced-china-graphite-anodes-field-trip-2019',
  '/rise-of-electric-vehicles-forces-honda-to-close-uk-car-plant-shift-base-to-asia':
    '/blog-archive/rise-of-electric-vehicles-forces-honda-to-close-uk-car-plant-shift-base-to-asia',
  '/australian-spodumene-concentrate-exports-continue-to-ramp-up':
    '/blog-archive/australian-spodumene-concentrate-exports-continue-to-ramp-up',
  '/megafactories-on-auto-majors-minds-volkswagen-need-batteries-tesla-model-y':
    '/blog-archive/megafactories-on-auto-majors-minds-volkswagen-need-batteries-tesla-model-y',
  '/jose-hofer-joins-team-benchmark-from-sqm-as-our-forecasting-division-grows':
    '/blog-archive/jose-hofer-joins-team-benchmark-from-sqm-as-our-forecasting-division-grows',
  '/volkswagen-leads-auto-majors-charge-into-lithium-market':
    '/blog-archive/volkswagen-leads-auto-majors-charge-into-lithium-market',
  '/vivas-kumar-joins-benchmark-minerals-from-tesla':
    '/blog-archive/vivas-kumar-joins-benchmark-minerals-from-tesla',
  '/new-york-toronto-chicago-san-francisco-vancouver-await-benchmark-world-tour-19':
    '/blog-archive/new-york-toronto-chicago-san-francisco-vancouver-await-benchmark-world-tour-19',
  '/cobalt-sourcing-risks-remain-high-on-the-agenda':
    '/blog-archive/cobalt-sourcing-risks-remain-high-on-the-agenda',
  '/bmw-announces-it-will-buy-cobalt-directly-from-australia-and-morocco':
    '/blog-archive/bmw-announces-it-will-buy-cobalt-directly-from-australia-and-morocco',
  '/kidman-to-be-acquired-by-australias-westfarmers':
    '/blog-archive/kidman-to-be-acquired-by-australias-westfarmers',
  '/senator-murkowski-us-government-launch-american-minerals-security-act-at-benchmark-minerals-summit-in-washington-dc':
    '/blog-archive/senator-murkowski-us-government-launch-american-minerals-security-act-at-benchmark-minerals-summit-in-washington-dc',
  '/after-cobalt-industry-focus-shifts-to-other-battery-minerals-in-responsible-sourcing':
    '/blog-archive/after-cobalt-industry-focus-shifts-to-other-battery-minerals-in-responsible-sourcing',
  '/benchmark-world-tour-2019-north-america-download-presentations-here':
    '/blog-archive/benchmark-world-tour-2019-north-america-download-presentations-here',
  '/umicore-double-down-on-european-battery-supply-chain-kokkola-purchase-confirmed':
    '/blog-archive/umicore-double-down-on-european-battery-supply-chain-kokkola-purchase-confirmed',
  '/watch-benchmark-minerals-simon-moores-speak-at-the-royal-institution':
    '/blog-archive/watch-benchmark-minerals-simon-moores-speak-at-the-royal-institution',
  '/mori-mitsuhiro-joins-benchmark-minerals-from-albemarle-japan-%e3%83%99%e3%83%b3%e3%83%81%e3%83%9e%e3%83%bc%e3%82%af%e3%83%9f%e3%83%8d%e3%83%a9%e3%83%ab%e3%81%ab%e6%a3%ae%e3%80%80%e6%ba%80%e5%8d%9a':
    '/blog-archive/mori-mitsuhiro-joins-benchmark-minerals-from-albemarle-japan-%e3%83%99%e3%83%b3%e3%83%81%e3%83%9e%e3%83%bc%e3%82%af%e3%83%9f%e3%83%8d%e3%83%a9%e3%83%ab%e3%81%ab%e6%a3%ae%e3%80%80%e6%ba%80%e5%8d%9a',
  '/benchmark-minerals-reaction-to-london-metal-exchange-lme-lithium-decision':
    '/blog-archive/benchmark-minerals-reaction-to-london-metal-exchange-lme-lithium-decision',
  '/inner-mongolia-leads-chinas-anode-expansions':
    '/blog-archive/inner-mongolia-leads-chinas-anode-expansions',
  '/bp-cites-benchmark-minerals-lithium-price-in-world-energy-report':
    '/blog-archive/bp-cites-benchmark-minerals-lithium-price-in-world-energy-report',
  '/responsible-sourcing-concerns-and-the-reconfiguration-of-the-cobalt-market':
    '/blog-archive/responsible-sourcing-concerns-and-the-reconfiguration-of-the-cobalt-market',
  '/watch-benchmark-minerals-managing-director-simon-moores-discusses-lithium-batteries-and-electric-vehicles-on-al-jazeera-news':
    '/blog-archive/watch-benchmark-minerals-managing-director-simon-moores-discusses-lithium-batteries-and-electric-vehicles-on-al-jazeera-news',
  '/benchmark-world-tour-2019-europe-download-presentations-here':
    '/blog-archive/benchmark-world-tour-2019-europe-download-presentations-here',
  '/lithiums-price-paradox': '/blog-archive/lithiums-price-paradox',
  '/ey-awards-highest-iosco-assurance-for-benchmark-minerals-lithium-prices':
    '/blog-archive/ey-awards-highest-iosco-assurance-for-benchmark-minerals-lithium-prices',
  '/benchmarks-megafactory-tracker-exceeds-2-terawatt-hours-as-solid-state-makes-its-first-appearance':
    '/blog-archive/benchmarks-megafactory-tracker-exceeds-2-terawatt-hours-as-solid-state-makes-its-first-appearance',
  '/will-plunkett-joins-from-argus-media-as-benchmark-minerals-ramps-up-price-licensing-for-lithium-cobalt-graphite-and-nickel':
    '/blog-archive/will-plunkett-joins-from-argus-media-as-benchmark-minerals-ramps-up-price-licensing-for-lithium-cobalt-graphite-and-nickel',
  '/responsible-sourcing-progresses-despite-setbacks-linked-to-army-intervention-in-drc-industrial-mines':
    '/blog-archive/responsible-sourcing-progresses-despite-setbacks-linked-to-army-intervention-in-drc-industrial-mines',
  '/the-three-tiers-of-battery-megafactories':
    '/blog-archive/the-three-tiers-of-battery-megafactories',
  '/benchmark-world-tour-2019-australia-and-asia-presentations-available-to-download':
    '/blog-archive/benchmark-world-tour-2019-australia-and-asia-presentations-available-to-download',
  '/glencores-revamped-gem-deal-could-leave-electric-vehicle-makers-short-of-sustainable-cobalt':
    '/blog-archive/glencores-revamped-gem-deal-could-leave-electric-vehicle-makers-short-of-sustainable-cobalt',
  '/lithium-needs-a-rio-tinto-to-get-to-2-2m-tonnes-demand-by-2030':
    '/blog-archive/lithium-needs-a-rio-tinto-to-get-to-2-2m-tonnes-demand-by-2030',
  '/how-does-the-electric-vehicle-supply-chain-use-benchmark-minerals-lithium-prices-in-contracts':
    '/blog-archive/how-does-the-electric-vehicle-supply-chain-use-benchmark-minerals-lithium-prices-in-contracts',
  '/mark-beveridge-joins-benchmark-minerals-from-cru-as-its-electric-vehicle-supply-chain-strategy-division-grows':
    '/blog-archive/mark-beveridge-joins-benchmark-minerals-from-cru-as-its-electric-vehicle-supply-chain-strategy-division-grows',
  '/benchmark-mineral-intelligence-briefs-the-white-house-on-creating-a-powerhouse-us-electric-vehicle-industry':
    '/blog-archive/benchmark-mineral-intelligence-briefs-the-white-house-on-creating-a-powerhouse-us-electric-vehicle-industry',
  '/lithiums-place-in-chiles-social-unrest':
    '/blog-archive/lithiums-place-in-chiles-social-unrest',
  '/graphite-anodes-2019-key-talking-points':
    '/blog-archive/graphite-anodes-2019-key-talking-points',
  '/cathodes-2019-key-talking-points':
    '/blog-archive/cathodes-2019-key-talking-points',
  '/glencore-closes-mutanda-mine-20-of-global-cobalt-supply-comes-offline':
    '/blog-archive/glencore-closes-mutanda-mine-20-of-global-cobalt-supply-comes-offline',
  '/can-nickel-sulphate-response-to-the-lithium-ion-battery-structural-shift':
    '/blog-archive/can-nickel-sulphate-response-to-the-lithium-ion-battery-structural-shift',
  '/cobalt-drc-a-reminder-of-the-salient-points':
    '/blog-archive/cobalt-drc-a-reminder-of-the-salient-points',
  '/ev-battery-arms-race-enters-new-gear-with-115-megafactories-europe-sees-most-rapid-growth':
    '/blog-archive/ev-battery-arms-race-enters-new-gear-with-115-megafactories-europe-sees-most-rapid-growth',
  '/rise-of-europes-battery-megafactories-on-demand-lithium-ion-cells-forecasting-cathode-anode-demand':
    '/blog-archive/rise-of-europes-battery-megafactories-on-demand-lithium-ion-cells-forecasting-cathode-anode-demand',
  '/all-new-blog-posts-now-under-membership-section':
    '/blog-archive/all-new-blog-posts-now-under-membership-section',
}

const PRICE_ASSESSMENTS = {
  '/mineral-client/graphite': '/natural-graphite/natural-graphite-prices/chart',
  '/mineral-client/lithium': '/lithium/lithium-prices/chart',
  '/mineral-client/cobalt': '/cobalt/cobalt-prices/chart',
  '/price-assessments/cathodes': '/cathode',
  '/price-assessments/anodes': '/anode',
  '/price-assessments/price-dashboard': '/price-dashboard',
  '/price-assessments/price-dashboard/chart-builder':
    '/price-dashboard/chart-builder',
  '/price-assessments/price-dashboard/watchlist': '/price-dashboard/watchlist',
  '/price-assessments/lithium': '/lithium',
  '/price-assessments/lithium/reports':
    '/lithium/data-reports/price-assessments',
  '/price-assessments/lithium/chart': '/lithium/lithium-prices/chart',
  '/price-assessments/lithium/analysis':
    '/lithium/market-insights/price-assessment-analysis',
  '/price-assessments/lithium/price-customisation-tool':
    '/lithium/lithium-prices/price-customisation-tool',
  '/price-assessments/cobalt': '/cobalt',
  '/price-assessments/cobalt/reports': '/cobalt/data-reports/price-assessments',
  '/price-assessments/cobalt/chart': '/cobalt/cobalt-prices/chart',
  '/price-assessments/cobalt/analysis':
    '/cobalt/market-insights/price-assessment-analysis',
  '/price-assessments/cobalt/price-customisation-tool':
    '/cobalt/cobalt-prices/price-customisation-tool',
  '/price-assessments/nickel': '/nickel',
  '/price-assessments/nickel/reports': '/nickel/data-reports/price-assessments',
  '/price-assessments/nickel/chart': '/nickel/nickel-prices/chart',
  '/price-assessments/nickel/analysis':
    '/nickel/market-insights/price-assessment-analysis',
  '/price-assessments/nickel/price-customisation-tool':
    '/nickel/nickel-prices/price-customisation-tool',
  '/price-assessments/natural-graphite': '/natural-graphite',
  '/price-assessments/natural-graphite/reports':
    '/natural-graphite/data-reports/price-assessments',
  '/price-assessments/natural-graphite/chart':
    '/natural-graphite/natural-graphite-prices/chart',
  '/price-assessments/natural-graphite/analysis':
    '/natural-graphite/market-insights/price-assessment-analysis',
  '/price-assessments/natural-graphite/price-customisation-tool':
    '/natural-graphite/natural-graphite-prices/price-customisation-tool',
  '/price-assessments/synthetic-graphite': '/synthetic-graphite',
  '/price-assessments/synthetic-graphite/reports':
    '/synthetic-graphite/data-reports/price-assessments',
  '/price-assessments/synthetic-graphite/chart':
    '/synthetic-graphite/synthetic-graphite-prices/chart',
  '/price-assessments/anode': '/anode',
  '/price-assessments/anode/reports': '/anode/data-reports/price-assessments',
  '/price-assessments/anode/chart': '/anode/anode-prices/chart',
  '/price-assessments/anode/analysis':
    '/anode/market-insights/price-assessment-analysis',
  '/price-assessments/anode/capacities': '/anode/capacity-database',
  '/price-assessments/cathode': '/cathode',
  '/price-assessments/cathode/reports':
    '/cathode/data-reports/price-assessments',
  '/price-assessments/cathode/analysis':
    '/cathode/market-insights/price-assessment-analysis',
  '/price-assessments/cathode/capacities': '/cathode/capacity-database/cathode',
  '/price-assessments/lithium-ion-batteries': '/lithium-ion-batteries',
  '/price-assessments/lithium-ion-batteries/reports':
    '/lithium-ion-batteries/data-reports/price-assessments',
  '/price-assessments/lithium-ion-batteries/chart':
    '/lithium-ion-batteries/lithium-ion-batteries-prices/chart',
  '/price-assessments/lithium-ion-batteries/analysis':
    '/lithium-ion-batteries/market-insights/price-assessment-analysis',
  '/price-assessments/rare-earths': '/rare-earths',
  '/price-assessments/rare-earths/reports':
    '/rare-earths/data-reports/price-assessments',
  '/price-assessments/rare-earths/chart':
    '/rare-earths/rare-earths-prices/chart',
  '/price-assessments/rare-earths/analysis':
    '/rare-earths/market-insights/price-assessment-analysis',
  '/price-assessments/black-mass': '/recycling',
  '/price-assessments/black-mass/reports':
    '/recycling/data-reports/price-assessments',
  '/price-assessments/black-mass/chart': '/recycling/recycling-prices/chart',
  '/price-assessments/black-mass/analysis':
    '/recycling/market-insights/price-assessment-analysis',
}

const MARKET_ASSESSMENTS = {
  '/market-assessments/sodium-ion-batteries': '/sodium-ion-batteries',
  '/market-assessments/sodium-ion-batteries/battery-cell-capacities':
    '/sodium-ion-batteries/capacity-database/battery-cell',
  '/market-assessments/sodium-ion-batteries/anode':
    '/sodium-ion-batteries/capacity-database/anode',
  '/market-assessments/sodium-ion-batteries/cathode':
    '/sodium-ion-batteries/capacity-database/cathode',
  '/market-assessments/sodium-ion-batteries/reports':
    '/sodium-ion-batteries/data-reports/market-assessments',
  '/market-assessments/precursor-assessment':
    '/cathode/capacity-database/precursor',
  '/market-assessments/precursor-assessment/capacities':
    '/cathode/capacity-database/precursor',
  '/market-assessments/precursor-assessment/reports':
    '/cathode/data-reports/market-assessments',
  '/market-assessments/gigafactory-assessment':
    '/lithium-ion-batteries/capacity-database/gigafactory',
  '/market-assessments/gigafactory-assessment/capacities':
    '/lithium-ion-batteries/capacity-database/gigafactory',
  '/market-assessments/gigafactory-assessment/tier-ranking':
    '/lithium-ion-batteries/capacity-database/tier-ranking',
  '/market-assessments/gigafactory-assessment/reports':
    '/lithium-ion-batteries/data-reports/market-assessments',
  '/megafactories': '/lithium-ion-batteries/capacity-database/gigafactory',
  '/gigafactories': '/lithium-ion-batteries/capacity-database/gigafactory',
  '/market-assessments/gigafactories':
    '/lithium-ion-batteries/capacity-database/gigafactory',
  '/market-assessments/cathodes': '/cathode',
  '/market-assessments/anodes': '/anode',
}

const FORECASTS = {
  '/forecasts/lithium': '/lithium/data-reports/forecast',
  '/forecasts/lithium/reports': '/lithium/data-reports/forecast',
  '/forecasts/cobalt': '/cobalt/data-reports/forecast',
  '/forecasts/cobalt/reports': '/cobalt/data-reports/forecast',
  '/forecasts/nickel': '/nickel/data-reports/forecast',
  '/forecasts/nickel/reports': '/nickel/data-reports/forecast',
  '/forecasts/natural-graphite': '/natural-graphite/data-reports/forecast',
  '/forecasts/natural-graphite/reports':
    '/natural-graphite/data-reports/forecast',
  '/forecasts/synthetic-graphite': '/synthetic-graphite/data-reports/forecast',
  '/forecasts/synthetic-graphite/reports':
    '/synthetic-graphite/data-reports/forecast',
  '/forecasts/anode': '/anode/data-reports/forecast',
  '/forecasts/anode/reports': '/anode/data-reports/forecast',
  '/forecasts/cathode': '/cathode/data-reports/forecast',
  '/forecasts/cathode/reports': '/cathode/data-reports/forecast',
  '/forecasts/lithium-ion-batteries':
    '/lithium-ion-batteries/data-reports/forecast',
  '/forecasts/lithium-ion-batteries/reports':
    '/lithium-ion-batteries/data-reports/forecast',
  '/forecasts/rare-earths': '/rare-earths/data-reports/forecast',
  '/forecasts/rare-earths/reports': '/rare-earths/data-reports/forecast',
  '/forecasts/solid-state': '/solid-state/data-reports/forecast',
  '/forecasts/solid-state/reports': '/solid-state/data-reports/forecast',
  '/forecasts/recycling': '/recycling/data-reports/forecast',
  '/forecasts/recycling/reports': '/recycling/data-reports/forecast',
  '/forecasts/manganese': '/manganese/data-reports/forecast',
  '/forecasts/manganese/reports': '/manganese/data-reports/forecast',
  '/forecasts/silicon-anode': '/silicon-anode/data-reports/forecast',
  '/forecasts/silicon-anode/reports': '/silicon-anode/data-reports/forecast',
  '/forecasts/fluorspar': '/fluorspar/data-reports/forecast',
  '/forecasts/fluorspar/reports': '/fluorspar/data-reports/forecast',
  '/forecasts/phosphate': '/phosphate/data-reports/forecast',
  '/forecasts/phosphate/reports': '/phosphate/data-reports/forecast',
}

const ESG = {
  '/esg': '/sustainability',
  '/esg/lca': '/lca',
  '/esg/battery-emissions-analyser': '/battery-emissions-analyser',
  '/esg/lithium': '/lithium/data-reports/sustainability',
  '/esg/lithium/reports': '/lithium/data-reports/sustainability',
  '/esg/lithium/sustainability-index': '/lithium/sustainability',
  '/esg/cobalt': '/cobalt/data-reports/sustainability',
  '/esg/cobalt/reports': '/cobalt/data-reports/sustainability',
  '/esg/cobalt/sustainability-index': '/cobalt/sustainability',
  '/esg/nickel': '/nickel/data-reports/sustainability',
  '/esg/nickel/reports': '/nickel/data-reports/sustainability',
  '/esg/nickel/sustainability-index': '/nickel/sustainability',
  '/esg/graphite': '/natural-graphite/data-reports/sustainability',
  '/esg/graphite/reports': '/natural-graphite/data-reports/sustainability',
  '/esg/graphite/sustainability-index': '/natural-graphite/sustainability',
  '/esg/rare-earths': '/rare-earths/data-reports/sustainability',
  '/esg/rare-earths/reports': '/rare-earths/data-reports/sustainability',
  '/esg/rare-earths/sustainability-index': '/rare-earths/sustainability',
}

export default {
  ...BLOG_ARCHIVE,
  ...PRICE_ASSESSMENTS,
  ...MARKET_ASSESSMENTS,
  ...FORECASTS,
  ...ESG,
  '/forecasts/graphite': '/natural-graphite/data-reports/forecast',
  '/price-assessments/graphite/reports':
    '/natural-graphite/data-reports/price-assessments',
  '/market-assessments/sodium-ion-batteries/anode-capacities':
    '/sodium-ion-batteries/capacity-database/anode',
  '/market-assessments/sodium-ion-batteries/cathode-capacities':
    '/sodium-ion-batteries/capacity-database/cathode',
  '/market-assessments/battery-cells':
    '/lithium-ion-batteries/data-reports/market-assessments/',
  '/tools/battery-emissions-analyser': '/battery-emissions-analyser',
  '/forecasts/battery-database': '/lithium-ion-batteries/data-reports/forecast',
  '/webinar-sign-up': '/events/webinars',
  '/price-assessments/cathode/chart/': '/cathode/cathode-prices/chart',
  '/synthetic-graphite/analysis':
    '/synthetic-graphite/data-reports/price-assessments',
  '/price-assessments/raw-material-index': '/lithium/sustainability/overview/',
  '/membership/3d-printing-casts-new-dimension-on-metals':
    'https://source.benchmarkminerals.com/article/3d-printing-casts-new-dimension-on-metals',
  '/blog/membership/a-new-generation':
    'https://source.benchmarkminerals.com/article/a-new-generation',
  '/membership/a-new-generation':
    'https://source.benchmarkminerals.com/article/a-new-generation',
  '/blog/membership/a-pilgrims-progress':
    'https://source.benchmarkminerals.com/article/a-pilgrims-progress',
  '/membership/a-pilgrims-progress':
    'https://source.benchmarkminerals.com/article/a-pilgrims-progress',
  '/blog/membership/albemarle-shifts-focus-to-spodumene-but-fails-to-address-primary-lithium-supply':
    'https://source.benchmarkminerals.com/article/albemarle-shifts-focus-to-spodumene-but-fails-to-address-primary-lithium-supply',
  '/membership/albemarle-shifts-focus-to-spodumene-but-fails-to-address-primary-lithium-supply':
    'https://source.benchmarkminerals.com/article/albemarle-shifts-focus-to-spodumene-but-fails-to-address-primary-lithium-supply',
  '/blog/membership/are-battery-regulations-up-in-the-air':
    'https://source.benchmarkminerals.com/article/are-battery-regulations-up-in-the-air',
  '/membership/are-battery-regulations-up-in-the-air':
    'https://source.benchmarkminerals.com/article/are-battery-regulations-up-in-the-air',
  '/blog/membership/argentina-open-for-lithium-business-as-chiles-biggest-producer-invests':
    'https://source.benchmarkminerals.com/article/argentina-open-for-lithium-business-as-chiles-biggest-producer-invests',
  '/membership/argentina-open-for-lithium-business-as-chiles-biggest-producer-invests':
    'https://source.benchmarkminerals.com/article/argentina-open-for-lithium-business-as-chiles-biggest-producer-invests',
  '/blog/membership/batteries-can-spark-a-fourth-industrial-revolution-2':
    'https://source.benchmarkminerals.com/article/batteries-can-spark-a-fourth-industrial-revolution-2',
  '/membership/batteries-can-spark-a-fourth-industrial-revolution-2':
    'https://source.benchmarkminerals.com/article/batteries-can-spark-a-fourth-industrial-revolution-2',
  '/blog/membership/batteries-can-spark-a-fourth-industrial-revolution':
    'https://source.benchmarkminerals.com/article/batteries-can-spark-a-fourth-industrial-revolution',
  '/membership/batteries-can-spark-a-fourth-industrial-revolution':
    'https://source.benchmarkminerals.com/article/batteries-can-spark-a-fourth-industrial-revolution',
  '/blog/membership/battery-grade-graphite-set-for-record-year':
    'https://source.benchmarkminerals.com/article/battery-grade-graphite-set-for-record-year',
  '/membership/battery-grade-graphite-set-for-record-year':
    'https://source.benchmarkminerals.com/article/battery-grade-graphite-set-for-record-year',
  '/blog/membership/battery-grade-graphite-shortage-on-horizon-as-demand-rockets':
    'https://source.benchmarkminerals.com/article/battery-grade-graphite-shortage-on-horizon-as-demand-rockets',
  '/membership/battery-grade-graphite-shortage-on-horizon-as-demand-rockets':
    'https://source.benchmarkminerals.com/article/battery-grade-graphite-shortage-on-horizon-as-demand-rockets',
  '/blog/membership/billion-dollar-battery-bid-lays-marker-for-new-oil':
    'https://source.benchmarkminerals.com/article/billion-dollar-battery-bid-lays-marker-for-new-oil',
  '/membership/billion-dollar-battery-bid-lays-marker-for-new-oil':
    'https://source.benchmarkminerals.com/article/billion-dollar-battery-bid-lays-marker-for-new-oil',
  '/blog': '/blog-archive',
  '/blog/membership/chiles-lithium-state-of-mind':
    'https://source.benchmarkminerals.com/article/chiles-lithium-state-of-mind',
  '/membership/chiles-lithium-state-of-mind':
    'https://source.benchmarkminerals.com/article/chiles-lithium-state-of-mind',
  '/blog/membership/china-loses-re-case-but-maintains-grip':
    'https://source.benchmarkminerals.com/article/china-loses-re-case-but-maintains-grip',
  '/membership/china-loses-re-case-but-maintains-grip':
    'https://source.benchmarkminerals.com/article/china-loses-re-case-but-maintains-grip',
  '/blog/membership/china-overtakes-japan-as-leading-separator-producer-amid-auto-surge':
    'https://source.benchmarkminerals.com/article/china-overtakes-japan-as-leading-separator-producer-amid-auto-surge',
  '/membership/china-overtakes-japan-as-leading-separator-producer-amid-auto-surge':
    'https://source.benchmarkminerals.com/article/china-overtakes-japan-as-leading-separator-producer-amid-auto-surge',
  '/blog/membership/cobalt-conflict-minerals-and-the-unjustified-stigmatisation-of-producers':
    'https://source.benchmarkminerals.com/article/cobalt-conflict-minerals-and-the-unjustified-stigmatisation-of-producers',
  '/membership/cobalt-conflict-minerals-and-the-unjustified-stigmatisation-of-producers':
    'https://source.benchmarkminerals.com/article/cobalt-conflict-minerals-and-the-unjustified-stigmatisation-of-producers',
  '/blog/membership/cobalts-hi-tech-blueprint':
    'https://source.benchmarkminerals.com/article/cobalts-hi-tech-blueprint',
  '/membership/cobalts-hi-tech-blueprint':
    'https://source.benchmarkminerals.com/article/cobalts-hi-tech-blueprint',
  '/blog/membership/copper-conflicted':
    'https://source.benchmarkminerals.com/article/copper-conflicted',
  '/membership/copper-conflicted':
    'https://source.benchmarkminerals.com/article/copper-conflicted',
  '/blog/membership/copper-slump-risks-a-crisis-for-cobalt':
    'https://source.benchmarkminerals.com/article/copper-slump-risks-a-crisis-for-cobalt',
  '/membership/copper-slump-risks-a-crisis-for-cobalt':
    'https://source.benchmarkminerals.com/article/copper-slump-risks-a-crisis-for-cobalt',
  '/blog/membership/critical-mineral-trends-for-2015':
    'https://source.benchmarkminerals.com/article/critical-mineral-trends-for-2015',
  '/membership/critical-mineral-trends-for-2015':
    'https://source.benchmarkminerals.com/article/critical-mineral-trends-for-2015',
  '/blog/membership/daimler-doubles-down-on-energy-storage-masterplan':
    'https://source.benchmarkminerals.com/article/daimler-doubles-down-on-energy-storage-masterplan',
  '/membership/daimler-doubles-down-on-energy-storage-masterplan':
    'https://source.benchmarkminerals.com/article/daimler-doubles-down-on-energy-storage-masterplan',
  '/blog/membership/disruptive-innovation-2':
    'https://source.benchmarkminerals.com/article/disruptive-innovation-2',
  '/membership/disruptive-innovation-2':
    'https://source.benchmarkminerals.com/article/disruptive-innovation-2',
  '/blog/membership/disruptive-innovation':
    'https://source.benchmarkminerals.com/article/disruptive-innovation',
  '/membership/disruptive-innovation':
    'https://source.benchmarkminerals.com/article/disruptive-innovation',
  '/blog/membership/enrc-suspends-worlds-largest-cobalt-metal-refinery':
    'https://source.benchmarkminerals.com/article/enrc-suspends-worlds-largest-cobalt-metal-refinery',
  '/membership/enrc-suspends-worlds-largest-cobalt-metal-refinery':
    'https://source.benchmarkminerals.com/article/enrc-suspends-worlds-largest-cobalt-metal-refinery',
  '/blog/membership/europe-moves-a-step-closer-to-conflict-minerals-crackdown':
    'https://source.benchmarkminerals.com/article/europe-moves-a-step-closer-to-conflict-minerals-crackdown',
  '/membership/europe-moves-a-step-closer-to-conflict-minerals-crackdown':
    'https://source.benchmarkminerals.com/article/europe-moves-a-step-closer-to-conflict-minerals-crackdown',
  '/blog/membership/europes-conflict-conundrum':
    'https://source.benchmarkminerals.com/article/europes-conflict-conundrum',
  '/membership/europes-conflict-conundrum':
    'https://source.benchmarkminerals.com/article/europes-conflict-conundrum',
  '/blog/membership/europes-conflict-conundrum-2':
    'https://source.benchmarkminerals.com/article/europes-conflict-conundrum-2',
  '/membership/europes-conflict-conundrum-2':
    'https://source.benchmarkminerals.com/article/europes-conflict-conundrum-2',
  '/blog/membership/evs-at-the-tipping-point-says-deutsche-bank':
    'https://source.benchmarkminerals.com/article/evs-at-the-tipping-point-says-deutsche-bank',
  '/membership/evs-at-the-tipping-point-says-deutsche-bank':
    'https://source.benchmarkminerals.com/article/evs-at-the-tipping-point-says-deutsche-bank',
  '/blog/membership/eye-of-the-storm-vw-electric-vehicles':
    'https://source.benchmarkminerals.com/article/eye-of-the-storm-vw-electric-vehicles',
  '/membership/eye-of-the-storm-vw-electric-vehicles':
    'https://source.benchmarkminerals.com/article/eye-of-the-storm-vw-electric-vehicles',
  '/blog/membership/faradays-future-begins-with-1bn-ev-plant-construction-in-nevada':
    'https://source.benchmarkminerals.com/article/faradays-future-begins-with-1bn-ev-plant-construction-in-nevada',
  '/membership/faradays-future-begins-with-1bn-ev-plant-construction-in-nevada':
    'https://source.benchmarkminerals.com/article/faradays-future-begins-with-1bn-ev-plant-construction-in-nevada',
  '/blog/membership/fears-of-lithium-supply-shortage-become-reality':
    'https://source.benchmarkminerals.com/article/fears-of-lithium-supply-shortage-become-reality',
  '/membership/fears-of-lithium-supply-shortage-become-reality':
    'https://source.benchmarkminerals.com/article/fears-of-lithium-supply-shortage-become-reality',
  '/blog/membership/formula-club-e-the-new-class':
    'https://source.benchmarkminerals.com/article/formula-club-e-the-new-class',
  '/membership/formula-club-e-the-new-class':
    'https://source.benchmarkminerals.com/article/formula-club-e-the-new-class',
  '/blog/membership/gates-it-is-too-expensive-to-store-energy':
    'https://source.benchmarkminerals.com/article/gates-it-is-too-expensive-to-store-energy',
  '/membership/gates-it-is-too-expensive-to-store-energy':
    'https://source.benchmarkminerals.com/article/gates-it-is-too-expensive-to-store-energy',
  '/blog/membership/geopolitics-of-mineral-supply':
    'https://source.benchmarkminerals.com/article/geopolitics-of-mineral-supply',
  '/membership/geopolitics-of-mineral-supply':
    'https://source.benchmarkminerals.com/article/geopolitics-of-mineral-supply',
  '/blog/membership/graftech-takeover-targets-new-energy-and-defence-markets':
    'https://source.benchmarkminerals.com/article/graftech-takeover-targets-new-energy-and-defence-markets',
  '/membership/graftech-takeover-targets-new-energy-and-defence-markets':
    'https://source.benchmarkminerals.com/article/graftech-takeover-targets-new-energy-and-defence-markets',
  '/blog/membership/graphites-first-price-index-launched':
    'https://source.benchmarkminerals.com/article/graphites-first-price-index-launched',
  '/membership/graphites-first-price-index-launched':
    'https://source.benchmarkminerals.com/article/graphites-first-price-index-launched',
  '/blog/membership/green-tech-evolution-of-high-purity-alumina':
    'https://source.benchmarkminerals.com/article/green-tech-evolution-of-high-purity-alumina',
  '/membership/green-tech-evolution-of-high-purity-alumina':
    'https://source.benchmarkminerals.com/article/green-tech-evolution-of-high-purity-alumina',
  '/blog/membership/has-critical-cobalt-been-overlooked':
    'https://source.benchmarkminerals.com/article/has-critical-cobalt-been-overlooked',
  '/membership/has-critical-cobalt-been-overlooked':
    'https://source.benchmarkminerals.com/article/has-critical-cobalt-been-overlooked',
  '/blog/membership/real-world-graphine':
    'https://source.benchmarkminerals.com/article/real-world-graphine',
  '/membership/real-world-graphine':
    'https://source.benchmarkminerals.com/article/real-world-graphine',
  '/membership': 'https://source.benchmarkminerals.com',
  '/media': 'https://source.benchmarkminerals.com/downloads/presentations',
  '/membership/benchmark-launches-lithium-ion-raw-material-price-index':
    'https://source.benchmarkminerals.com/article/benchmark-launches-lithium-ion-battery-raw-material-price-index',
  '/membership/benchmark-launches-lithium-ion-battery-raw-material-price-index':
    'https://source.benchmarkminerals.com/article/benchmark-launches-lithium-ion-battery-raw-material-price-index',
  '/unrepresentative-lme-lithium-prices-hamper-attempts-to-modernise-industry':
    '',
  '/membership/canada-puts-breaks-on-chinas-4-billion-lithium-acquisition-spree':
    'https://source.benchmarkminerals.com',
  '/megafactories/megafactory-tier-rankings':
    '/market-assessments/gigafactory-assessment/tier-ranking',
  '/anode-market-assessment': '/price-assessments/anode',
  '/cathode-market-assessment': '/price-assessments/cathode',
  '/mineral-client/cobalt-data-analysis': '/price-assessments/cobalt/reports',
  '/mineral-client/nickel-data-analysis': '/price-assessments/nickel/reports',
  '/mineral-client/lithium-data-analysis': '/price-assessments/lithium/reports',
  '/mineral-client/graphite-data-analysis':
    '/price-assessments/graphite/reports',
  '/membership-subscription':
    'https://source.benchmarkminerals.com/request-a-trial',
  '/consultancy/supply-chain-playbook-strategies': '/consultancy',
  '/archive': 'https://source.benchmarkminerals.com/downloads/presentations',
  '/battery-supply-chain-blog': '/blog-archive',
  '/magazine-archive':
    'https://source.benchmarkminerals.com/downloads/magazine',
  '/video-library': 'https://source.benchmarkminerals.com/video',
  '/webinars': '/events',
  '/events/benchmarksummit2021': '/events/battery-gigafactories-usa-2022',
  '/events/benchmarksummit2022': '/events/battery-gigafactories-usa-2022',
  '/events/battery-megafactories-europe-2021':
    '/events/battery-megafactories-europe-2022',
  '/events/battery-megafactories-europe-2021/location':
    '/events/battery-megafactories-europe-2022/location',
  '/events/battery-megafactories-europe-2021/register':
    '/events/battery-megafactories-europe-2022/register',
  '/worldtour2017': '/events/world-tour-2017',
  '/germany-2016': '/events',
  '/melboure-2015': '/events',
  '/sydney-2016': '/events',
  '/lithium-price-assessment-portal':
    '/lithium/market-insights/price-assessment-analysis',
  '/events/latin-american-lithium-white-gold-in-the-age-of-resource-nationalism':
    '/events/lithium-resource-nationalism-shifting-policies-in-chile-and-the-impact-on-lithium-in-south-america',
  '/events/battery-gigafactories-asia-pacific-2022':
    'https://events.benchmarkminerals.com/gigaapac2022',
  '/events/battery-gigafactories-asia-pacific-2022/register':
    'https://events.benchmarkminerals.com/bwLnA',
  '/events/benchmark-week-2021':
    'https://events.benchmarkminerals.com/d/rfqx5b',
  '/events/benchmark-week-2022':
    'https://events.benchmarkminerals.com/d/rfqx5b',
  '/events/battery-gigafactories-usa-2022/register':
    'https://events.benchmarkminerals.com/d/sfq4x4',
  '/events/battery-gigafactories-asia-pacific':
    'https://benchmarkevents.benchmarkminerals.com/gigaapac',
  '/events/world-tour-2023-toronto':
    'https://benchmarkevents.benchmarkminerals.com/worldtourtoronto',
  '/events/benchmark-week-2023':
    'https://benchmarkevents.benchmarkminerals.com/benchmarkweek23',
}

import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { TUser } from '~/src/types/auth'

const useAuthStore = defineStore('auth', () => {
  const jwtToken = ref<string | null>(null)
  const user = ref<TUser | null>(null)

  const authenticated = computed(() => !!jwtToken.value)
  const userRoles = computed<string[]>(() => user.value?.roles ?? [])
  const fullName = computed(() => {
    if (!user.value) return ''
    return `${user.value?.firstName} ${user.value?.lastName}`
  })

  const setJwtToken = (token: string) => {
    jwtToken.value = token
  }

  const setUser = (value: TUser) => {
    const sentryUser = value
      ? { id: value.id, email: value.email, username: value.name }
      : null
    Sentry.setUser(sentryUser)
    user.value = value
  }

  return {
    jwtToken,
    user,
    authenticated,
    userRoles,
    fullName,
    setJwtToken,
    setUser,
  }
})

export default useAuthStore

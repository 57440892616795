import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { TGeneral } from '~/src/types/common'

const DEFAULT_MODALS = {
  signInOpen: false,
  forgotPasswordOpen: false,
  contactOpen: false,
  subscribeOpen: false,
  fileUploaderOpen: false,
  requestForProposalOpen: false,
  esgReportsOpen: false,
  eventsModalOpen: false,
  priceAssessmentFiltersOpen: false,
  priceAssessmentControlDocumentsOpen: false,
  mobileMenuOpen: false,
  mobileAccountMenuOpen: false,
  tableIndexShareOpen: false,
  newsletterOpen: false,
  capexTrackerOpen: false,
  trialConversionsOpen: false,
  updateAccountDetailRequestOpen: false,
  changePasswordOpen: false,
  eventOpen: false,
  videoIntroOpen: false,
  capexTrackerContactOpen: false,
  industryReportsOpen: false,
  chartBuilderResetOpen: false,
  chartBuilderCloseOpen: false,
  deleteWatchlistOpen: false,
  chartBuilderDataSeriesWarningOpen: false,
  clearChartOpen: false,
  allPricesTableOpen: false,
}

export const useModalStore = defineStore('modal', () => {
  const modals = ref(DEFAULT_MODALS)
  const callback = ref(null)
  const initialFields = ref<any>(null)
  const modalParams = ref<any>(null)
  const subscriptionRenewData = ref<TGeneral>({})

  const open = (modalName: string) => {
    modals.value[`${modalName}Open`] = true
  }

  const close = (modalName: string) => {
    modals.value[`${modalName}Open`] = false
  }

  const setRenewData = (data: Record<string, any>) => {
    subscriptionRenewData.value = data
  }

  const setInitialFields = (fields: any) => {
    initialFields.value = fields
  }

  const getModal = (modalName: string): boolean => {
    return modals.value[`${modalName}Open`]
  }

  return {
    modals,
    callback,
    initialFields,
    modalParams,
    subscriptionRenewData,
    getModal,
    open,
    close,
    setRenewData,
    setInitialFields,
  }
})

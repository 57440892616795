import type { TArticlesQuery } from '~/types/graphql.type'

import { gql } from 'graphql-tag'
import { camelAndPascalCase } from '~/utils/string'
import { marketsWithAnalysis } from '~/queries/market-insights'
import { getArticleTerms } from '~/src/utils/graphql-query'

export const buildPortalArticlesQuery = ({
  market,
  categories = null,
}: TArticlesQuery) => {
  const { strLower } = camelAndPascalCase(market)
  const termsArticles = getArticleTerms(market, categories)

  const marketAnalyses = `${strLower}Analyses`

  const hasAnalysis = marketsWithAnalysis.includes(market)

  const analysisArticles = hasAnalysis
    ? `analysisArticles: ${marketAnalyses}(first: 5) {
    nodes {
      id: databaseId
      title
      slug
      date
      excerpt
      __typename
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }`
    : ''

  return gql`
    query getPortalArticlesData {
      articles: memberships(
        first: 5
        where: {
          taxQuery: {
            relation: OR
            taxArray: [
              {
                ${termsArticles}
                taxonomy: CATEGORY
                operator: IN
                field: SLUG
              }
            ]
          }
        }
      ) {
        nodes {
          id: databaseId
          title
          slug
          date
          excerpt
          __typename
          memberships {
            estimatedReadingTime
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
      ${analysisArticles}
    }
  `
}

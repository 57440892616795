/** utils */
import { cloudinaryBuildRoute } from '~/src/utils/cloudinary'

export const LOGIN_IMAGES = [
  'v1678221022/assets/login/login-1_vp84jy.jpg?_i=AA',
  'v1678221017/assets/login/login-2_seohqt.jpg?_i=AA',
  'v1678221022/assets/login/login-3_tf2cya.jpg?_i=AA',
  'v1678221018/assets/login/login-4_wmwnzh.jpg?_i=AA',
  'v1678221025/assets/login/login-5_eqhp6y.jpg?_i=AA',
  'v1678221014/assets/login/login-6_yiuoi1.jpg?_i=AA',
]

export const MANAGE_PASSWORD_IMAGES = {
  desktop: cloudinaryBuildRoute(
    'v1733845679/assets/password/bg-reset-password-desktop_aeuxr3.png',
    ['w_720'],
  ),
  mobile: cloudinaryBuildRoute(
    'v1733789090/assets/password/bg-reset-password-mobile_ke2ocd.png',
    ['w_420'],
  ),
}

export const ROLE_USER = {
  ADMIN: 'administrator',
  AUTHOR: 'author',
  EDITOR: 'editor',
  SUBSCRIBER: 'subscriber',
} as const

export const CAN_PREVIEW_CONTENT_ROLES: string[] = [
  ROLE_USER.ADMIN,
  ROLE_USER.EDITOR,
  ROLE_USER.AUTHOR,
]

export const MESSAGE_SUCCESS_SET_PASSWORD =
  'your password was changed successfully.'

export const PASSWORD_CRITERIA = {
  atLeastEight: {
    label: 'At least 8 characters',
    passed: false,
  },
  oneUpper: {
    label: 'One upper case letter',
    passed: false,
  },
  oneLower: {
    label: 'One lower case letter',
    passed: false,
  },
  oneNumerical: {
    label: 'One numerical character',
    passed: false,
  },
  passwordsMatch: {
    label: 'Passwords match',
    changed: false,
    passed: false,
  },
}

export const PASSWORD_CRITERIA_KEYS = Object.keys(PASSWORD_CRITERIA)

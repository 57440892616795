import {
  NON_PUBLISHED_POST_STATES,
  PUBLISHED_POST_STATES,
} from '~/src/constants/wordpress-content'

export function getAllowedPostStatuses(includeNonPublished: boolean) {
  const states = [...PUBLISHED_POST_STATES]

  if (includeNonPublished) {
    states.push(...NON_PUBLISHED_POST_STATES)
  }

  return states
}

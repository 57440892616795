<template>
  <ClientOnly>
    <div>
      <div v-if="success">
        <div class="w-full flex flex-col gap-4 text-base">
          <h2
            class="text-left font-extrabold text-gray-900 mb-4"
            :class="getClasses()"
          >
            Email has been sent
          </h2>
          <p> We have sent you a reset password link to {{ form.email }} </p>
          <div class="flex flex-row gap-2">
            <p class="flex items-baseline"> Didn’t receive the email? </p>
            <div
              v-if="successSendAgain"
              class="flex flex-row gap-2 font-bold items-baseline"
            >
              <div class="text-good flex flex-row gap-1">
                <span>Sent</span>
                <CheckedIcon class="h-5 w-5 text-good" />
              </div>
              <span class="text-sm" type="button">
                Try again in {{ seconds }}
              </span>
            </div>
            <button
              v-else
              class="font-bold hover:text-p-500"
              type="button"
              :disabled="timerRunning"
              @click.prevent="resetPassword(true)"
            >
              Send Again
            </button>
          </div>

          <div class="flex flex-row gap-4 justify-end">
            <Button
              class="!text-sm border-2 border-p-500 bg-white text-black hover:text-black hover:bg-p-500 hover:border-p-500"
              variant="secondary"
              :disabled="timerRunning && !successSendAgain"
              @click.prevent="resetSend"
            >
              Edit Email
            </Button>
            <Button
              class="!text-sm"
              variant="primary"
              @click.prevent="onBackButtonClick"
            >
              {{ RETURN_TO_HOMEPAGE_TEXT }}
            </Button>
          </div>
        </div>
      </div>
      <div v-else>
        <client-only>
          <VeeValidateForm ref="observer" as="">
            <div class="mx-auto flex flex-col items-center gap-4 my-10 md:my-0">
              <div v-if="showDefaultTitle" class="w-full flex flex-col gap-10">
                <h2
                  class="text-left font-extrabold text-gray-900"
                  :class="getClasses()"
                >
                  Reset Password
                </h2>

                <p class="text-base">
                  Enter your email address that you used to register. We'll send
                  you an email with your username and a link to reset your
                  password.
                </p>
              </div>
              <form class="w-full" novalidate @submit.prevent="resetPassword">
                <input type="hidden" name="remember" value="true" />
                <div class="-space-y-px rounded-md">
                  <FormInput
                    v-model="form.email"
                    name="Email"
                    rules="required|email"
                    type="email"
                    data-testid="forgot-password-email"
                    label="Email"
                    class="w-full"
                    placeholder="name@email.com"
                    label-classes="!text-sm"
                    autocomplete="email"
                  />
                </div>

                <div v-if="messageError" class="text-error my-1">
                  {{ messageError }}
                </div>

                <div class="flex flex-col items-center mt-10 lg:mt-0">
                  <Button
                    data-testid="send-instructions-btn"
                    class="!w-full px-12 text-sm lg:!w-auto lg:self-end"
                    variant="primary"
                    type="submit"
                    :disabled="form.busy"
                    @click.prevent="resetPassword()"
                  >
                    Send
                  </Button>
                </div>
              </form>
            </div>
          </VeeValidateForm>
        </client-only>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
/** packages */
import { Form as VeeValidateForm } from 'vee-validate'

/** components */
import Button from '~/components/common/Button.vue'
import FormInput from '~/components/common/FormInput.vue'

/** assets */
import CheckedIcon from 'assets/icons/password-check.svg'

/** utils */
import { Forms } from '~/src/utils/forms'

/** types */
import type { TComponentType, TFormCommon } from '~/src/types/form'
import { RETURN_TO_HOMEPAGE_TEXT, TYPES_MODULE } from '~/src/constants/forms'

/** props */
const props = withDefaults(
  defineProps<{
    module: TComponentType
    showDefaultTitle: boolean
  }>(),
  {
    module: TYPES_MODULE.MODAL,
    showDefaultTitle: false,
  },
)

/** refs */
const propertiesForm = {
  email: '',
  source: 'website',
} as TFormCommon

const form = ref(new Forms<TFormCommon>(null, propertiesForm))
const success = ref<boolean>(false)
const successEmail = ref<string>('')

const observer = ref<VeeValidateForm | null>(null)
const seconds = ref<number>(10)
const timerRunning = ref<boolean>(false)
const showSendAgain = ref<boolean>(false)
const successSendAgain = ref<boolean>(false)

/** app */
const { $modal, $restClient } = useNuxtApp()
const $router = useRouter()

/** computed */
const messageError = computed(() => form.value.errors.get('message'))

/** methods */
const resetSend = () => {
  timerRunning.value = false
  showSendAgain.value = false
  successSendAgain.value = false
  seconds.value = 10
  success.value = false
}

const resetPassword = async (resend = false) => {
  if (resend) {
    timerRunning.value = true
  }
  showSendAgain.value = !resend

  const isValid = await observer.value?.validate()

  if (!resend && !isValid?.valid) return

  prepareForm(resend)

  try {
    await $restClient.user.resetPassword({
      email: form.value.email || successEmail.value,
      source: 'website',
    })

    successEmail.value = form.value.email

    form.value.reset()
    form.value.finishProcessing()
    observer.value?.resetForm()

    success.value = true
    if (resend && !showSendAgain.value) {
      successSendAgain.value = true
    }
    form.value.setValue('source', 'website')
  } catch (e) {
    form.value.setErrors({ message: e.message })
  }
  if (resend) {
    timerRunning.value = false
  }
}

const prepareForm = (resend = false) => {
  if (!resend) {
    success.value = false
  }

  form.value.startProcessing()
}

const onBackButtonClick = () => {
  if (isEqualModule(TYPES_MODULE.MODAL)) {
    $modal.close('forgotPassword')
  }

  $router.push('/')
}

const executeTimer = () => {
  if (timerRunning.value) return

  timerRunning.value = true
  const timer = setInterval(() => seconds.value--, 1000)

  setTimeout(() => {
    clearInterval(timer)
    seconds.value = 10
    timerRunning.value = false
    successSendAgain.value = false
  }, 10000)
}

const isEqualModule = (defaultModule: TYPES_MODULE) =>
  props.module === defaultModule

const getClasses = () =>
  isEqualModule(TYPES_MODULE.PAGE) ? 'text-2xl' : 'text-xl lg:text-3xl'

watch(
  () => successSendAgain.value,
  (value) => {
    if (value) {
      executeTimer()
    }
  },
)
</script>

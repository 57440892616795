<template>
  <NuxtLink
    class="border-b-4 py-0.5 min-w-[9.375rem] text-center"
    :class="isActive ? 'border-p-500' : 'border-transparent'"
    :to="item.to"
    no-prefetch
  >
    {{ item.name }}
  </NuxtLink>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isActive() {
      return this.$route.path.replace(/\/$/, '') === this.item.to
    },
  },
  methods: {
    titleCase,
  },
}
</script>

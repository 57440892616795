import { defineStore } from 'pinia'
import { ref } from 'vue'

export type TSetToastParams = {
  message: string
  type?: string
  position?: string
  duration?: number
  icon?: string | null
}

export const useToastStore = defineStore('toast', () => {
  const message = ref<string>('')
  const type = ref<string>('success')
  const position = ref<string>('bottom-center')
  const duration = ref<number>(3000)
  const icon = ref<string | null>(null)
  const show = ref<boolean>(false)

  const setToast = (params: TSetToastParams) => {
    message.value = params.message
    type.value = params.type || 'success'
    position.value = params.position || 'bottom-center'
    duration.value = params.duration || 3000
    icon.value = params.icon || null
    show.value = true
  }

  const clearToast = () => {
    message.value = ''
    type.value = ''
    position.value = ''
    duration.value = 3000
    icon.value = null
    show.value = false
  }

  return {
    message,
    type,
    position,
    duration,
    icon,
    show,
    setToast,
    clearToast,
  }
})

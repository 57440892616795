<template>
  <div class="w-full">
    <div v-if="loading" class="absolute left-0 top-44 w-full lg:top-72">
      <TheLoader />
    </div>

    <div v-else class="mb-10 h-full w-full">
      <div
        class="max-w-content-container mx-auto w-full px-4 lg:px-14 2xl:px-0"
      >
        <BackButton
          show-icon-back
          title="Back to Data & Reports"
          class="my-5"
          :route="route"
        />
      </div>
      <div
        class="border-p-500 bg-s-900 relative flex h-full w-full flex-col border-t-4"
      >
        <VimeoPlayer
          v-if="vimeoVideo"
          :source="vimeoSource"
          :autoplay="startPlay"
          video-ratio="4:2"
          class="h-full w-full"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheLoader from '~/components/TheLoader.vue'
import BackButton from '~/components/shared/common/BackButton.vue'
import VimeoPlayer from '~/components/video/VimeoPlayer.vue'

import { useGetVimeoData } from '~/composables/useGetVimeoData'

import { reformat } from '~/utils/date'
import { logError } from '~/utils/log-error'

const props = defineProps<{
  reportId: string
  reportCategory: string
}>()

const { $downloadManager } = useNuxtApp()
const { vimeoSource, vimeoVideo, getVimeoData } = useGetVimeoData()

const loading = ref(true)
const startPlay = ref(false)
const videoTitle = ref('')
const videoDate = ref(null)

const route = computed(() => {
  const { path } = useRoute()
  const queryParams = window.location.search || ''
  return `/${path.split('/').slice(1, 4).join('/')}${queryParams}`
})

async function onInit() {
  try {
    if (props.reportCategory && props.reportId) {
      const report = await $downloadManager.getDataFileByIdAndCategory(
        props.reportCategory,
        props.reportId,
      )
      videoTitle.value = report.post_title
      videoDate.value = reformat(report.post_date, 'MMM dd, yyyy')
      const vimeoId = report.file_name
      await getVimeoData(vimeoId)
    }
  } catch (_error) {
    logError(_error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  onInit()
})
</script>

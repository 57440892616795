<template>
  <ClientOnly>
    <NuxtLink
      :href="sourceLink"
      target="_blank"
      class="flex items-center gap-x-1 text-black border-2 rounded border-p-500 py-2 px-4 font-bold leading-none w-fit h-fit min-h-[42px]"
      external
      no-prefetch
    >
      <SourceIcon class="size-5" />
      <span>Visit Source</span>
    </NuxtLink>
    <template #fallback>
      <div
        class="flex items-center gap-x-1 text-black border-2 rounded border-p-500 py-2 px-4 font-bold leading-none w-fit h-fit min-h-[42px]"
      >
        <SourceIcon class="size-5" />
        <span>Visit Source</span>
      </div>
    </template>
  </ClientOnly>
</template>
<script setup lang="ts">
/** assets */
import SourceIcon from '~/assets/icons/sidebar/Terminal.svg'

/** app */
const { $config } = useNuxtApp()

const sourceLink = ref<string>($config.public.sourceUrl)
</script>

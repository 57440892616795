import type { TMetadata, TSeoProperties } from '~/src/types/seo'
import { generateSeoInfo } from '~/src/utils/seo'

export const useSeo = () => {
  const config = useRuntimeConfig()
  const route = useRoute()
  const url = `${config.public?.baseFrontendUrl}${route?.fullPath}`

  const getSeoInfo = (
    metaProps: TMetadata,
    seoProperties?: TSeoProperties,
  ): any => generateSeoInfo({ metaProps, seoProperties, url })

  return { getSeoInfo }
}

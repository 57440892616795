<template>
  <div
    class="flex cursor-pointer select-none py-3 border-s-400 border-b text-base font-normal"
    :class="item?.menuStyle"
    data-testid="menu-mobile-item"
    @click="$emit('handleClick', item)"
  >
    <div class="flex flex-grow items-center justify-between font-normal">
      <slot>
        <div class="flex flex-row items-center justify-center gap-2">
          <component :is="item?.icon" v-if="item?.icon" class="h-5 w-auto" />
          {{ item?.name }}
          <IconCheckSubscribe
            v-if="hasAnyActiveMembership(item?.memberships)"
            class="text-p-500 h-3 w-3"
          />
        </div>
        <IconRightAngle v-if="hasAnyChild(item)" class="h-4 w-4" />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconCheckSubscribe from 'assets/icons/check-subscribe.svg'
import IconRightAngle from 'assets/icons/angle-right.svg'
import { useMembershipsStore } from '~/stores/memberships'

defineEmits(['handleClick'])
defineProps<{
  item?: {
    name: string
    icon: string
    memberships: string[]
    children: any[]
    groups: any[]
    menuStyle: string
    if_callback: () => boolean
  }
}>()

const { getSubscription } = useMembershipsStore()

const hasAnyActiveMembership = (memberships) => {
  if (!memberships) return false
  return memberships.find((membership) => getSubscription(membership))
}

const hasAnyChild = (menu) => {
  return menu?.children?.length || menu?.groups?.length
}
</script>

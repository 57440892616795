import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import {
  PAYABLE_AND_KWH,
  PAYABLE_ID,
  UNITS_MEASUREMENTS,
} from '~/constants/all-prices/chart-builder'

export const useChartBuilderUnitsMeasurementStore = defineStore(
  'chartBuilderUnitsMeasurement',
  () => {
    const unitsMeasurement = ref(UNITS_MEASUREMENTS)
    const selectedUnits = ref<string[]>([])

    const havePayableAndKWH = computed(() =>
      PAYABLE_AND_KWH.every((unit) => selectedUnits.value.includes(unit)),
    )

    const hasOnlyPayable = computed(
      () =>
        selectedUnits.value.length === 1 &&
        selectedUnits.value[0] === PAYABLE_ID,
    )

    const hasOnlyKWH = computed(
      () =>
        selectedUnits.value.length === 1 && selectedUnits.value[0] === 'kwh',
    )

    const setSelectedUnits = (units: string[]) => {
      selectedUnits.value = units
      unitsMeasurement.value = unitsMeasurement.value.map((unit) => ({
        ...unit,
        isSelected: units.includes(unit.value),
      }))
    }

    const reset = () => {
      unitsMeasurement.value = UNITS_MEASUREMENTS
      selectedUnits.value = []
    }

    return {
      unitsMeasurement,
      selectedUnits,
      havePayableAndKWH,
      hasOnlyPayable,
      hasOnlyKWH,
      setSelectedUnits,
      reset,
    }
  },
)

import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useMenuStore = defineStore('menu', () => {
  const activeMenu = ref<any>(null)
  const activeSubMenu = ref<string>(null)

  const getActiveSubMenu = computed(
    () =>
      activeMenu.value?.children.find((menu: any) =>
        menu.to.includes(activeSubMenu.value),
      )?.name,
  )

  const setActiveMenu = (params: {
    activeMenu: any
    activeSubMenu: string
  }) => {
    activeMenu.value = params.activeMenu
    activeSubMenu.value = params.activeSubMenu
  }

  return {
    activeMenu,
    activeSubMenu,
    setActiveMenu,
    getActiveSubMenu,
  }
})

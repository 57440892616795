<template>
  <ReportsDownloadFilePage
    v-if="reportId"
    :key="`report-page-lithium-${marketSlug}`"
    :report-category="reportCategory"
    :report-id="reportId"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ReportsDownloadFilePage from '~/components/reports/DownloadFilePage.vue'
import { ALL_MARKETS, MARKET } from '~/constants/services/constants'

definePageMeta({ layout: 'overviews', middleware: ['has-active-membership'] })

const route = useRoute()
const marketsMap = ALL_MARKETS.reduce(
  (acc, market) => {
    acc[market.slug] = market
    return acc
  },
  {} as Record<string, (typeof ALL_MARKETS)[number]>,
)

const marketSlug = ref(route.params.market as string)
const market = ref(marketsMap[marketSlug.value] || null)
const reportCategory = ref(market.value?.categoryReportPricing)
const reportId = ref<string | null>(null)

const { getSeoInfo } = useSeo()
const seoInfo = getSeoInfo({
  title: `${MARKET.name}: ${market.value?.name || ''} Report`,
  description:
    ALL_MARKETS[marketSlug.value]?.seo.priceAssessments.reportDescription,
})
useHead(seoInfo)

onMounted(() => {
  reportId.value = route.params.id as string
})
</script>

<template>
  <div
    class="md:max-w-content-container mx-auto grid h-full w-full md:mt-6 md:grid-cols-2 md:gap-4 md:px-4 lg:px-14 xl:grid-cols-3 2xl:px-0"
  >
    <div :class="[twMerge(cardDefaultClasses)]">
      <transition name="swipe">
        <CarouselDefault v-if="loading" type="event" />
      </transition>

      <HomeHeroEvent v-if="!loading" :event="event" :loading="true" />
    </div>

    <div :class="[twMerge(cardDefaultClasses)]">
      <transition name="swipe">
        <CarouselDefault v-if="loading" type="source" />
      </transition>

      <CarouselHero v-if="!loading" :items="articles" />
    </div>

    <div :class="[twMerge(cardDefaultClasses, 'md:col-span-2 xl:col-span-1')]">
      <transition name="swipe">
        <CarouselDefault v-if="loading" type="capex" />
      </transition>

      <CarouselHero
        v-if="!loading"
        :items="capexTrackerData"
        type="capex-tracker"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
/** packages */
import { twMerge } from 'tailwind-merge'

/** packages */
import CarouselDefault from '~/components/home/CarouselDefault.vue'

/** constants */
import { capexTrackerData } from '~/constants/capex-tracker-data'
import type { TArticleNode, TEvent } from '~/src/types/wordpress-content'

defineProps<{
  loading: boolean
  event?: TEvent | null
  articles: TArticleNode[]
}>()

const cardDefaultClasses =
  'hero-event bg-s-300 min-h-36 h-36 relative flex md:h-[290px] w-full overflow-hidden md:rounded-xl lg:w-full'
</script>
<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 429px) {
  .hero-event {
    height: 125px;
    min-height: 125px;
  }
}

@media (min-width: 430px) and (max-width: 767px) {
  .hero-event {
    height: 150px;
    min-height: 150px;
  }
}
</style>

import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLiveChatStore = defineStore('liveChat', () => {
  const enable = ref(false)

  const setEnable = (value: boolean) => {
    enable.value = value
  }

  return { enable, setEnable }
})

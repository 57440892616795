import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ $router, vueApp, $config }) => {
  if ($config.public.SENTRY_ENABLED) {
    Sentry.init({
      app: vueApp,
      dsn: $config.public.SENTRY_DSN,
      environment: $config.public.SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration({
          router: $router,
        } as any),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
        Sentry.vueIntegration({
          app: vueApp,
          tracingOptions: { trackComponents: true },
        }),
      ],
      sampleRate: 0.5,
      tracesSampleRate: 0.3,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.3,
    })
  }
})

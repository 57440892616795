<template>
  <div>
    <div class="fixed left-0 z-10 w-full border-b border-white">
      <button
        class="flex w-full items-center gap-1 bg-grey-900 px-[1.125rem] py-2.5"
        @click="toggleOpenMobile"
      >
        <span class="text-xs font-bold leading-none text-white">
          {{ fixedName || activeMenu?.name }}
        </span>
        <span
          :class="{
            'animate-bounce-menu': !openMobileMenu,
          }"
        >
          <IconChevronDown
            class="w-5 text-p-400 transition-transform duration-300"
            :class="{
              'rotate-180': openMobileMenu,
            }"
          />
        </span>
      </button>

      <div
        :class="{ 'h-fit': openMobileMenu, 'h-0': !openMobileMenu }"
        class="absolute flex max-h-[calc(100vh-105px)] w-full flex-col overflow-hidden overflow-y-auto bg-grey-100 shadow-file transition-all duration-300 lg:sticky lg:top-[153px] lg:bg-white lg:shadow-none xl:top-[105px]"
      >
        <client-only>
          <div
            v-for="menu in menus"
            :key="menu.name"
            class="flex min-h-11 items-center px-3.5 py-2.5"
            :class="{
              'bg-grey-300 text-s-900': isMenuActive(menu.href),
              'text-s-700': !isMenuActive(menu.href),
            }"
          >
            <NuxtLink
              :to="menu.href"
              no-prefetch
              class="flex w-full items-center"
            >
              <span class="text-xs font-medium leading-none">
                {{ menu.name }}
              </span>
            </NuxtLink>
          </div>
        </client-only>
      </div>
    </div>

    <!-- Menu Offset -->
    <div class="pt-[2.875rem] md:hidden" />
  </div>
</template>

<script setup lang="ts">
/** packages */
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

/** components */
import IconChevronDown from '~/assets/icons/ChevronDown.svg?component'

/** composables */
import { useScreenSize } from '~/composables/useScreenSize'

/** types */
import type { TMenu } from '~/src/types/common'

/** props */
const props = withDefaults(
  defineProps<{ fixedName?: string; menus: TMenu[] }>(),
  {
    fixedName: '',
    menus: () => [],
  },
)

const route = useRoute()
const { isTablet, isMobile } = useScreenSize()

const activeMenu = computed(() => {
  return props.menus.find((menu) => isMenuActive(menu.href))
})

const openMobileMenu = ref(false)
let prevScrollPos = 0

const toggleOpenMobile = () => {
  openMobileMenu.value = !openMobileMenu.value
}

const isMenuActive = (menu: string) => {
  return route.path.includes(menu)
}

const scrollFunction = () => {
  const currentScrollPos = window.scrollY
  const navbar = document.getElementById('navbar')
  const sidebar = document.getElementById('sidebar')

  if (navbar && sidebar) {
    const navbarHeight = navbar.offsetHeight
    const sidebarHeight = sidebar.offsetHeight
    const totalHeight = navbarHeight + sidebarHeight

    if (prevScrollPos < currentScrollPos && currentScrollPos > totalHeight) {
      navbar.style.top = `-${navbarHeight}px`
      sidebar.style.top = `-${totalHeight}px`
    } else if (prevScrollPos > currentScrollPos) {
      navbar.style.top = '0'
      sidebar.style.top = `${navbarHeight}px`
    }
    prevScrollPos = currentScrollPos
  }
}

onMounted(() => {
  if (isTablet.value || isMobile.value) {
    window.addEventListener('scroll', scrollFunction)
  }
})
</script>

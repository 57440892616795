import type { TGeneral } from '~/src/types/common'

export default defineNuxtPlugin((nuxtApp) => {
  const { $config }: TGeneral = nuxtApp
  if (import.meta.client && $config.public.performanceObserverEnabled) {
    const types: string = $config.public.performanceObserverEntryTypes
    const entryTypes: string[] = types.split(',').map((type) => type.trim())
    const observer = new PerformanceObserver(
      (list: PerformanceObserverEntryList) => {
        entryTypes.forEach((entryType) => {
          const entries = list.getEntriesByType(entryType)
          entries.forEach((entry) => {
            console.log({
              Type: entryType,
              Resource: entry.name,
              Duration: parseFloat(entry.duration.toFixed(3)),
              entry,
            })
          })
        })
      },
    )

    observer.observe({ entryTypes })
  }
})

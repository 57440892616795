<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { JWT_ACCESS_TOKEN, USER_INFO } from '~/src/constants/common'
import { useMembershipsStore } from '~/stores/memberships'
import useAuthStore from '~/stores/auth'

const THREE_MINUTES = 3 * 60 * 1000
const { $backendVersionWatcher, $cookies, $membershipApi } = useNuxtApp()
const { setJwtToken, setUser } = useAuthStore()
const membershipsStore = useMembershipsStore()

onMounted(() => {
  $membershipApi.restoreMemberships()
  $backendVersionWatcher.invalidateSession()
  setInterval(() => {
    $backendVersionWatcher.invalidateSession()
  }, THREE_MINUTES)
})

onBeforeMount(() => {
  const jwtToken = $cookies.get(JWT_ACCESS_TOKEN)
  const user = $cookies.get(USER_INFO)
  setJwtToken(jwtToken)
  setUser(user)
  if (!jwtToken) membershipsStore.clearKeys()
})
</script>

import type { TArticlesQuery } from '~/types/graphql.type'
import { gql } from 'graphql-tag'
import { getArticleTerms } from '~/src/utils/graphql-query'

const buildNewsArticlesQuery = ({
  market,
  categories = null,
}: TArticlesQuery) => {
  const termsArticles = getArticleTerms(market, categories)

  return gql`
    query getNewsArticles(
      $first: Int
    ) {
      memberships(
        first: $first
        where: {
          stati: [PUBLISH]
          taxQuery: {
            relation: OR
            taxArray: [
              {
                ${termsArticles}
                taxonomy: CATEGORY
                operator: IN
                field: SLUG
              }
            ]
          }
        }
      ) {
        nodes {
          id: databaseId
          title
          date
          slug
          excerpt
          __typename
          featuredImage {
            node {
              mediaItemUrl
              srcSet
            }
          }
          memberships {
            estimatedReadingTime
          }
          categories {
            nodes {
              name
              slug
              id: databaseId
            }
          }
        }
      }
    }
  `
}

export default buildNewsArticlesQuery

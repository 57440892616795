<template>
  <div
    class="flex flex-col h-full lg:flex-row"
    :class="[isPage ? 'justify-center items-center' : '']"
  >
    <div
      class="bg-grey-50 w-full bg-cover bg-center"
      :class="[
        isPage && isResetPassword
          ? 'lg:h-[max(900px,100vh)]'
          : 'lg:h-[max(900px,94vh)]',
        isPage
          ? 'h-[clamp(350px,100%,50vh)] lg:w-[44%] 2xl:w-[45%]'
          : '!h-[200px] lg:!h-[440px] lg:w-[40%]',
      ]"
    >
      <ImageCommon
        :src="image"
        alt="login"
        :class="classesImage"
        loading="eager"
        fetchpriority="high"
      />
    </div>
    <div :class="classesContainer">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
/** components */
import ImageCommon from '~/src/components/common/images/ImageCommon.vue'
import { twMerge } from 'tailwind-merge'

const props = withDefaults(
  defineProps<{
    image: string
    isPage?: boolean
    isResetPassword?: boolean
  }>(),
  {
    isPage: false,
    isResetPassword: false,
  },
)

const classesImage = ref(
  twMerge(
    'w-full object-cover',
    props.isPage ? 'h-[400px] lg:h-full' : 'h-[200px] lg:h-[440px]',
  ),
)

const classesContainer = ref(
  twMerge(
    'm-auto flex flex-col items-center justify-center',
    props.isResetPassword
      ? 'h-[min(850px,95vh)] lg:h-[max(900px,90vh)]'
      : 'h-[400px]',
    props.isPage
      ? 'w-full h-full lg:w-[56%] 2xl:w-[55%] px-0 my-10 lg:my-0'
      : 'w-full min-h-[200px] lg:w-[60%] p-10',
  ),
)
</script>

import { defineRule, configure } from 'vee-validate'
import { regex, required, email, confirmed, min } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import { blackListEmails } from '~/constants/blackListEmail'
import type { TCommon } from '~/src/types/common'

export default defineNuxtPlugin(() => {
  defineRule('required', required)
  defineRule('confirmed', confirmed)
  defineRule('min', min)
  defineRule('email', email)
  defineRule(
    'minmax',
    (value: TCommon, [min, max]) => value >= +min && value <= +max,
  )
  defineRule(
    'businessEmail',
    (value: TCommon) =>
      !blackListEmails.some((blacklisted) => value.includes(blacklisted)),
  )
  defineRule('min_length', (value: TCommon) => value.length >= 8)
  defineRule('uppercase', (value: TCommon) => regex(value, { regex: /[A-Z]/ }))
  defineRule('lowercase', (value: TCommon) => regex(value, { regex: /[a-z]/ }))
  defineRule('number', (value: TCommon) => regex(value, { regex: /\d/ }))
  defineRule('password', (value: TCommon) => {
    return (
      required(value) &&
      regex(value, { regex: /[A-Z]/ }) &&
      regex(value, { regex: /[a-z]/ }) &&
      regex(value, { regex: /\d/ }) &&
      value.length >= 8
    )
  })

  configure({
    generateMessage: localize('en', {
      messages: {
        email: 'The email is invalid',
        required: '{field} cannot be empty',
        minmax: '{field} must be between {min} and {max}',
        min: (context) =>
          `${context.field} must have a minimum length of ${context.rule.params[0]}`,
        confirmed: '{field} must match',
        businessEmail: 'Please enter a valid company email',
        min_length: '{field} must be at least 8 characters long.',
        uppercase: '{field} must contain at least one uppercase letter.',
        lowercase: '{field} must contain at least one lowercase letter.',
        number: '{field} must contain at least one number.',
        password:
          'At least 8 characters, with one uppercase letter, one lowercase letter, and one number.',
      },
      fields: {
        'company-category': {
          required: 'A company category must be selected',
        },
        industry: {
          required: 'An Industry must be selected',
        },
        revenue: {
          required: 'A year revenue range must be selected',
        },
      },
    }),
  })
})

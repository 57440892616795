/** types */
import type { TForm, TFormValue } from '~/src/types/form'
import type { TCommon } from '~/src/types/common'

/** errors */
import { FormErrors } from '~/src/errors/form-errors'

export abstract class AbstractForm<T extends TForm<T>> {
  protected data: T = {} as T
  protected errors: FormErrors<T> = new FormErrors<T>()
  protected formId: string | number | null
  protected dataKeys: Array<keyof T>

  public getValue<K extends keyof T>(key: K): TFormValue {
    return this.data[key] as TFormValue
  }

  protected getValueByFormData<K extends keyof T>(key: K): TCommon {
    return this.data[key]
  }

  public setValue<K extends keyof T>(key: K | string, value: TFormValue): void {
    this.data[key as string] = value
  }

  protected isNonFileObject(value: any): boolean {
    return typeof value === 'object' && !(value instanceof File)
  }

  protected appendObjectToFormData(
    formData: FormData,
    objectValue: any,
    parentKey: keyof T,
  ) {
    for (const subKey in objectValue) {
      if (objectValue[subKey]) {
        const value = `${parentKey as string}[${subKey}[]`
        formData.append(value, subKey)
      }
    }
  }

  public reset() {
    this.dataKeys.forEach((key) => this.setValue(key, ''))
  }

  public json() {
    return this.dataKeys.reduce((object, key) => {
      return { ...object, [key]: this.getValue(key) }
    }, {})
  }

  public formData() {
    const formData = new FormData()
    this.dataKeys.forEach((key) => {
      const value = this.getValue(key)
      if (this.isNonFileObject(value)) {
        this.appendObjectToFormData(formData, value, key)
      } else {
        formData.append(key as string, this.getValueByFormData(key))
      }
    })
    return formData
  }

  public setError(key: keyof T, message: string) {
    this.errors.setError(key, message)
  }

  public resetErrors() {
    this.errors = new FormErrors<T>()
  }

  public hasErrors() {
    return this.errors.hasErrors()
  }

  public abstract setErrors(errors: T): void
}

import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import {
  STEPS,
  STEPS_WITHOUT_CURRENCY,
} from '~/constants/all-prices/chart-builder'
import { useChartBuilderUnitsMeasurementStore } from './unitsMeasurement'
import { useChartBuilderConfigStore } from '~/stores/chartBuilder/config'

export const useChartBuilderStepsStore = defineStore(
  'chartBuilderSteps',
  () => {
    const configStore = useChartBuilderConfigStore()
    const currentStep = ref(1)
    const steps = ref(STEPS)
    const unitsMeasurementStore = useChartBuilderUnitsMeasurementStore()

    const filteredSteps = computed(() => {
      if (!unitsMeasurementStore.hasOnlyPayable) return steps.value
      return STEPS_WITHOUT_CURRENCY.map((step) => {
        const stepFromState = steps.value.find((s) => s.id === step.id)
        return {
          ...step,
          completed: stepFromState?.completed ?? false,
          hidden: stepFromState?.hidden ?? true,
        }
      })
    })

    const progress = computed(() => {
      const isEditing = configStore.isEditing
      const products = configStore.products
      const someCompleteProduct = products.every((product) => product.completed)

      if (!isEditing || !someCompleteProduct) return 0

      const stepValue = 100 / filteredSteps.value.length
      return stepValue * currentStep.value
    })

    const nextTitle = computed(() =>
      currentStep.value === filteredSteps.value.length ? 'Build chart' : 'Next',
    )
    const productsStep = computed(() =>
      filteredSteps.value.find((step) => step.id === 'products'),
    )
    const currencyStep = computed(() =>
      filteredSteps.value.find((step) => step.id === 'currency'),
    )
    const unitStep = computed(() =>
      filteredSteps.value.find((step) => step.id === 'unit'),
    )

    const setSteps = (newSteps: any[]) => {
      steps.value = newSteps
    }

    const setOpenStep = ({
      stepId,
      open,
    }: {
      stepId: string
      open: boolean
    }) => {
      steps.value = steps.value.map((step) => ({
        ...step,
        hidden: step.id === stepId ? !open : step.hidden,
      }))
    }

    const toggleOpenStep = (stepId: string) => {
      steps.value = steps.value.map((step) => ({
        ...step,
        hidden: step.id === stepId ? !step.hidden : step.hidden,
      }))
    }

    const changeTitleStep = ({
      stepId,
      title,
    }: {
      stepId: string
      title: string
    }) => {
      steps.value = steps.value.map((step) => ({
        ...step,
        title: step.id === stepId ? title : step.title,
      }))
    }

    const completeStep = (stepId: string) => {
      steps.value = steps.value.map((step) => ({
        ...step,
        completed: step.id === stepId ? true : step.completed,
      }))
    }

    const setCurrentStep = (newStep: number) => {
      if (newStep < 1 || newStep > steps.value.length) return
      currentStep.value = newStep
    }

    const reset = () => {
      steps.value = steps.value.map((step) => ({
        ...step,
        completed: false,
        hidden: true,
      }))
      steps.value[0].hidden = false
      currentStep.value = 1
    }

    return {
      currentStep,
      steps,
      progress,
      nextTitle,
      productsStep,
      currencyStep,
      unitStep,
      filteredSteps,
      setSteps,
      setOpenStep,
      toggleOpenStep,
      changeTitleStep,
      completeStep,
      setCurrentStep,
      reset,
    }
  },
)

<template>
  <div
    v-if="index.name"
    :id="id"
    ref="tickerItem"
    class="relative flex items-center"
    :class="hasAccess ? '' : 'mx-1'"
  >
    <span class="mr-2 whitespace-nowrap font-normal">
      {{ indexName }}
    </span>
    <Icon
      v-if="!hasAccess"
      name="close-lock"
      class="hover:text-green-light mx-1 max-h-4 w-auto cursor-pointer"
      @click="$modal.open('signIn')"
    />
    <span v-else>{{ today }}</span>
    <span
      class="ml-2 flex min-w-[45px] items-center text-xs"
      :class="getColor(percentageChange)"
    >
      {{ percentageChange }}%
      <IconLeftAngle
        v-if="percentageChange !== 0"
        class="w-5 -rotate-90"
        :class="
          (isPercentageChangeGreaterThanZero && 'rotate-90 ') +
          iconAngleClassHidden
        "
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import tippy, { type Tippy } from 'tippy.js'
import { useId } from '#app'
import Icon from '~/components/common/Icon.vue'
import IconLeftAngle from '/assets/icons/angle-left.svg'
import { buildSVGChart } from '~/utils/build-svg-chart'
import { FRONTEND_BASE_URL_DEFAULT } from '~/src/constants/common'
import { scrollTo } from '~/src/utils/scroll'
import { getMarketBySubscription } from '~/constants/services/market'
import type { TCommon } from '~/src/types/common'

const iconArrowDown =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414Z"/></svg>'
const iconArrowUp =
  '<svg width="18" height="18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.707 11.707a1 1 0 0 1-1.414 0L9 8.414l-3.293 3.293a1 1 0 0 1-1.414-1.414l4-4a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414Z"/></svg>'

const ZERO = 0

const emit = defineEmits(['mounted', 'pauseAnimation', 'resumeAnimation'])
const props = withDefaults(
  defineProps<{
    index: any
  }>(),
  {
    index: () => ({}),
  },
)

const id = useId()
const router = useRouter()
const route = useRoute()
const { $modal }: any = useNuxtApp()

const tickerItem = ref(null)
const elementIDsWithOpenModalAction = ref([])
const elementIDsWithTickerAnimationActions = ref([])
const elementIDsWithLinks = ref([])

const hasAccess = computed(() => props.index?.hasAccess)
const percentageChange = computed(() => props.index?.percentageChange)
const today = computed(() => props.index?.today)
const url = computed(() => {
  if (!props.index) return ''

  const route = getMarketBySubscription(props.index.subscription)

  if (route) return route

  return props.index.url.includes('raw-material-index')
    ? '/lithium-ion-battery-raw-material-index/'
    : props.index.url
})

const indexName = computed(() => `${props.index.name} Index`)
const link = computed(
  () => url.value.replace(FRONTEND_BASE_URL_DEFAULT, '') || '',
)
const isPercentageChangeGreaterThanZero = props.index?.percentageChange > ZERO
const iconAngleClassHidden =
  props.index?.percentageChange === ZERO ? 'hidden' : ''

const getColor = (value) => {
  if (value === ZERO || value === null) return 'text-s-500'
  if (value > ZERO) return 'text-green-light'
  return 'text-error-light'
}

const getFormattedValue = (value) => {
  if (value === null) return `--`
  if (value === 0) return `0.0%`
  if (value < 0) return `${iconArrowDown}${value}%`
  return `${iconArrowUp}${value}%`
}

const setOpenModalListener = (instance) => {
  const contactUsContainer = document.querySelector(
    '.tooltip-contact-us-container',
  )
  if (!contactUsContainer) return
  if (!elementIDsWithOpenModalAction.value.includes(instance.id)) {
    contactUsContainer?.addEventListener('click', () => {
      $modal.open('contact')
      instance.hide()
    })
    elementIDsWithOpenModalAction.value.push(instance.id)
  }
}

const setTickerAnimationListeners = () => {
  const tickerTooltip = document.querySelector('.ticker-tooltip')
  if (
    tickerTooltip &&
    !elementIDsWithTickerAnimationActions.value.includes(tickerTooltip)
  ) {
    tickerTooltip.addEventListener('mouseover', () => emit('pauseAnimation'), {
      passive: true,
    })
    tickerTooltip.addEventListener(
      'mouseleave',
      () => emit('resumeAnimation'),
      { passive: true },
    )
    elementIDsWithTickerAnimationActions.value.push(tickerTooltip)
  }
}

const setNavigationListener = (instance) => {
  const linkTitle = document.querySelector('.tooltip-header-link')
  if (!linkTitle) return
  if (elementIDsWithLinks.value.includes(instance.id)) return

  linkTitle.addEventListener('click', () => {
    let newLink = link.value
    if (!link.value.includes('raw')) {
      const path = link.value.split('/')[1]
      newLink = `/${path}/${path}-prices/index`
    }
    router.push(newLink)
    const fullPath = route.fullPath
    if (fullPath.includes(newLink)) {
      scrollTo('summary-content')
      scrollTo('lithium-ion-battery-raw-material-index-chart')
    }
    instance.hide()
  })
  elementIDsWithLinks.value.push(instance.id)
}

const tooltipInstance = ref<Tippy | null | TCommon>(null)

onMounted(() => {
  nextTick(() => {
    emit('mounted')
    tooltipInstance.value = tippy(tickerItem.value, {
      content: '',
      allowHTML: true,
      interactive: true,
      appendTo: document.body,
      onShow(instance) {
        const { yearOnYear, yearToDate, chartData, updateFrequency } =
          props.index
        const yoyColor = getColor(yearOnYear)
        const yoyValue = getFormattedValue(yearOnYear)
        const ytdColor = getColor(yearToDate)
        const ytdValue = getFormattedValue(yearToDate)
        const indexPercentageColor = getColor(props.index?.percentageChange)
        const indexPercentageValue = getFormattedValue(
          props.index?.percentageChange,
        )
        const isPercentageChangeZero = percentageChange.value === ZERO
        const isPercentageChangeGreaterThanZero =
          percentageChange.value !== null && percentageChange.value > ZERO
        const iconLock =
          '<svg xmlns="http://www.w3.org/2000/svg" width="12.7" height="16" viewBox="0 0 44 56" fill="currentColor"><path d="M38 20h-2v-6a14 14 0 0 0-28 0v6H6a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h32a6 6 0 0 0 6-6V26a6 6 0 0 0-6-6Zm-26-6a10 10 0 0 1 20 0v6H12v-6Zm28 36a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V26a2 2 0 0 1 2-2h32a2 2 0 0 1 2 2v24Z"/><path  d="M22 31a4 4 0 0 0-2 7.4V43a2 2 0 0 0 4 0v-4.6a4 4 0 0 0-2-7.4Z"/></svg>'
        const iconTitle =
          '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 6H6C4.89543 6 4 6.89543 4 8V18C4 19.1046 4.89543 20 6 20H16C17.1046 20 18 19.1046 18 18V14M14 4H20M20 4V10M20 4L10 14" stroke="#FDBD00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'

        const chartWidth = 146
        const chartHeight = 73
        const SVGChart = buildSVGChart(
          chartData.data,
          chartData.dates,
          chartWidth,
          chartHeight,
        )

        const chartContainer = document.createElement('div')
        chartContainer.innerHTML = SVGChart

        chartContainer.style.width = `${chartWidth}px`
        chartContainer.style.height = `${chartHeight}px`
        chartContainer.style.margin = '8px 0 11px 0'

        const mainDataContainer = document.createElement('div')
        const headerContainer = document.createElement('div')
        const contentContainer = document.createElement('div')
        const leftContainer = document.createElement('div')
        const rightContainer = document.createElement('div')
        const rightDataContainer = document.createElement('div')
        const rightFirstRow = document.createElement('div')
        const firstRowHeader = document.createElement('div')
        const firstRowContent = document.createElement('div')
        const rightSecondRow = document.createElement('div')
        const secondRowHeader = document.createElement('div')
        const secondRowContent = document.createElement('div')
        const spanCurrent = document.createElement('span')
        const spanCurrentIndexValue = document.createElement('span')
        const spanYOY = document.createElement('span')
        const spanYOYValue = document.createElement('span')
        const spanUpdateFrequency = document.createElement('span')
        const spanUpdateFrequencyValue = document.createElement('span')
        const spanYTD = document.createElement('span')
        const spanYTDValue = document.createElement('span')
        const linkTitle = document.createElement('div')
        const spanTitle = document.createElement('span')
        const spanTitleIcon = document.createElement('span')
        const leftTitle = document.createElement('span')
        const leftIndexValue = document.createElement('span')
        const leftChartLabel = document.createElement('span')

        linkTitle.classList.add('tooltip-header-link')
        contentContainer.classList.add('tooltip-content-container')
        leftContainer.classList.add('tooltip-left-container')
        rightContainer.classList.add('tooltip-right-container')
        leftTitle.classList.add('tooltip-left-title')
        leftIndexValue.classList.add('tooltip-left-index-value')
        leftChartLabel.classList.add('tooltip-left-chart-label')
        spanTitle.classList.add('tooltip-title')

        rightDataContainer.classList.add('tooltip-right-data-container')
        rightFirstRow.classList.add('tooltip-right-row')
        rightSecondRow.classList.add('tooltip-right-row')
        spanCurrentIndexValue.classList.add('tooltip-right-row-value')
        spanYOYValue.classList.add('tooltip-right-row-value')
        spanUpdateFrequencyValue.classList.add('tooltip-right-row-value')
        spanYTDValue.classList.add('tooltip-right-row-value')

        let indexArrow = ''
        if (!isPercentageChangeZero) {
          if (isPercentageChangeGreaterThanZero) {
            indexArrow = iconArrowUp
          } else {
            indexArrow = iconArrowDown
          }
        }

        if (hasAccess.value) {
          leftIndexValue.textContent = today.value
          spanCurrentIndexValue.innerHTML = indexArrow + today.value
          spanCurrentIndexValue.classList.add(indexPercentageColor)
        } else {
          const contactUsContainer = document.createElement('div')
          contactUsContainer.classList.add('tooltip-contact-us-container')
          const contactUsSpan = document.createElement('span')
          contactUsSpan.innerHTML = 'Contact Us ' + iconArrowUp
          contactUsContainer.innerHTML = iconLock
          contactUsContainer.firstChild.style.color = '#FDC723'
          contactUsContainer.appendChild(contactUsSpan)
          leftIndexValue.appendChild(contactUsContainer)
          contactUsContainer.firstChild.style.height = '18px'
          contactUsContainer.firstChild.style.width = 'auto'
          contactUsContainer.firstChild.style.margin = '0 0 4px 0'
          spanCurrentIndexValue.innerHTML = iconLock
          spanCurrentIndexValue.firstChild.style.height = '18px'
          spanCurrentIndexValue.firstChild.style.width = 'auto'
          spanCurrentIndexValue.firstChild.style.color = '#FDC723'
        }

        spanTitle.textContent = indexName.value
        spanTitleIcon.innerHTML = iconTitle
        leftTitle.textContent = 'Current'
        leftChartLabel.textContent = '1 Year Index Trend'
        spanCurrent.textContent = 'Current'
        spanYOY.textContent = 'YOY'
        spanYOYValue.innerHTML = yoyValue
        spanYOYValue.classList.add(yoyColor)
        spanUpdateFrequency.textContent = updateFrequency?.toUpperCase()
        spanUpdateFrequencyValue.innerHTML = indexPercentageValue
        spanUpdateFrequencyValue.classList.add(indexPercentageColor)
        spanYTD.textContent = 'YTD'
        spanYTDValue.innerHTML = ytdValue
        spanYTDValue.classList.add(ytdColor)

        headerContainer.appendChild(linkTitle)
        linkTitle.appendChild(spanTitle)
        linkTitle.appendChild(spanTitleIcon)
        contentContainer.appendChild(leftContainer)
        contentContainer.appendChild(rightContainer)
        leftContainer.appendChild(leftTitle)
        leftContainer.appendChild(leftIndexValue)
        leftContainer.appendChild(leftChartLabel)
        leftContainer.appendChild(chartContainer)
        rightContainer.appendChild(rightDataContainer)
        rightDataContainer.appendChild(rightFirstRow)
        rightDataContainer.appendChild(rightSecondRow)
        rightFirstRow.appendChild(firstRowHeader)
        rightFirstRow.appendChild(firstRowContent)
        rightSecondRow.appendChild(secondRowHeader)
        rightSecondRow.appendChild(secondRowContent)
        firstRowHeader.appendChild(spanCurrent)
        firstRowHeader.appendChild(spanYOY)
        firstRowContent.appendChild(spanCurrentIndexValue)
        firstRowContent.appendChild(spanYOYValue)
        secondRowHeader.appendChild(spanUpdateFrequency)
        secondRowHeader.appendChild(spanYTD)
        secondRowContent.appendChild(spanUpdateFrequencyValue)
        secondRowContent.appendChild(spanYTDValue)

        mainDataContainer.appendChild(headerContainer)
        mainDataContainer.appendChild(contentContainer)

        instance.setContent(
          `<div class="ticker-tooltip">${mainDataContainer.outerHTML}</div>`,
        )
      },
      onMount(_instance) {
        const content: HTMLDivElement = document.querySelector('.tippy-content')
        content.style.top = '12px'
        const arrow: HTMLDivElement = document.querySelector('.tippy-arrow')
        arrow.style.top = '12px'
      },
      onShown(instance) {
        setOpenModalListener(instance)
        setTickerAnimationListeners()
        setNavigationListener(instance)
      },
    })
  })
})

onUnmounted(() => {
  if (tooltipInstance.value) {
    tooltipInstance.value.destroy()
    tooltipInstance.value = null
  }
})
</script>

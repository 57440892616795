type TCarouselClasses = {
  articleIndex: number
  carouselIndex: number
  indexMaximum: number
}

export const getClassesContainerActive = ({
  articleIndex,
  carouselIndex,
  indexMaximum,
}: TCarouselClasses) => {
  if (articleIndex === carouselIndex) return 'translate-x-0 z-[1]'

  if (articleIndex === 0) {
    if (carouselIndex === indexMaximum) return 'translate-x-[100%] opacity-0'
    return '-translate-x-[100%] opacity-0'
  }

  if (articleIndex === indexMaximum) {
    if (carouselIndex === 0) return '-translate-x-[100%] opacity-0'
    return 'translate-x-[100%] opacity-0'
  }

  if (articleIndex < carouselIndex) return '-translate-x-[100%] opacity-0'
  return 'translate-x-[100%] opacity-0'
}

import { useAuthorization } from '~/composables/useAuthorization'
import {
  PRICE_DASHBOARD,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
} from '~/constants/services/constants'
import { hasPricingMenu } from '~/utils/memberships'
import { useMembershipsStore } from '~/stores/memberships'

export default defineNuxtRouteMiddleware(async (_, from) => {
  const { hasMembership } = useAuthorization()
  const membershipsStore = useMembershipsStore()
  let isMembershipActive = await hasMembership(
    SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
  )

  if (!isMembershipActive) {
    const memberships = membershipsStore.state
    isMembershipActive = hasPricingMenu(memberships)
  }

  if (!isMembershipActive) {
    return navigateTo(`/${PRICE_DASHBOARD.slug}`)
  }

  if (from.fullPath === `/${PRICE_DASHBOARD.slug}/`) {
    return navigateTo(`/${PRICE_DASHBOARD.slug}/chart-builder`)
  }
})

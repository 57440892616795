import { computed } from 'vue'
import { VARIANT_CLASSES } from '@/constants/variant-button-classes'
import { EventBus } from '~/utils/eventBus'

export function useButtonClassesAndEvent(
  variant: (typeof VARIANT_CLASSES.BASE)[keyof typeof VARIANT_CLASSES.BASE] = 'primary',
  eventId: string = 'common',
) {
  const baseClasses = computed(() => VARIANT_CLASSES.BASE[variant])
  const backgroundColorDownload = computed(
    () => VARIANT_CLASSES.DOWNLOAD[variant],
  )

  const handleClickButtonCommon = () => {
    EventBus.$emit('clickButtonCommon', eventId)
  }

  return {
    baseClasses,
    backgroundColorDownload,
    handleClickButtonCommon,
  }
}

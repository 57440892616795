import Cookies from 'js-cookie'
import { parse as parseCookie } from 'cookie-es'
import { DEFAULT_OPTIONS } from '~/src/constants/common'

const CONSENT_KEY = 'cookie-accepted'

export default defineNuxtPlugin({
  name: 'cookies',
  setup() {
    const req = useRequestHeaders()

    const cookies = {
      getCookieValue(key: string) {
        if (import.meta.server) {
          return parseCookie(req.cookie || '')[key] ?? undefined
        }

        return Cookies.get(key)
      },

      get(key: string) {
        const cookieValue = this.getCookieValue(key)

        try {
          return JSON.parse(cookieValue)
        } catch {
          return cookieValue
        }
      },

      set(key: string, value: any, options?: Cookies.CookieAttributes) {
        let cookieValue = value

        if (typeof value === 'object') {
          cookieValue = JSON.stringify(value)
        }

        Cookies.set(key, cookieValue, { ...DEFAULT_OPTIONS, ...options })
      },

      remove: Cookies.remove,

      setConsentCookie(accepted: boolean) {
        this.set(CONSENT_KEY, accepted)
      },

      getConsentCookie(): string | undefined {
        return this.get(CONSENT_KEY)
      },
    }

    return { provide: { cookies } }
  },
})

/** packages */
import type { AxiosInstance } from 'axios'

/** utils */
import { getNewToken, getToken } from '~/utils/auth'

/** constants */
import { REFRESH_TOKEN } from '~/src/constants/common'
import { UNAUTHORIZED } from '~/constants/http'

/** types */
import type { TGeneral } from '~/src/types/common'
import type { THttpProxyResponse } from '~/src/types/http'

export class HttpProxy {
  protected fetcher: AxiosInstance
  protected maxNumberOfRetries = 0
  protected currentRetries = 0
  protected cookies: TGeneral
  protected config: TGeneral

  constructor(
    $fetcher: AxiosInstance,
    $cookies: TGeneral,
    $config: TGeneral,
    options: TGeneral,
  ) {
    this.fetcher = $fetcher
    this.maxNumberOfRetries = options.maxNumberOfRetries || 0
    this.cookies = $cookies
    this.config = $config
  }

  static buildHttpOptions = async (
    baseOptions: TGeneral,
    $cookies: TGeneral,
    $config: TGeneral,
  ) => {
    const jwtToken: string = await getToken({
      $cookies,
      $config,
    })

    if (!jwtToken) return baseOptions

    return {
      ...baseOptions,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  }

  async request(
    url: string,
    baseOptions: TGeneral,
  ): Promise<THttpProxyResponse> {
    const refreshToken = this.cookies.get(REFRESH_TOKEN)
    const options = await HttpProxy.buildHttpOptions(
      baseOptions,
      this.cookies,
      this.config,
    )

    try {
      const response = await this.fetcher({
        url,
        ...options,
      })
      return { response, error: null }
    } catch (e) {
      if (
        e?.response?.status === UNAUTHORIZED &&
        refreshToken &&
        this.currentRetries < this.maxNumberOfRetries
      ) {
        this.currentRetries++
        await getNewToken({ $cookies: this.cookies, $config: this.config })
        return this.request(url, options)
      }

      return { error: e, response: null }
    }
  }
}

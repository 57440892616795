<template>
  <button
    type="button"
    class="default-button-height relative flex w-full flex-row items-center justify-center gap-x-1 overflow-hidden rounded-md border px-4 py-3 text-base font-semibold leading-5 lg:gap-x-3"
    :class="[baseClasses, $attrs.class]"
    @click="handlerDownload"
  >
    <Icon
      v-if="isButtonDownload && !downloading && showIcon"
      name="icon-download"
      class="z-index h-4 w-full min-w-4 max-w-4"
    />
    <Icon
      v-if="isButtonDownload && downloading && showIcon"
      name="Close"
      class="z-index h-4 w-full min-w-4 max-w-4"
    />
    <span
      v-if="isButtonDownload && showLabel"
      class="z-index"
      :class="textClasses"
    >
      {{ buttonText }}
    </span>
    <span
      v-if="isButtonDownload"
      class="progress-bar-container"
      :class="backgroundColorDownload"
      :style="progressBarDownloadingStyles"
    />
    <NavigationLink v-if="!isButtonDownload" :url="url" :class="textClasses">
      {{ buttonText }}
    </NavigationLink>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Icon from '~/components/common/Icon.vue'
import NavigationLink from '~/components/shared/navigation/Link.vue'
import { useDownloadReport } from '~/composables/useDownloadReport'
import { useButtonClassesAndEvent } from '~/composables/useButtonClassesAndEvent'
import type { TDownloadFile } from '~/types/benchmark-app.type'

const props = withDefaults(
  defineProps<{
    text?: string
    showIcon?: boolean
    downloadFile: TDownloadFile
    variant: string
    showLabel?: boolean
    textClasses?: string
  }>(),
  {
    text: 'Download',
    showIcon: true,
    showLabel: true,
    textClasses: '',
  },
)

const { baseClasses, backgroundColorDownload } = useButtonClassesAndEvent(
  props.variant,
)
const {
  downloading,
  cancelDownload,
  downloadReport,
  downloadVideoVimeo,
  progressBarDownloadingStyles,
} = useDownloadReport()

const buttonText = computed(() => (downloading.value ? 'Cancel' : props.text))
const downloadCategory = computed(() => props.downloadFile?.category)
const isButtonDownload = computed(() => !props.downloadFile?.url)
const url = computed(() => props.downloadFile?.url || '')

const handlerDownload = () => {
  if (isButtonDownload.value) {
    if (downloading.value) {
      cancelDownload()
      return
    }
    if (props.downloadFile?.isVideoVimeo) {
      downloadVideoVimeo(
        props.downloadFile?.vimeoId,
        props.downloadFile?.vimeoAuthToken,
      )
    } else {
      downloadReport(props.downloadFile, downloadCategory.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar-container {
  position: absolute;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 0;
}
.z-index {
  z-index: 2;
}
</style>

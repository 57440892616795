<template>
  <div :class="[$attrs.class, 'scroll-custom flex min-h-full w-full flex-col']">
    <TheHeader />

    <div
      class="mt-[46px] flex flex-col md:mt-[82px] lg:mt-0"
      :class="{ content: !withoutContentClass }"
    >
      <slot />
    </div>

    <TheFooter v-if="!withoutFooter" />

    <ContactModal v-show="$modal.getModal('contact')" />

    <CommonToast
      :show="toast.show"
      :position="toast.position"
      :type="toast.type"
      :message="toast.message"
      :duration="toast.duration"
      :icon="toast.icon"
      @close="closeToast"
    />
  </div>
</template>

<script setup lang="ts">
import TheHeader from '~/components/TheHeader.vue'
import TheFooter from '~/components/TheFooter.vue'
import { useToastStore } from '~/stores/toast'

interface Props {
  withoutFooter?: boolean
  withoutContentClass?: boolean
}

withDefaults(defineProps<Props>(), {
  withoutFooter: false,
  withoutContentClass: false,
})

const toast = useToastStore()
const closeToast = () => toast.clearToast()
</script>

<style scoped>
.content {
  min-height: calc(100vh - 48px);
}

@media (width >= 768px) {
  .content {
    min-height: calc(100vh - 84px);
  }
}

@media (width >= 1280px) {
  .content {
    min-height: calc(100vh - 153px);
  }
}
</style>

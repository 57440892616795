/** types */
import type { TScript } from '../types/nuxt-config'

export const hubspotConfiguration = (id: string | number): TScript => ({
  src: `https://js-eu1.hs-scripts.com/${id}.js`,
  id: 'hs-script-loader',
  type: 'text/javascript',
  defer: true,
})

export const loadLiveChat = ({ email, token }) => {
  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: email,
    identificationToken: token,
  }

  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load()
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load()
      },
    ]
  }
}

export const openLiveChat = () => {
  window.HubSpotConversations.widget.open()
}

export const removeLiveChat = () => {
  window.HubSpotConversations.widget.remove()
  window.HubSpotConversations.clear({ resetWidget: true })
}

export const refreshLiveChat = () => {
  if (!window.HubSpotConversations?.widget) return

  const status = window.HubSpotConversations.widget.status()

  if (status.loaded) {
    window.HubSpotConversations.widget.close()
    window.HubSpotConversations.widget.refresh()
  } else {
    window.HubSpotConversations.widget.load()
  }
}

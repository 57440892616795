import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
  checkAllPredefinedOptions,
  createDynamicFilter,
  generateCombinations,
  sortOptionsAlphabetically,
} from '~/utils/chart'
import {
  DEFAULT_PRESELECTED,
  DEFAULT_PRESELECTED_COMBINATIONS,
} from '~/constants/chartSidebarFilter'

export const useChartSidebarFilterStore = defineStore(
  'chartSidebarFilter',
  () => {
    const catalog = ref<Record<string, any>>({})
    const preselected = ref(DEFAULT_PRESELECTED)
    const preselectedCombinations = ref(DEFAULT_PRESELECTED_COMBINATIONS)

    const fetchCatalogByMineral = ({
      mineral,
      response,
    }: {
      mineral: string
      response: any
    }) => {
      catalog.value[mineral] = { response }
    }

    const getPricesCatalogByMineral = (market: string) => {
      const catalogData = catalog.value[market]?.response

      if (!catalogData) return []

      const filters = createDynamicFilter(
        catalogData,
        catalogData.nextLevel,
        null,
        null,
      )
      const checkedAllOptions = checkAllPredefinedOptions(filters)
      const sortedData = sortOptionsAlphabetically(checkedAllOptions)

      return Object.values(sortedData)
    }

    const getAvailableCombinations = (mineral: string) => {
      const catalogData = catalog.value[mineral]?.response

      if (!catalogData) return []
      return generateCombinations(catalogData)
    }

    const getAvailablePreselectedCombinations = (mineral: string) => {
      return preselectedCombinations.value[mineral]
    }

    return {
      catalog,
      preselected,
      preselectedCombinations,
      fetchCatalogByMineral,
      getPricesCatalogByMineral,
      getAvailableCombinations,
      getAvailablePreselectedCombinations,
    }
  },
)

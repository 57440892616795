<template>
  <transition name="translate-x">
    <div
      v-if="open"
      data-testid="mobile-menu"
      class="mobile-menu fixed left-0 top-[46px] z-[998] flex w-full flex-col items-center bg-white md:top-20 lg:hidden"
    >
      <div v-show="hasTitle" class="bg-s-100" @click="backPreviousMenu">
        <button
          aria-label="Back Mobile Button"
          class="absolute left-0 top-3 ml-5 p-1"
        >
          <IconLeftAngle class="h-4 w-4" />
        </button>

        <div
          class="border-s-600 text-s-900 w-screen border-b border-solid py-3 pl-12 text-lg font-semibold"
        >
          {{ menuTitle }}
        </div>
      </div>

      <section
        class="w-screen overflow-y-auto pb-2 px-5"
        :class="[hasTitle ? 'bg-white' : 'bg-s-100']"
      >
        <MobileMenuItem
          v-for="item in menuList"
          :key="item.name"
          :item="item"
          @handle-click="handleClick"
        />

        <MobileMenuItem v-if="!hasTitle" @handle-click="closeMobileMenu">
          <div class="flex flex-col gap-y-4 w-full">
            <SourceButton class="w-full flex justify-center" />
            <ButtonContactUs class="py-2 px-4 leading-none w-full" />
          </div>
        </MobileMenuItem>

        <MobileMenuItem v-if="!hasTitle" @handle-click="signInOut">
          {{ signInOutText }}
        </MobileMenuItem>
      </section>
    </div>
  </transition>
</template>

<script setup lang="ts">
import IconLeftAngle from '~/assets/icons/angle-left.svg'
import { SIGN_IN } from '~/constants/services/constants'
import useAuthStore from '~/stores/auth'
import MobileMenuItem from '~/components/navigation/MobileMenuItem.vue'
import SourceButton from '~/components/navigation/SourceButton.vue'
import ButtonContactUs from '~/components/shared/common/ButtonContactUs.vue'

const props = withDefaults(
  defineProps<{
    menu?: Array<any>
    initialMenuTitle?: string
    open?: boolean
    modalName: string
  }>(),
  {
    data: () => [],
    initialMenuTitle: 'Menu',
    open: false,
  },
)

const router = useRouter()
const authStore = useAuthStore()
const { $modal, $bmAuth } = useNuxtApp()

const menuList = ref(props.menu)
const menuTitle = ref(props.initialMenuTitle)
const previousMenuList = ref([])

const hasTitle = computed(() => menuTitle.value !== props.initialMenuTitle)
const authenticated = computed(() => authStore.authenticated)
const groups = computed(() => props.menu.flatMap((e) => e.groups))
const signInOutText = computed(() =>
  authenticated.value ? 'Sign Out' : SIGN_IN,
)

const signInOut = () => {
  authenticated.value ? logout() : signIn()
}

const signIn = () => {
  closeMobileMenu()
  $modal.open('signIn')
}

const handleClick = async (item) => {
  const newMenuList = item?.children || item?.groups

  if (newMenuList?.length) {
    menuTitle.value = item.name
    previousMenuList.value = menuList.value
    menuList.value = newMenuList
    return
  }

  if (item?.name?.toLowerCase() === 'source') {
    window.location.href = item.href
    closeMobileMenu()
    return
  }

  const route = item?.parent ? `${item.parent}${item.href}` : item.href
  await router.push(route)

  closeMobileMenu()
}

const resetMenu = () => {
  menuTitle.value = props.initialMenuTitle
  menuList.value = props.menu
}

const closeMobileMenu = () => {
  $modal.close(props.modalName)
  resetMenu()
}

const backPreviousMenu = () => {
  const menuInGroupsLevel = groups.value.find((e) => e.name === menuTitle.value)

  let previousMenu = props.menu
  let parentMenu = props.initialMenuTitle
  if (menuInGroupsLevel) {
    for (const item of props.menu) {
      if (item.groups) {
        const group = item.groups.find(
          (g) => g.name === menuInGroupsLevel.name && !g.initial,
        )
        if (group) {
          parentMenu = item.name
          previousMenu = item.groups
          break
        }
      }
    }

    if (previousMenu === props.menu) {
      previousMenu = previousMenuList.value
    }
  }

  menuTitle.value = parentMenu
  menuList.value = previousMenu
}

const logout = async () => {
  $bmAuth.logout()
  closeMobileMenu()
  await router.push('/')
}
</script>

<style scoped>
.mobile-menu {
  height: calc(100dvh - 48px);
}

@media (min-width: 768px) {
  .mobile-menu {
    height: calc(100dvh - 80px);
  }
}

.translate-x-enter {
  transform: translateX(-100%);
}

.translate-x-enter-active,
.translate-x-leave-active {
  transition: 0.5s ease;
}

.translate-x-enter-from,
.translate-x-leave-to {
  transform: translateX(-100%);
}
</style>

/** types */
import type { TForm, TFormValue } from '~/src/types/form'
import type { TCommon } from '~/src/types/common'

export class FormErrors<T extends TForm<T>> {
  errors = {} as TForm<TCommon>

  get(key: keyof T | TCommon): TFormValue {
    return this.errors[key] ?? null
  }

  set(errors: T | TCommon) {
    this.errors = errors
  }

  hasErrors() {
    return Object.keys(this.errors).length
  }

  setError(key: keyof T, message: string) {
    this.errors[key as string] = message
  }
}

<template>
  <div
    :class="[
      twMerge('flex h-full flex-col items-center justify-center', attrs.class),
    ]"
  >
    <Spinner :class="[twMerge('text-p-500 animate-spin', sizeClasses)]" />
  </div>
</template>

<script setup lang="ts">
/** packages */
import { twMerge } from 'tailwind-merge'

/** assets */
import Spinner from '~/assets/icons/Spinner.svg'

/** types */
import type { TGeneral } from '~/src/types/common'
import type { TLoaderSize } from '~/src/types/markets'

/** props */
const props = withDefaults(
  defineProps<{
    size?: TLoaderSize
  }>(),
  {
    size: 'large',
  },
)

/** app */
const attrs = useAttrs() as TGeneral

/** computed */
const sizeClasses = computed(() =>
  props.size === 'small' ? 'w-8 h-8' : 'w-16 h-16',
)
</script>

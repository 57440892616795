import { format, isSameDay, parseISO, sub, subDays } from 'date-fns'
import type { Filter } from '~/types/daily-indicator.type'

export const reformat = (stringDate: string, to = 'MMM dd, yyyy') => {
  try {
    const parsedDate = parseISO(stringDate)
    return format(parsedDate, to)
  } catch {
    return format(parsePresentationStringDate(stringDate), 'yyy-MM-dd')
  }
}

export const parsePresentationStringDate = (stringDate: string) => {
  // This function takes a string date in the format "yyy-MM-dd HH:mm:ss" and parses it to a JS Date.
  // We expect the presentation date to come in this format: "yyyy-MM-dd HH:mm:ss".
  // For example, "2022-08-22 12:38:32"
  // We use the parseISO function to avoid some issues in Safari
  return parseISO(stringDate)
}

// Get the last calendar year date range for the given date.
export const getLastCalendarYear = (date) => {
  const yearStartDate = sub(date, { years: 1 })
  return { start: yearStartDate, end: date }
}

export const formatDateRange = (
  startDateTime: number | Date,
  endDateTime: number | Date,
  _formatDay = '',
) => {
  if ((!startDateTime && !endDateTime) || (!startDateTime && endDateTime)) {
    return
  }

  if (startDateTime && !endDateTime) {
    return format(startDateTime, `${_formatDay} d MMMM yyyy`)
  }

  if (isSameDay(startDateTime, endDateTime)) {
    return format(startDateTime, `${_formatDay} d MMMM yyyy`)
  }

  const sameYear = format(startDateTime, 'yyyy') === format(endDateTime, 'yyyy')
  const sameMonth =
    format(startDateTime, 'MMMM') === format(endDateTime, 'MMMM')
  const sameDay = format(startDateTime, 'd') === format(endDateTime, 'd')

  let startDate: string
  let endDate: string

  if (sameYear && sameMonth && !sameDay) {
    startDate = format(startDateTime, `${_formatDay} d`)
    endDate = format(endDateTime, `${_formatDay} d MMMM yyyy`)
  }

  if (sameYear && !sameMonth && !sameDay) {
    startDate = format(startDateTime, `${_formatDay} d MMMM`)
    endDate = format(endDateTime, `${_formatDay} d MMMM yyyy`)
  }

  if (!sameYear && !sameMonth && !sameDay) {
    startDate = format(startDateTime, `${_formatDay} d MMMM yyyy`)
    endDate = format(endDateTime, `${_formatDay} d MMMM yyyy`)
  }

  return `${startDate} - ${endDate}`
}

// Get the YTD range for the given date.
export const getYTD = (date) => {
  const yearStartDate = new Date(new Date().getFullYear(), 0, 1)
  return { start: yearStartDate, end: date }
}

export const getDayDifference = (
  fromDate: string | number | Date,
  toDate: string | number | Date,
) => {
  const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24
  const fromDateObj = new Date(fromDate)
  const toDateObj = new Date(toDate)
  const diffTime = Math.abs(toDateObj.getTime() - fromDateObj.getTime())
  return Math.ceil(diffTime / MILLISECONDS_IN_A_DAY)
}

export const getDateRange = (filterName: Filter) => {
  const daysIn = {
    oneWeek: 5,
    oneMonth: 30,
    threeMonths: 90,
    oneYear: 365,
  }

  const todayDate = new Date()
  const to = format(todayDate, 'yyyy-MM-dd')

  const from = format(subDays(todayDate, daysIn[filterName]), 'yyyy-MM-dd')
  return { from, to }
}

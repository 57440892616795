<template>
  <Layout class="bg-s-50">
    <LayoutPreviewSite>
      <div
        class="max-w-content-container mx-auto mt-[46px] flex h-full w-full flex-grow flex-col px-0 lg:mt-0 lg:flex-row lg:px-14 2xl:px-0"
      >
        <Sidebar
          :key="fullPath"
          :menus="menus"
          :service="service"
          :active-menu="activeMenu"
          :active-sub-menu="activeSubMenu"
        />

        <div
          class="price-assessments min-h-[80vh] w-full flex-1 px-4 lg:px-0"
          :class="
            (isForecastsService && 'pt-0') ||
            (isGuestPriceAssessmentsBuilder && 'relative')
          "
        >
          <div
            v-if="isGuestPriceAssessmentsBuilder"
            id="price-assessments-chart-builder-background"
            class="absolute right-0 top-0 h-full w-full bg-[url('https://res.cloudinary.com/benchmarkminerals/image/upload/v1710448492/assets/price-dashboard/AdobeStock_484193150_1_Converted_copy-01_1_j21nro.png')] bg-cover bg-no-repeat"
          />
          <div
            class="xl:pl-big w-full pb-20 pt-5 lg:pb-36 lg:pl-8"
            :class="isGuestPriceAssessmentsBuilder && 'relative'"
          >
            <Breadcrumb :menu="items" class="mb-6" />
            <slot />
          </div>
        </div>
      </div>
    </LayoutPreviewSite>
  </Layout>
</template>

<script setup lang="ts">
/** components */
import Breadcrumb from '~/components/shared/navigation/Breadcrumb.vue'
import Layout from '~/components/TheLayout.vue'
import LayoutPreviewSite from '~/components/PreviewSiteLayout.vue'
import Sidebar from '~/components/common/Sidebar.vue'

/** constants */
import { FORECASTS, PRICE_ASSESSMENTS } from '~/constants/services/constants'

/** stores */
import { useSidebarStore } from '~/stores/sidebar'
import { useBreadcrumbStore } from '~/stores/breadcrumb'
import useAuthStore from '~/stores/auth'

/** app */
const $route = useRoute()
const { items } = useBreadcrumbStore()
const { authenticated } = useAuthStore()
const { state } = useSidebarStore()

/** computed */
const fullPath = computed(() => $route.fullPath)

const isGuestPriceAssessmentsBuilder = computed(() => {
  return isPriceAssessmentsServiceAndBuilder.value && !loggedIn.value
})
const activeMenu = computed(
  () => $route.path.split('/')[2]?.toLowerCase() || 'home',
)
const activeSubMenu = computed(
  () => $route.path.split('/')[3]?.toLowerCase() || 'overview',
)
const service = computed(() => $route.path.split('/')[1])
const isForecastsService = computed(() => service.value === FORECASTS.slug)
const isPriceAssessmentsServiceAndBuilder = computed(
  () =>
    service.value === PRICE_ASSESSMENTS.slug &&
    activeSubMenu.value === 'price-chart-builder',
)
const loggedIn = computed(() => authenticated)
const menus = computed(() => {
  const sidebar = state[service.value]?.map((menuItem) => ({
    ...menuItem,
    icon: menuItem.icon ? `sidebar/${menuItem.icon}` : 'sidebar/Home',
  }))
  return sidebar ?? []
})
</script>

<style scoped>
@media (width > 1024px) {
  .price-assessments {
    max-width: calc(100vw - 379px);
  }
}

.price-assessments {
  min-width: 0;
}
</style>

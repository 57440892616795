import { defineNuxtPlugin } from 'nuxt/app'
import { type TSetToastParams, useToastStore } from '~/stores/toast'

export default defineNuxtPlugin(() => {
  const toastStore = useToastStore()

  const clear = () => toastStore.clearToast()

  const show = ({
    message,
    type,
    position,
    duration,
    icon,
  }: TSetToastParams) => {
    const isShow = toastStore.show
    const config = {
      duration,
      icon,
      message,
      position,
      type,
    }

    if (!isShow) return toastStore.setToast(config)

    clear()

    setTimeout(() => toastStore.setToast(config), 50)
  }
  return {
    provide: {
      toast: { show, clear },
    },
  }
})

<template>
  <div>
    <div class="flex flex-col items-center gap-x-7 lg:flex-row lg:items-start">
      <div class="flex w-full flex-col items-center">
        <CommonButton
          variant="primary"
          type="button"
          class="text-lg"
          @click="$modal.open('fileUploader')"
        >
          {{ label }}
        </CommonButton>
        <p v-if="attachmentCounter" class="mt-1">
          <span class="font-bold">
            {{ attachmentCounter }}
          </span>
          file(s) added.</p
        >
      </div>

      <p v-if="errorText" class="font-lato text-sm text-error">
        {{ errorText }}
      </p>
      <p
        v-else-if="helpText"
        class="text-center font-lato text-sm text-surface-em-medium lg:text-left"
      >
        {{ helpText }}
      </p>
    </div>

    <CommonFileUploaderModal
      v-show="$modal.getModal('fileUploader')"
      ref="formidable-file-uploader-modal"
      @set-files="setFiles"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import CommonButton from '~/components/common/Button.vue'
import CommonFileUploaderModal from '~/components/common/FileUploaderModal.vue'
import type { TFile, TFileUploaderField } from '~/src/types/download'

const emit = defineEmits(['update:modelValue'])

const props = withDefaults(defineProps<TFileUploaderField>(), {
  name: null,
  id: null,
  helpText: '',
  errorText: '',
})

const { $modal } = useNuxtApp()
const files = ref<Array<TFile>>([])
const attachmentCounter = computed(() => files.value.length)

watch(
  () => props.modelValue,
  (newValue) => {
    files.value = newValue
    if (!files.value.length) {
      reset()
    }
  },
)

const setFiles = (newFiles: Array<TFile>) => {
  files.value = newFiles
  emit('update:modelValue', newFiles)
}

const reset = () => {
  const modalRef = ref<InstanceType<typeof CommonFileUploaderModal>>()
  modalRef.value?.reset()
}
</script>

<template>
  <div
    data-testid="capex-tracker"
    class="absolute flex h-full w-full min-w-full origin-left cursor-pointer select-none items-center justify-between overflow-hidden bg-s-900 transition-all duration-700 ease-in-out"
    :class="classesContainerActive"
    @click="$modal.open('capexTracker')"
  >
    <!-- Image -->
    <div class="absolute top-0 h-full w-full">
      <img
        :src="imageUrl"
        alt="capex tracker background"
        class="image min-h-full w-full object-cover lg:h-full"
        data-testid="capex-image"
      />
    </div>
    <div
      class="z-10 flex h-full w-full flex-col justify-between px-5 pb-7 pt-6 md:px-8 md:py-5 md:pt-8 lg:px-10 lg:pt-8"
    >
      <div class="h-fit text-white">
        <div class="mb-3 flex items-baseline justify-start">
          <BenchmarkDataLogo class="h-3.5 w-auto md:h-4 5xl:h-6" />
          <p class="ml-1 text-xs md:text-[13px] 5xl:text-lg">DATA</p>
        </div>

        <h2
          class="line-clamp-2 font-bold md:mb-3 md:text-xl md:leading-6"
          data-testid="capex-title"
        >
          Capital Tracker: {{ material }}
        </h2>

        <p class="text-2xl font-bold md:text-3xl">${{ value }}</p>
      </div>

      <CommonButton
        variant="clean"
        class="hidden !w-fit text-sm md:flex"
        data-testid="capex-button"
      >
        View Now
      </CommonButton>
    </div>
  </div>
</template>
<script setup lang="ts">
/** components */
import CommonButton from '~/components/common/Button.vue'
import BenchmarkDataLogo from '~/assets/icons/BenchmarkLogo.svg'

/** utils */
import { getClassesContainerActive } from '~/utils/carousel'

/** props */
const props = withDefaults(
  defineProps<{
    material: string
    value: string
    imageUrl: string
    buttonClasses?: string
    indexMaximum?: number | null
    carouselIndex?: number | null
    articleIndex?: number | null
  }>(),
  {
    buttonClasses: '',
    indexMaximum: null,
    carouselIndex: null,
    articleIndex: null,
  },
)

/** app */
const { $modal } = useNuxtApp()

/** computed */
const classesContainerActive = computed(() => {
  if (
    props.indexMaximum === null ||
    props.carouselIndex === null ||
    props.articleIndex === null
  ) {
    return ''
  }

  return getClassesContainerActive({
    articleIndex: props.articleIndex,
    carouselIndex: props.carouselIndex,
    indexMaximum: props.indexMaximum,
  })
})
</script>

<style scoped>
.image {
  background: linear-gradient(90deg, #00000087 0%, #0000006b 59.74%, #000 100%);
}
</style>

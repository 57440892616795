import { defineNuxtPlugin } from 'nuxt/app'
import { useModalStore } from '~/stores/modal'

export default defineNuxtPlugin(() => {
  const modalStore = useModalStore()

  const open = (modalName) => modalStore.open(modalName)

  const close = (modalName) => modalStore.close(modalName)

  const getModal = (modalName) => modalStore.getModal(modalName)

  const setInitialFields = (fields) => modalStore.setInitialFields(fields)

  const setRenewData = (data) => modalStore.setRenewData(data)

  return {
    provide: {
      modal: { open, close, getModal, setInitialFields, setRenewData },
    },
  }
})

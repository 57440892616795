export const asyncSome = async (arr, predicate) => {
  for (const e of arr) {
    if (await predicate(e)) return true
  }
  return false
}

export const arrayToPairs = (arr) => {
  const pairs = []
  for (let i = 0; i < arr.length; i += 2) {
    pairs.push([arr[i], arr[i + 1]])
  }

  const cleanPairs = pairs.map((subArray) =>
    subArray.filter((item) => item !== null && item !== undefined),
  )

  return cleanPairs
}

// https://bost.ocks.org/mike/shuffle/
export function shuffleArray(array: any[]) {
  const arr = [...array]
  let m = arr.length
  let t: any
  let i: any

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--)

    // And swap it with the current element.
    t = arr[m]
    arr[m] = arr[i]
    arr[i] = t
  }

  return arr
}

// it will shuffle the array after reaching the end
export function* getInfiniteShuffledIterator(array: any[]) {
  let arr = [...array]
  let index = 0
  while (true) {
    if (index === arr.length) {
      index = 0
      arr = shuffleArray(arr)
    }
    yield arr[index]
    index++
  }
}

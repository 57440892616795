/** constants */
import {
  CLOUDINARY_MODIFIERS_BY_DEFAULT,
  CLOUDINARY_PATH,
  CLOUDINARY_QUERY_STRINGS,
} from '~/src/constants/cloudinary'

/**
 * Build a Cloudinary route with the given image URL and modifiers.
 * @param imageUrl e.g. 'v1685978797/assets/home/HomeHeroSuperWide_lxyvhm.png'
 * @param modifiers e.g. ['w_100']
 * @param hasDefaultModifiers e.g. true
 * @param modifiersExtra e.g. ['c_fill', 'c_scale']
 */
export function cloudinaryBuildRoute(
  imageUrl: string,
  modifiers: string[],
  hasDefaultModifiers = true,
  modifiersExtra: string[] = [],
): string {
  if (hasDefaultModifiers) {
    const transformationsDefault = Array.from(
      new Set([...CLOUDINARY_MODIFIERS_BY_DEFAULT, ...modifiersExtra]),
    ).join(',')
    const transformations = modifiers.join(',')
    if (transformations) {
      return `${CLOUDINARY_PATH}/${transformations}/${transformationsDefault}/${imageUrl}${CLOUDINARY_QUERY_STRINGS}`
    }
    return `${CLOUDINARY_PATH}/${transformationsDefault}/${imageUrl}${CLOUDINARY_QUERY_STRINGS}`
  }

  const transformations = modifiers.join(',')

  if (modifiersExtra.length) {
    const transformationsExtra = modifiersExtra.join(',')
    return `${CLOUDINARY_PATH}/${transformations}/${transformationsExtra}/${imageUrl}${CLOUDINARY_QUERY_STRINGS}`
  }

  return `${CLOUDINARY_PATH}/${transformations}/${imageUrl}${CLOUDINARY_QUERY_STRINGS}`
}

<template>
  <component
    :is="componentByType"
    :class="$attrs.class"
    v-bind="propsByType"
    :target="target"
    no-prefetch
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'

const props = withDefaults(
  defineProps<{
    type?: string
    url: string | object
    target?: string
  }>(),
  {
    type: 'nuxt-link',
    target: '_parent',
  },
)

const componentByType = computed(() => {
  return props.type === 'nuxt-link' ? resolveComponent('NuxtLink') : 'a'
})

const propsByType = computed(() => {
  return props.type === 'nuxt-link' ? { to: props.url } : { href: props.url }
})
</script>

<template>
  <div
    id="sidebar"
    class="fixed top-[46px] z-40 w-full min-w-[235px] bg-white text-xs leading-3 md:top-[82px] lg:relative lg:top-0 lg:z-10 lg:mb-8 lg:w-[235px]"
  >
    <button
      class="flex w-full items-center gap-1 bg-grey-900 px-4 py-2.5 lg:hidden"
      @click="toggleOpenMobile"
    >
      <span class="font-bold text-white">
        {{ defaultMainMenuName ? defaultMainMenuName : sectionName }}
      </span>
      <span
        :class="{
          'animate-bounce-menu': !openMobileMenu,
        }"
      >
        <IconChevronDown
          class="w-5 text-p-400 duration-300"
          :class="{
            'rotate-180': openMobileMenu,
          }"
        />
      </span>
    </button>

    <div
      :class="{ 'h-fit pb-28': openMobileMenu, 'h-0': !openMobileMenu }"
      class="absolute z-10 flex max-h-[calc(100vh-105px)] w-full flex-col overflow-hidden overflow-y-auto bg-grey-100 shadow-file transition-all duration-300 lg:sticky lg:top-[153px] lg:bg-white lg:shadow-none xl:top-[105px]"
    >
      <client-only>
        <div
          v-for="menu in menus"
          :key="menu.name"
          class="relative flex w-full flex-col items-center font-medium before:absolute before:bottom-0 before:left-3.5 before:right-3.5 before:block before:h-px before:content-[''] lg:before:hidden"
          :class="{
            'bg-grey-300 text-s-900': isMenuActive(menu.name),
            'text-s-700': !isMenuActive(menu.name),
          }"
        >
          <button
            v-if="hasChildrenByAccess(menu)"
            class="flex w-full items-center justify-between gap-2 px-3.5 py-2.5"
            @click="toggleMenu(menu.name)"
          >
            <span class="flex items-center gap-2 font-medium">
              <span class="h-5 w-5">
                <Icon
                  v-if="menu.icon"
                  :name="menu.icon"
                  class="h-5 w-5 lg:text-inherit"
                />
              </span>
              <span class="text-left">{{ menu.label }}</span>
            </span>

            <IconChevronDown
              class="w-full min-w-[22px] max-w-[22px] duration-500"
              :class="{ 'rotate-180': menu.open }"
            />
          </button>

          <NuxtLink
            v-else
            no-prefetch
            :to="menu.to"
            class="flex w-full items-center justify-between gap-2 px-3.5 py-2.5"
          >
            <div class="flex items-center gap-2">
              <div class="h-5 w-5">
                <Icon v-if="menu.icon" :name="menu.icon" class="h-5 w-5" />
              </div>
              <div class="text-left">{{ menu.label }}</div>
            </div>

            <IconChevronDown
              class="w-full min-w-[22px] max-w-[22px] opacity-0"
            />
          </NuxtLink>

          <div
            :class="{
              'unfold-animation': isOpenSubmenu(menu),
              'fold-animation': !isOpenSubmenu(menu),
            }"
            class="submenu flex w-full flex-col bg-grey-300 lg:bg-s-50"
          >
            <div v-for="subitem in menu.children" :key="subitem.name">
              <a
                v-if="subitem.externalLink"
                :href="subitem.to"
                target="_blank"
                class="flex bg-[#020d1d]"
              >
                <div class="flex items-center gap-1.5 py-3 pl-6">
                  <Icon :name="subitem.icon" class="h-5 w-5 text-p-900" />
                  <span class="text-white">
                    {{ subitem.name }}
                  </span>
                </div>
              </a>
              <NuxtLink
                v-else
                no-prefetch
                :to="subitem.to"
                class="flex hover:bg-grey-100"
                :class="{
                  'bg-grey-400 lg:bg-grey-100':
                    isSubMenuActive(subitem.page) && isMenuActive(menu.name),
                }"
              >
                <div
                  class="w-1 bg-p-500"
                  :class="{
                    'opacity-0':
                      !isSubMenuActive(subitem.page) ||
                      !isMenuActive(menu.name),
                  }"
                />
                <span class="py-3 pl-11 capitalize">
                  {{ subitem.name }}
                </span>
              </NuxtLink>
            </div>
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
import Icon from '~/components/common/Icon.vue'
import IconChevronDown from '~/assets/icons/ChevronDown.svg'
import ScreenSizeMixin from '~/mixins/ScreenSizeMixin'
import { mapStores } from 'pinia'
import { useMenuStore } from '~/stores/menu'
import { useSidebarStore } from '~/stores/sidebar'
import { useMembershipsStore } from '~/stores/memberships'
import useAuthStore from '~/stores/auth'

export default {
  components: { Icon, IconChevronDown },

  mixins: [ScreenSizeMixin],

  props: {
    menus: {
      type: Array,
      required: true,
    },
    activeMenu: {
      type: String,
      default: 'home',
    },
    activeSubMenu: {
      type: String,
      default: '',
    },
    defaultMainMenuName: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    openMenus: [],
    openMobileMenu: false,
    sectionName: '',
    prevScrollPos: 0,
    mediaQuery: null,
  }),

  computed: {
    ...mapStores(
      useMenuStore,
      useSidebarStore,
      useAuthStore,
      useMembershipsStore,
    ),
  },

  mounted() {
    if (window === undefined) return

    this.mediaQuery = window.matchMedia('(min-width: 1024px)')
    document.body.style.overflow = 'auto'
    const activeMenu = this.menus.find((menu) => menu.name === this.activeMenu)
    this.sectionName = activeMenu?.section
    this.getIsMobile()

    this.mediaQuery?.addEventListener('change', this.getIsMobile)

    if (activeMenu) {
      this.sidebarStore.openMenu(activeMenu.name)
      this.menuStore.setActiveMenu({
        activeMenu,
        activeSubMenu: this.activeSubMenu,
      })
    }
    if (this.isTablet() || this.isMobile()) {
      window.onscroll = () => {
        this.$nextTick(() => {
          this.scrollFunction()
        })
      }
    }
  },

  unmounted() {
    this.mediaQuery?.removeEventListener('change', this.getIsMobile)
    document.body.style.overflow = 'auto'
  },

  methods: {
    isOpenSubmenu(menu) {
      if (
        menu.name === 'price-dashboard' &&
        (!this.authStore.authenticated ||
          !this.membershipsStore.activePriceDashboardMembership)
      ) {
        return false
      }
      return (
        menu.children?.length > 0 &&
        menu.open &&
        (!menu.isPrivate || this.authStore.authenticated)
      )
    },
    getIsMobile() {
      this.openMobileMenu = this.mediaQuery.matches
    },

    toggleOpenMobile() {
      this.openMobileMenu = !this.openMobileMenu
      if (this.openMobileMenu) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },

    toggleMenu(menuName) {
      this.sidebarStore.toggleMenu(menuName)
    },

    isMenuActive(menuName) {
      return this.activeMenu === menuName.toLowerCase()
    },

    hasChildrenByAccess(menu) {
      if (menu.name === 'price-dashboard') {
        return this.membershipsStore.activePriceDashboardMembership
      }
      return (
        menu.children?.length > 0 &&
        (!menu.isPrivate || this.authStore.authenticated)
      )
    },

    isSubMenuActive(subMenuName) {
      if (this.activeSubMenu?.toLowerCase() === subMenuName?.toLowerCase()) {
        return true
      }
      const activeSubMenu = this.activeSubMenu.replaceAll('-', ' ')
      return activeSubMenu === subMenuName?.toLowerCase()
    },
    scrollFunction() {
      const currentScrollPos = window.scrollY
      const navbar = document.getElementById('navbar')
      const navbarHeight = navbar.offsetHeight
      const sidebar = document.getElementById('sidebar')
      const sidebarHeight = sidebar.offsetHeight
      const totalHeight = navbarHeight + sidebarHeight

      if (
        this.prevScrollPos < currentScrollPos &&
        currentScrollPos > totalHeight
      ) {
        navbar.style.top = `-${navbarHeight}px`
        sidebar.style.top = `-${totalHeight}px`
      } else if (this.prevScrollPos > currentScrollPos) {
        navbar.style.top = '0'
        sidebar.style.top = `${navbarHeight}px`
      }

      this.prevScrollPos = currentScrollPos
    },
  },
}
</script>

<style scoped>
.submenu {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.fold-animation {
  max-height: 0;
}

.unfold-animation {
  max-height: 300px;
}

#sidebar {
  transition: top 0.3s ease;
}
</style>

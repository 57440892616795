<template>
  <div
    class="relative mx-auto flex h-full min-h-[clamp(479px,479px,550px)] lg:min-h-[641px] overflow-hidden transition-all duration-700 lg:max-h-[clamp(420px,641px,641px)]"
  >
    <div id="bgImage" class="relative grow h-full min-h-[479px] lg:h-[641px]">
      <ImageFeatured
        fetch-priority="high"
        alt="Battery Home Hero"
        :images="HOME_HERO_IMAGES"
        classes="h-full w-full min-h-[479px] lg:min-h-[clamp(420px,641px,641px)] object-cover lg:opacity-100"
      />

      <!-- Overlay -->
      <div
        class="absolute left-0 top-0 h-full w-full bg-[linear-gradient(to,top,rgba(16,64,122,0) 97%, #001a39 69%] lg:bg-[linear-gradient(to,top,rgba(16,64,122,0) 167%, #001a39 119%] opacity-45 bg-blend-soft-light md:block"
      />

      <div
        class="max-w-content-container h-full absolute bottom-0 left-0 right-0 top-0 lg:top-2 xl:top-0 mx-auto px-4 py-6 lg:py-0 lg:px-10 2xl:px-0"
      >
        <KingsAwardEmblem
          class="absolute z-10 lg:top-6 lg:right-6 left-4 lg:left-auto top-3"
        />
        <div class="flex flex-col-reverse lg:block relative h-full w-full">
          <div
            class="flex h-full w-full mt-24 lg:mt-4 gap-3 lg:gap-6 xl:mt-0 flex-col xl:justify-end text-white"
          >
            <h2
              class="tracking-normal font-stretch-normal not-italic flex lg:mt-24 flex-col text-4xl !leading-[normal] lg:!leading-tight xl:!leading-[97.5px] font-bold lg:text-7xl xl:text-[5rem] lg:max-w-[820px] w-4/5 md:w-3/5 md:text-balance"
            >
              Enabling the Energy Transition
            </h2>
            <div
              class="flex lg:max-w-[820px] flex-wrap text-balance lg:flex-col text-base lg:text-lg font-semibold pb-12 md:pb-0"
            >
              <span>
                Benchmark’s prices and data empowers clients: from critical
                mineral
              </span>
              <span>
                contracts to EV battery supply chain investments and government
                policy
              </span>
            </div>

            <div class="flex lg:mb-52 2xl:mb-56">
              <CommonButton
                variant="primary"
                extra-classes="!w-full sm:!w-auto"
                @click="$modal.open('contact')"
              >
                Contact Us
              </CommonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/** nuxt */
import { useNuxtApp } from 'nuxt/app'

/** components */
import CommonButton from '~/components/common/Button.vue'
import ImageFeatured from '~/src/components/common/images/ImageFeatured.vue'
import KingsAwardEmblem from '~/src/components/common/logos/KingsAwardEmblem.vue'

/** constants */
import { HOME_HERO_IMAGES } from '~/src/constants/home'

/** app */
const { $modal } = useNuxtApp()

onMounted(() => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
</script>

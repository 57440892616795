import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFormsStore = defineStore('forms', () => {
  const forms = ref<Record<string, any>>({})

  const save = ({
    formId,
    configuration,
  }: {
    formId: string
    configuration: any
  }) => {
    forms.value[formId] = configuration
  }

  const retrieve = (formId: string) => forms.value[formId]

  return {
    forms,
    save,
    retrieve,
  }
})

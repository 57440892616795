export const WORDPRESS_POST_STATES = {
  PUBLISH: 'PUBLISH',
  DRAFT: 'DRAFT',
  FUTURE: 'FUTURE',
}

export const NON_PUBLISHED_POST_STATES = [
  WORDPRESS_POST_STATES.DRAFT,
  WORDPRESS_POST_STATES.FUTURE,
]
export const PUBLISHED_POST_STATES = [WORDPRESS_POST_STATES.PUBLISH]

export const MEMBERSHIPS = 'memberships'

<template>
  <div
    :class="[$attrs.class, { 'flex-col md:flex-row': isSimple }]"
    class="mx-auto flex w-full flex-row overflow-hidden rounded-xl bg-white p-4 text-sm shadow-pm"
  >
    <Icon
      :name="service.icon"
      class="h-12 w-12 min-w-[48px]"
      :class="imgSimpleClasses"
    />
    <div
      class="ml-4 mr-3 h-full w-full min-w-[3px] max-w-[3px] bg-p-500"
      :class="lineSimpleClasses"
    />
    <span :class="[contentClasses, 'font-semibold']">
      <slot :service="service">{{ service.description }}</slot>
    </span>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/common/Icon.vue'

const props = withDefaults(
  defineProps<{
    contentClasses?: string
    service: {
      icon: string
      title?: string
      description: string
    }
    variant?: 'default' | 'simple'
  }>(),
  {
    variant: 'default',
  },
)

const isSimple = computed(() => props.variant === 'simple')

const imgSimpleClasses = computed(() =>
  isSimple.value ? 'mx-auto md:mx-0 h-[58px] w-[58px] md:h-12 md:w-12' : '',
)

const lineSimpleClasses = computed(() =>
  isSimple.value
    ? 'mt-5 mb-3 !h-[3px] !max-w-full md:mt-0 md:mb-0 md:!h-full md:!max-w-[3px]'
    : '',
)
</script>

import { defineNuxtPlugin } from 'nuxt/app'
import { FormidableForm } from '@/utils/formidable-form'
import { useFormsStore } from '~/stores/forms'

export default defineNuxtPlugin((nuxtApp) => {
  const { $config, $httpClient }: any = nuxtApp
  const formsStore = useFormsStore()

  const create = (formId, defaultValues = {}, componentConfig = {}) => {
    return FormidableForm.create(
      formId,
      defaultValues,
      componentConfig,
      $config,
      $httpClient,
      formsStore,
    )
  }

  return {
    provide: {
      formidableFormBuilder: {
        create,
      },
    },
  }
})

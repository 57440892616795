export const CLOUDINARY_ROUTE_START = 'res.cloudinary.com'
export const CLOUDINARY_BASE_PATH = `https://${CLOUDINARY_ROUTE_START}/benchmarkminerals`
export const CLOUDINARY_PATH = `${CLOUDINARY_BASE_PATH}/image/upload`

export const CLOUDINARY_MODIFIERS_BY_DEFAULT = [
  'f_avif',
  'q_auto',
  'g_auto',
  'dpr_auto',
]

export const CLOUDINARY_QUERY_STRINGS = '?_i=AA'

export const CLOUDINARY_IMAGE_SIZES = {
  'hero-image': ['h_300', 'w_600', 'c_fill', 'g_auto'],
  magazine: ['w_200', 'h_270', 'c_scale'],
  presentation: ['w_370', 'c_scale'],
  'video-thumbnail': ['w_500', 'c_scale'],
  'membership-thumbnail': ['w_700', 'c_scale'],
  'membership-hero': ['w_1300', 'c_scale'],
  'event-card': ['w_500', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
  'event-card-mobile': ['w_350', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
  'event-detail': ['w_950', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
  'event-detail-mobile': ['w_420', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
}

export const CLOUDINARY_IMAGE_SIZE = {
  HERO_IMAGE: ['h_300', 'w_600', 'c_fill', 'g_auto'],
  MAGAZINE: ['w_200', 'h_270', 'c_scale'],
  PRESENTATION: ['w_370', 'c_scale'],
  VIDEO_THUMBNAIL: ['w_500', 'c_scale'],
  ARTICLE_THUMBNAIL: ['w_700', 'c_scale'],
  ARTICLE_HERO: ['w_1300', 'c_scale'],
  EVENT_CARD: ['w_500', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
  EVENT_CARD_MOBILE: ['w_350', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
  EVENT_DETAIL: ['w_950', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
  EVENT_DETAIL_MOBILE: ['w_420', 'c_fill', 'g_auto', 'f_auto', 'q_auto'],
} as const

<template>
  <component
    :is="typeComponent"
    v-bind="getProps"
    data-testid="link-with-animation"
    :class="[$attrs.class]"
    class="button button-arrow flex items-center text-s-900 hover:text-s-700 hover:underline"
    no-prefetch
    @click="handleClick"
  >
    {{ text }}
    <svg
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="arrow-icon"
      :class="textColor"
    >
      <g class="arrow-head">
        <path
          d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8"
          stroke="currentColor"
          stroke-width="2"
        />
      </g>
    </svg>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    text: string
    url?: string
    target?: string
    colorIcon?: string
  }>(),
  {
    url: '',
    target: '_parent',
    colorIcon: 'p-500',
  },
)

const emit = defineEmits(['click'])

const typeComponent = computed(() => {
  if (props.url === '') return 'button'
  return props.target === '_blank' ? 'a' : resolveComponent('NuxtLink')
})

const getProps = computed(() => {
  return props.target === '_blank'
    ? { href: props.url, target: props.target }
    : { to: props.url }
})

const textColor = computed(() => {
  return `text-${props.colorIcon} hover:text-${props.colorIcon}`
})

const handleClick = (event: Event) => {
  event.stopImmediatePropagation()
  emit('click')
}
</script>

<style lang="scss" scoped>
$time-duration: 100ms;
.button {
  transition: all $time-duration ease-in-out;
}
.button-arrow {
  .arrow-icon {
    overflow: visible;
    margin-left: 8px;
    width: 8px;
  }
  .arrow-head {
    transform: translateX(0);
    transition: transform $time-duration ease-in-out;
  }

  &:hover {
    .arrow-head {
      transform: translateX(4px);
    }
  }
}
</style>

import { ref } from 'vue'

const eventBus = ref(new Map())

export function useEventBus() {
  const on = (eventName: any, listener: any) => {
    if (!eventBus.value.has(eventName)) {
      eventBus.value.set(eventName, [])
    }
    eventBus.value.get(eventName).push(listener)
  }

  const off = (eventName: any, listener: any) => {
    const listeners = eventBus.value.get(eventName)
    if (listeners) {
      eventBus.value.set(
        eventName,
        listeners.filter((_listener: any) => _listener !== listener),
      )
    }
  }

  const emit = (eventName: any, payload: any) => {
    const listeners = eventBus.value.get(eventName)
    if (listeners) {
      listeners.forEach((listener: any) => listener(payload))
    }
  }

  return {
    on,
    off,
    emit,
  }
}

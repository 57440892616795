/** packages */
import isObject from 'lodash/isObject'
import uniqBy from 'lodash/uniqBy'

/** constants */
import { META_DESCRIPTION_WEBSITE, TITLE_WEBSITE } from '~/src/constants/seo'

/** types */
import type {
  TLink,
  TMetadata,
  TSeoProperties,
  TSeoProps,
  TBuildMeta,
} from '~/src/types/seo'
import type { TCommon } from '~/src/types/common'

export const formatTitle = (title: string) => `${title} | ${TITLE_WEBSITE}`

export const addAdditionalSeoProperties = (
  seoProperties: TSeoProperties,
  url: string,
) => {
  if (seoProperties?.opengraphImage) {
    return {
      ...seoProperties,
      'opengraphImage:secure_url': seoProperties.opengraphImage,
    }
  }

  return { ...seoProperties, opengraphUrl: url }
}

export const getSeoProperties = (seoProperties: TSeoProperties) => {
  return Object.keys(seoProperties).filter((key) => !!seoProperties[key])
}

export const getSeoValue = (seoProperties: TSeoProperties, key: string) => {
  if (isObject(seoProperties[key])) {
    const properties: TSeoProperties | TCommon = seoProperties[key]
    return properties.mediaItemUrl
  }
  return seoProperties[key]
}

export const isOpenGraphProperty = (propertyName: string) =>
  propertyName.startsWith('opengraph')

export const isTwitterProperty = (propertyName: string) =>
  propertyName.startsWith('twitter')

export const formatSeoPropertyName = (propertyName: string) => {
  const firstUpperLetterIndex = propertyName
    .split('')
    .findIndex((letter) => /[A-Z]/.test(letter))
  const name = propertyName.substring(firstUpperLetterIndex).toLowerCase()

  if (isOpenGraphProperty(propertyName)) return `og:${name}`

  return isTwitterProperty(propertyName) ? `twitter:${name}` : propertyName
}

export const buildMetas = ({ seoProperties, url }: TBuildMeta) => {
  const allSeoProperties = addAdditionalSeoProperties(seoProperties, url)

  return getSeoProperties(allSeoProperties).map((key) => {
    return {
      hid: formatSeoPropertyName(key),
      [isOpenGraphProperty(key) ? 'property' : 'name']:
        formatSeoPropertyName(key),
      content: getSeoValue(seoProperties, key),
    }
  })
}

export const generateCanonicalLink = (url: string): TLink[] => [
  { hid: 'canonical', rel: 'canonical', href: url },
]

export const generateSeoInfo = ({
  metaProps,
  url,
  seoProperties = {},
}: TSeoProps): TMetadata => {
  const {
    title = seoProperties.opengraphTitle || TITLE_WEBSITE,
    description = seoProperties.description ||
      seoProperties.opengraphDescription ||
      META_DESCRIPTION_WEBSITE,
    ...remaining
  } = metaProps

  const meta: TBuildMeta = {
    seoProperties: {
      description,
      twitterDescription: description,
      opengraphDescription: description,
      opengraphTitle: formatTitle(title),
      ...seoProperties,
    },
    url,
  }

  return {
    title: formatTitle(title),
    meta: uniqBy(buildMetas(meta), 'hid'),
    link: generateCanonicalLink(url),
    ...remaining,
  }
}

<template>
  <Layout>
    <LayoutPreviewSite :client-only="isPreviewSite">
      <div>
        <slot />
      </div>
    </LayoutPreviewSite>
  </Layout>
</template>

<script setup lang="ts">
/** components */
import Layout from '~/components/TheLayout.vue'
import LayoutPreviewSite from '~/components/PreviewSiteLayout.vue'

/** stores */
import { useMenuStore } from '~/stores/menu'

/** app */
const { $config } = useNuxtApp()
const { setActiveMenu } = useMenuStore()

/** computed */
const isPreviewSite = computed(() => $config.public.isPreviewSite)

/** lifecycle */
onUnmounted(() => {
  setActiveMenu({
    activeMenu: null,
    activeSubMenu: null,
  })
})
</script>

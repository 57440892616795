/** utils */
import { getNewToken, getToken } from '~/utils/auth'

/** constants */
import { REFRESH_TOKEN } from '~/src/constants/common'
import { HTTP_STATUS_TEXT } from '~/src/constants/http'

/** types */
import type { TCommon, TGeneral } from '~/src/types/common'
import type { TGraphQlProxy, TGraphQlResponse } from '~/src/types/graphql'

export class GraphQlProxy {
  protected graphQLClient: TCommon
  protected cookies: TGeneral
  protected config: TGeneral

  protected currentRetries = 0
  protected maxNumberOfRetries = 0

  constructor({
    graphQLClient,
    $cookies = {},
    $config = {},
    options = {},
  }: TGraphQlProxy) {
    this.graphQLClient = graphQLClient
    this.maxNumberOfRetries = options.maxNumberOfRetries || 0
    this.cookies = $cookies
    this.config = $config
  }

  addAuthorizationHeader = async ({
    $cookies = {},
    $config = {},
  }: Partial<TGraphQlProxy>) => {
    this.graphQLClient.setHeaders({}) // Reset authorization header

    const jwtToken: string = await getToken({ $cookies, $config })

    if (!jwtToken) return

    this.graphQLClient.setHeaders({ Authorization: `Bearer ${jwtToken}` })
  }

  async query({ query, variables }): Promise<TGraphQlResponse> {
    const refreshToken: string = this.cookies.get(REFRESH_TOKEN)

    if (this.graphQLClient.requestConfig.requireCredentials) {
      await this.addAuthorizationHeader({
        $cookies: this.cookies,
        $config: this.config,
      })
    }

    try {
      const data = await this.graphQLClient.request(query, variables)
      return { data }
    } catch (e) {
      if (
        e?.message === HTTP_STATUS_TEXT.INTERNAL_SERVER_ERROR &&
        refreshToken &&
        this.currentRetries < this.maxNumberOfRetries
      ) {
        this.currentRetries++
        await getNewToken({ $cookies: this.cookies, $config: this.config })
        return this.query({ query, variables })
      }
    }
  }

  async mutate({ mutation, variables }): Promise<TGraphQlResponse> {
    this.graphQLClient.setHeaders({}) // Reset authorization header
    const data = await this.graphQLClient.request(mutation, variables)
    return { data }
  }
}

/** packages */
import { defineNuxtPlugin } from 'nuxt/app'

/** constants */
import {
  CLOUDINARY_BASE_PATH,
  CLOUDINARY_IMAGE_SIZES,
  CLOUDINARY_ROUTE_START,
} from '~/src/constants/cloudinary'

/** types */
import type { TCloudinaryImageSizes } from '~/src/types/cloudinary'

export default defineNuxtPlugin(() => {
  /**
   * Check if an url is a cloudinary url
   */
  const isCloudinaryUrl = (imageUrl?: string) =>
    imageUrl?.includes(CLOUDINARY_ROUTE_START)

  /**
   * Some images come with transformation params, to make a successful resize
   * we need to remove those.
   * E.g.
   * https://res.cloudinary.com/benchmarkminerals/images/w_2560,h_1362/f_auto,q_auto/v1654631307/kualo_qa_media_library/Shanghai-lockdown/Shanghai-lockdown.jpg?_i=AA
   *
   * That url will become:
   * https://res.cloudinary.com/benchmarkminerals/images/f_auto,q_auto/v1654631307/kualo_qa_media_library/Shanghai-lockdown/Shanghai-lockdown.jpg?_i=AA
   */
  const removeExistingTransformationParams = (imageUrl: string) => {
    return imageUrl
      .split('/')
      ?.filter(
        (segment: string) =>
          !['w_', 'h_', 'q_', 'f_'].some((param) => segment.startsWith(param)),
      )
      .join('/')
  }

  /**
   * Get the params to append to the cloudinary url with some default params
   */
  const getSizeParams = (size: TCloudinaryImageSizes) => {
    const defaultParams = ['f_auto', 'q_auto']

    return [...defaultParams, ...CLOUDINARY_IMAGE_SIZES[size]]
  }

  /**
   * Add parameters to resize the image
   */
  const resize = (
    imageUrl: string | undefined,
    size: TCloudinaryImageSizes,
  ) => {
    if (!isCloudinaryUrl(imageUrl) || !CLOUDINARY_IMAGE_SIZES[size]) {
      return imageUrl
    }

    const sizeParams = getSizeParams(size)
    imageUrl = removeExistingTransformationParams(imageUrl)

    return imageUrl.replace('/images', `/images/${sizeParams.join(',')}`)
  }

  const optimize = (
    imageUrl: string | undefined,
    size: TCloudinaryImageSizes,
  ) => {
    if (!isCloudinaryUrl(imageUrl)) {
      return imageUrl
    }

    const folder = imageUrl?.includes('upload') ? 'image/upload' : 'images'
    const image = imageUrl?.split('/v')[1]
    const query = CLOUDINARY_IMAGE_SIZES[size].join(',')
    return `${CLOUDINARY_BASE_PATH}/${folder}/${query}/v${image}`
  }

  const cloudinary = { resize, optimize }

  return { provide: { cloudinary } }
})

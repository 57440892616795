import { FILTER_KEY_MAP, LABEL_MAP, LEVEL_KEY_MAP } from '~/constants/chart'

export const cartesian = (...a) =>
  a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())), [[]])

const getAllFiltersCombinationsServicePro = (selectedFilters) => {
  const filtersNameArray = getFiltersNameArrayServicePro(selectedFilters)
  return cartesian(...filtersNameArray)
}

const getFiltersNameArrayServicePro = (filterObject) => {
  const filtersNameArray = []
  Object.values(filterObject).forEach((value: any[]) => {
    if (value.length) {
      filtersNameArray.push(value.map((e) => e.data.name))
    } else {
      filtersNameArray.push([''])
    }
  })

  return filtersNameArray
}

const isProductAllowedServicePro = (seriesRow, filters) => {
  const productFeatures = [
    ...[seriesRow.category?.name ?? null],
    ...[seriesRow.grade?.name ?? null],
    ...[seriesRow.purity?.name ?? null],
    ...[seriesRow.shippingRoute?.name ?? null],
    ...[seriesRow.feedstock?.name ?? null],
    ...[seriesRow.product?.name ?? null],
    ...[seriesRow.priceType?.name ?? null],
  ]
  const myFilterArray = filters.filter(Boolean)
  return myFilterArray.every((e) => {
    return productFeatures.includes(e)
  })
}

export const getAllowedProductsServicePro = (
  availableProducts,
  selectedFilters,
) => {
  const possibleFilterCombinations =
    getAllFiltersCombinationsServicePro(selectedFilters)
  return availableProducts.filter((product) => {
    let allow = false
    let i = 0

    while (i < possibleFilterCombinations.length) {
      const filters = possibleFilterCombinations[i]
      const isAllowed = isProductAllowedServicePro(product, filters)
      if (isAllowed) {
        allow = true
        break
      }
      i += 1
    }

    return allow
  })
}

export const getLevelOptions = (options, level) => {
  const selected = {}
  let currentLvl = 1
  Object.entries(options).forEach(([key, value]) => {
    selected[key] = value
    if (currentLvl >= level) selected[key] = []
    currentLvl += 1
  })
  return selected
}

export const getAllowedOptionsServicePro = (allowedProducts) => {
  const productEnabledOptions = allowedProducts.flatMap((_item) => [
    _item.category?.name,
    _item.grade?.name,
    _item.purity?.name,
    _item.shippingRoute?.name,
    _item.feedstock?.name,
    _item.product?.name,
    _item.priceType?.name,
  ])
  return [...new Set(productEnabledOptions)].filter(Boolean)
}

export const getBrotherOptions = (options) => {
  const selected = []
  Object.entries(options).forEach(([_, value]: any) => {
    value.forEach((option) => {
      if (option.brother_ids) {
        selected.push(...option.brother_ids)
      }
    })
  })
  return selected
}

export const createDynamicFilter = (
  object,
  level,
  parentLevel,
  parentID,
  filters = {},
) => {
  if (!filters[level]) {
    const key = FILTER_KEY_MAP[level]
    filters[level] = {
      name: level,
      label: key,
      level: parentLevel ? filters[parentLevel].level + 1 : 1,
      checked: false,
      options: [],
    }
  }

  if (object[level]) {
    object[level].forEach((subObject) => {
      let label = subObject.alias || subObject.label
      label =
        typeof LABEL_MAP[label] === 'function'
          ? LABEL_MAP[label](subObject)
          : LABEL_MAP[label] || label

      const optionExist = filters[level].options.find((item) => {
        return (
          item.data.name === subObject.label &&
          subObject?.isSustainable === item.data?.isSustainable
        )
      })
      if (optionExist && parentID) {
        optionExist.parent_ids.push(parentID)
      } else {
        const getParentIds = () => {
          return parentID ? [parentID] : null
        }

        filters[level].options.push({
          data: {
            name: subObject.label,
            label,
            isSustainable: subObject.isSustainable,
          },
          checked: false,
          disabled: false,
          parent: parentLevel,
          parent_ids: getParentIds(),
        })
      }

      const nextLevel = subObject.nextLevel
      if (nextLevel) {
        createDynamicFilter(subObject, nextLevel, level, subObject.id, filters)
      }
    })
  }

  return filters
}

export const generateCombinations = (node, path = {}, results = []) => {
  if (!node.nextLevel) {
    results.push({ ...path })
    return results
  }

  const nextLevelKey = node.nextLevel
  const levelKey = LEVEL_KEY_MAP[nextLevelKey]

  if (!levelKey) return results

  const nextLevelItems = node[nextLevelKey]

  for (const item of nextLevelItems) {
    const newPath = { ...path, [levelKey]: { name: item.label } }

    generateCombinations(item, newPath, results)
  }

  return results
}

export const checkAllPredefinedOptions = (obj) => {
  return Object.entries(obj).reduce((acc, [key, entry]: any) => {
    if (!entry.options) return { ...acc, [key]: entry }
    const options = entry.options.map((option) => ({
      ...option,
      checked: true,
    }))
    return { ...acc, [key]: { ...entry, options } }
  }, {})
}

export const sortOptionsAlphabetically = (obj: any) => {
  const sortByName = (a, b) => a.data.label.localeCompare(b.data.label)

  Object.values(obj).forEach((entry: any) => {
    if (entry.options) {
      entry.options.sort(sortByName)
    }
  })

  return obj
}

export default defineNuxtPlugin({
  name: 'polyfill',
  parallel: true,
  setup() {
    if (!Math.trunc) {
      Math.trunc = function (value) {
        return value < 0 ? Math.ceil(value) : Math.floor(value)
      }
    }
    if (!Array.prototype.at) {
      Array.prototype.at = function (index) {
        const length = this.length
        index = Math.trunc(index) || 0
        if (index < 0) index += length
        if (index < 0 || index >= length) return undefined
        return this[index]
      }
    }
    if (!String.prototype.at) {
      String.prototype.at = function (index) {
        const length = this.length
        index = Math.trunc(index) || 0
        if (index < 0) index += length
        if (index < 0 || index >= length) return undefined
        return this.charAt(index)
      }
    }
  },
})

export const scrollTo = (elementId: string): void => {
  const headerHeight = 150
  const isDesktop = window.screen.width >= 1024
  const el = document.getElementById(elementId)

  if (!el) return

  let top = el.getBoundingClientRect().top + window.scrollY

  if (isDesktop) top -= headerHeight

  window.scrollTo({ top, behavior: 'smooth' })
}

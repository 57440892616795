import { defineStore } from 'pinia'
import { ref } from 'vue'
import uniqBy from 'lodash/uniqBy'
import type { TBreadcrumbMenuItem } from '~/src/types/ui'

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const items = ref<TBreadcrumbMenuItem[]>([])

  const addItemBreadcrumb = (item: TBreadcrumbMenuItem) => {
    let itemObject = item
    if (typeof item === 'string') {
      itemObject = { title: item }
    }

    items.value = uniqBy([...items.value, itemObject], 'title')
  }

  const clearBreadcrumb = () => {
    items.value = []
  }

  return {
    items,
    addItemBreadcrumb,
    clearBreadcrumb,
  }
})

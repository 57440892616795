import { defineNuxtPlugin } from 'nuxt/app'
import FormData from 'form-data'
import {
  ERROR_REQUEST_CANCELED,
  HEADERS_PROPERTIES,
  HEADERS_VALUES,
  HTTP_METHODS_WITH_BODY,
  HTTP_STATUS,
} from '~/src/constants/http'
import type {
  THttpClientResponse,
  THttpData,
  THttpOptions,
} from '~/src/types/http'
import { HttpProxy } from '~/src/utils/http-proxy'
import type { AxiosInstance } from 'axios'
import type { TGeneral } from '~/src/types/common'

export default defineNuxtPlugin({
  name: 'httpClient',
  setup(nuxtApp) {
    const { $cookies, $axios, $config }: TGeneral = nuxtApp

    const get = (
      uri: string,
      data: THttpData = null,
      options: THttpOptions = {},
    ) => {
      return sendRequest(uri, 'get', data, options)
    }

    const remove = (
      uri: string,
      data: THttpData = null,
      options: THttpOptions = {},
    ) => {
      return sendRequest(uri, 'delete', data, options)
    }

    const post = (
      uri: string,
      data: THttpData = null,
      options: THttpOptions = {},
    ) => {
      return sendRequest(uri, 'post', data, options)
    }

    const put = (
      uri: string,
      data: THttpData = null,
      options: THttpOptions = {},
    ) => {
      return sendRequest(uri, 'put', data, options)
    }

    const patch = (
      uri: string,
      data: THttpData = null,
      options: THttpOptions = {},
    ) => {
      return sendRequest(uri, 'patch', data, options)
    }

    const sendRequest = async (
      uri: string,
      method: string,
      data: THttpData = null,
      customOptions: THttpOptions = {},
    ): Promise<THttpClientResponse> => {
      const options: THttpOptions = {
        method,
        ...customOptions,
      }

      if (HTTP_METHODS_WITH_BODY.includes(method)) {
        options.data = data
      }

      if (!(data instanceof FormData)) {
        options.headers = options.headers || {}
        options.headers[HEADERS_PROPERTIES.CONTENT_TYPE] =
          HEADERS_VALUES.APPLICATION_JSON
      }

      const httpProxy = new HttpProxy(
        $axios as AxiosInstance,
        $cookies,
        $config,
        {
          maxNumberOfRetries: 1,
        },
      )
      const { response, error } = await httpProxy.request(uri, options)

      if (
        error?.response?.status >= HTTP_STATUS.BAD_REQUEST ||
        error?.code === ERROR_REQUEST_CANCELED
      ) {
        throw error.response?.data || error
      }

      return response?.data
    }

    return {
      provide: {
        httpClient: {
          get,
          post,
          put,
          patch,
          delete: remove,
        },
      },
    }
  },
})

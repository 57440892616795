/** abstracts */
import { AbstractForm } from '~/src/abstracts/abstract-form'

/** types */
import type { TForm } from '~/src/types/form'
import type { TCommon } from '~/src/types/common'

export class Forms<T extends TForm<T>> extends AbstractForm<T> {
  busy = false
  successful = false

  constructor(formId: string | number | null, data: T) {
    super()
    this.formId = formId
    this.dataKeys = Object.keys(data) as Array<keyof T>
    this.data = data
    Object.assign(this, data)
  }

  public startProcessing() {
    this.busy = true
    this.successful = false
    this.resetErrors()
  }

  public finishProcessing() {
    this.busy = false
    this.successful = true

    setTimeout(() => {
      this.successful = false
    }, 3000)
  }

  public setErrors(errors: T | TCommon) {
    this.errors.set(errors)
    this.busy = false
    this.successful = false
  }

  public resetStatus() {
    this.busy = false
    this.resetErrors()
  }
}

<template>
  <nav class="relative z-40 flex items-center h-auto w-full">
    <!-- Desktop nav -->
    <DesktopNavigation class="hidden lg:grid" />
    <MobileNavigation class="flex lg:hidden h-11" />

    <ForgotPasswordModal v-if="getModal('forgotPassword')" />
    <SignInModal v-if="showSingInModal" :modal-open="showSingInModal" />
    <SubscribeModal v-if="getModal('subscribe')" />
    <ModalNewsletter v-if="getModal('newsletter')" />
    <CapexTrackerModal v-if="getModal('capexTracker')" />
    <CapexTrackerContactModal v-if="getModal('capexTrackerContact')" />
    <IndustryReportsModal v-if="getModal('industryReports')" />
    <RequestForProposalModal v-if="getModal('requestForProposal')" />
  </nav>
</template>

<script setup lang="ts">
import DesktopNavigation from '~/components/navigation/DesktopNavigation.vue'
import MobileNavigation from '~/components/navigation/MobileNavigation.vue'

import ForgotPasswordModal from '~/components/ForgotPasswordModal.vue'
import SignInModal from '~/components/SignInModal.vue'
import ModalNewsletter from '~/components/shared/common/ModalNewsletter.vue'
import CapexTrackerModal from '~/components/CapexTrackerModal.vue'
import SubscribeModal from '~/components/subscribe/Modal.vue'
import CapexTrackerContactModal from '~/components/CapexTrackerContactModal.vue'
import IndustryReportsModal from '~/components/IndustryReportsModal.vue'
import RequestForProposalModal from '~/components/RequestForProposalModal.vue'
import { useModalStore } from '~/stores/modal'

const { getModal } = useModalStore()

const showSingInModal = computed(() => getModal('signIn'))
</script>

import { getAdaptedRegions } from '~/utils/adapters'
import type { TCDCompany, TCDRegion } from '~/src/types/capacity-database'

export default defineNuxtPlugin((nuxtApp) => {
  const { $config, $httpClient }: any = nuxtApp

  const CAPACITY_DATABASE_API_PATH = `${$config.public.baseUrl}/wp-json/benchmark/v1/ma-data`
  const getRegionsByProduct = async (product: string): Promise<TCDRegion[]> => {
    return getAdaptedRegions(
      await $httpClient.post(
        `${CAPACITY_DATABASE_API_PATH}/v2/${product}/regions`,
      ),
    )
  }

  const getRecordsByProduct = (product: string): Promise<TCDCompany[]> => {
    return $httpClient.post(
      `${CAPACITY_DATABASE_API_PATH}/v2/${product}/report`,
    )
  }

  return {
    provide: {
      capacityDatabase: {
        getRecordsByProduct,
        getRegionsByProduct,
      },
    },
  }
})

import type { TCommon, TGeneral } from '~/src/types/common'

export default defineNuxtPlugin((nuxtApp) => {
  const { $config }: TGeneral = nuxtApp
  if ($config.public.catchErrorsAndExceptionsEnabled) {
    const logError = (
      messageType: string,
      details: unknown,
      event: PromiseRejectionEvent | ErrorEvent | TGeneral,
    ) => {
      if ($config.public.isProduction) {
        console.log(messageType)
      } else {
        console.error({ [messageType]: details, event })
      }
    }

    if (import.meta.client) {
      window.onerror = (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error,
      ) => {
        logError('[Error Client]', error.message, {
          source,
          lineno,
          colno,
          error,
          event,
        })
      }

      window.addEventListener(
        'unhandledrejection',
        (event: PromiseRejectionEvent) => {
          logError('[Unhandled Rejection]', event.reason, event)
        },
      )

      window.addEventListener('error', (event: ErrorEvent) => {
        logError('[Error]', event.error, event)
      })

      window.addEventListener('resourcetimingbufferfull', (event: TCommon) => {
        logError('[Resource Timing Buffer Full]', event.type, event)
      })
    }

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
      logError('[Vue Error Handler]', error, { instance, info })
    }
  }
})

<template>
  <div v-if="source" class="responsive-height-aspect-ratio-16-9">
    <div class="absolute h-full w-full">
      <div class="flex h-full w-full">
        <iframe
          ref="player"
          data-testid="vimeo-player"
          :src="urlVideo"
          allowfullscreen
          class="h-full w-full"
          allow="autoplay; fullscreen; picture-in-picture; encrypted-media; accelerometer; gyroscope"
          :title="title"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useNuxtApp } from '#app'

const defaultOptions = {
  loop: false,
  autoplay: true,
  muted: false,
  playsinline: true,
  byline: false,
  portrait: false,
  title: false,
  speed: true,
  transparent: false,
  captions: false,
  pip: false,
  color: 'FDBD00',
  endscreen: 'void',
  fields: 'play.progressive',
}

const props = withDefaults(
  defineProps<{
    source: string | number
    autoplay?: boolean
    title?: string
    ratio?: string
  }>(),
  {
    autoplay: true,
    title: '',
    ratio: '1920:1080',
  },
)

const player = ref<HTMLIFrameElement | null>(null)
const { $vimeo } = useNuxtApp()
const emit = defineEmits(['endedVideo', 'playVideo'])

const urlVideo = computed(() => {
  const url = new URL(props.source.toString())
  Object.entries(defaultOptions).forEach(([key, value]) => {
    url.searchParams.set(key, value.toString())
  })
  return url.toString()
})

onMounted(() => {
  if (!player.value) return

  const vimeoPlayer = $vimeo.init(player.value, {
    ratio: props.ratio,
  })

  vimeoPlayer.on('ended', () => emit('endedVideo'))
  vimeoPlayer.on('play', () => emit('playVideo'))
})
</script>

<style scoped lang="scss">
.responsive-height-aspect-ratio-16-9 {
  position: relative;
  margin: 0 auto;
  aspect-ratio: 16/9;
  max-width: 100vw;
}
</style>

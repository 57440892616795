<template>
  <Layout class="md:bg-s-50">
    <AccountHeader :key="fullPath" />

    <div class="max-w-contact-form-container mx-auto w-full p-4 pt-0">
      <div class="mb-20 flex-grow">
        <slot />
      </div>
    </div>
  </Layout>
</template>

<script setup lang="ts">
/** components */
import AccountHeader from '~/components/account/Header.vue'
import Layout from '~/components/TheLayout.vue'

/** app */
const $route = useRoute()

/** computed */
const fullPath = computed(() => $route.fullPath)
</script>

import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useChartBuilderCatalogStore } from '@/stores/chartBuilder/catalog'
import { useChartBuilderConfigStore } from '@/stores/chartBuilder/config'
import { useChartBuilderStepsStore } from '@/stores/chartBuilder/steps'
import { useChartBuilderUnitsMeasurementStore } from '@/stores/chartBuilder/unitsMeasurement'

export const useChartBuilderStore = defineStore('chartBuilder', () => {
  const isOpen = ref(false)
  const catalogStore = useChartBuilderCatalogStore()
  const configStore = useChartBuilderConfigStore()
  const stepsStore = useChartBuilderStepsStore()
  const unitsMeasurementStore = useChartBuilderUnitsMeasurementStore()

  const setIsOpen = (open: boolean) => {
    isOpen.value = open
  }

  const autoPopulate = () => {
    catalogStore.autoPopulateCurrency()
    catalogStore.autoPopulateUnit()
  }

  const reset = () => {
    catalogStore.reset()
    configStore.reset()
    stepsStore.reset()
    unitsMeasurementStore.reset()
    autoPopulate()
  }

  return {
    isOpen,
    setIsOpen,
    autoPopulate,
    reset,
  }
})

<template>
  <div class="hidden items-center lg:flex h-6" data-testid="breadcrumb">
    <component
      :is="getComponentType(item)"
      v-for="(item, index) in menu"
      :key="index"
      :to="getItemUrl(item)"
      class="text-xs leading-6 after:relative after:px-1 after:content-['>'] last:font-bold last:underline last:after:hidden"
      data-testid="breadcrumb-item"
    >
      {{ getItemTitle(item) }}
    </component>
  </div>
</template>

<script setup lang="ts">
import type {
  TBreadcrumbMenu as TMenu,
  TBreadcrumbMenuItem as TMenuItem,
} from '~/src/types/ui'

/*-------------------------------------
  Props & Refs
-------------------------------------*/
defineProps<{
  menu: TMenu
}>()

/*-------------------------------------
  Methods
-------------------------------------*/
const formatName = (name: string) => {
  return name?.replaceAll('-', ' ')
}

const isLinkedItem = (item: TMenuItem) => {
  if (typeof item === 'object') {
    return !!item.url
  }
}

const getComponentType = (item: TMenuItem) => {
  return isLinkedItem(item) ? resolveComponent('NuxtLink') : 'div'
}

const getItemUrl = (item: TMenuItem) => {
  if (typeof item === 'object') {
    return item.url
  }
}

const getItemTitle = (item: TMenuItem) => {
  const title = typeof item === 'object' ? item.title : item
  return formatName(title)
}
</script>

<script setup lang="ts">
import { useField, ErrorMessage, useFieldError } from 'vee-validate'
import { VueTelInput } from 'vue-tel-input'
import type { TPhoneObject } from '~/src/types/form'

import 'vue-tel-input/vue-tel-input.css'

type TProps = {
  label: string
  type?: string
  placeholder?: string
  modelValue?: string | number
  name: string
  rules?: string
  errorMessages?: Record<string, string>
  required: boolean
}

const props = withDefaults(defineProps<TProps>(), {
  type: 'text',
  label: 'Phone number',
  placeholder: 'Enter phone number',
  rules: '',
  modelValue: '',
  errorMessages: () => ({
    invalid: 'Phone number must be valid',
  }),
})

const emit = defineEmits(['update:modelValue'])

const error = useFieldError(props.name)
const hasError = computed(() => error.value)

const { setErrors, validate, value } = useField(props.name, props.rules, {
  label: props.label,
  validateOnValueUpdate: false,
})

const currentPhoneObject = ref<TPhoneObject>(null)

const validateInput = () => {
  if (!currentPhoneObject.value) return validate()

  const { formatted, valid } = currentPhoneObject.value

  if (!valid) {
    if (!formatted) return validate()
    return setErrors(props.errorMessages.invalid)
  }

  emit('update:modelValue', formatted)
  validate()
}

const handleInput = (_: string, phoneObject: TPhoneObject) => {
  const { formatted, valid } = phoneObject
  currentPhoneObject.value = phoneObject

  emit('update:modelValue', formatted)

  if (valid) validate()
}

const beforeinput = (e: InputEvent) => {
  if (!e.data) return
  const newValue = value.value + e.data
  if (newValue === '00') {
    e.preventDefault()
    emit('update:modelValue', '+')
  }
}

const preferredCountries = ['gb', 'us', 'ca', 'cn', 'au']
</script>

<template>
  <div>
    <span class="mb-1 block text-xs font-semibold">
      {{ label }} <span v-if="required" class="text-error">*</span>
    </span>
    <VueTelInput
      v-model="value"
      :preferred-countries="preferredCountries"
      :input-options="{ placeholder, showDialCode: true }"
      valid-characters-only
      mode="international"
      data-testid="phone-number-input"
      :class="{ error: hasError }"
      @beforeinput="beforeinput"
      @on-input="handleInput"
      @blur="validateInput"
    />

    <div class="h-4">
      <ErrorMessage class="block text-xxs text-error" :name="name" />
    </div>
  </div>
</template>

<style lang="scss">
.vue-tel-input {
  @apply rounded border-s-600 #{!important};

  &:focus-within {
    @apply border-p-500 ring-1 ring-p-500 ring-offset-0 #{!important};
  }

  input {
    @apply px-3 py-1 text-grey-900 shadow-none ring-0 #{!important};
  }

  .vti__dropdown:focus {
    @apply rounded outline-2 outline-offset-0 outline-p-500;
  }

  .vti__dropdown-item strong {
    @apply font-normal #{!important};
  }

  &.error {
    &:focus-within {
      @apply border-error #{!important};
    }

    .vti__dropdown:focus {
      @apply outline-error;
    }
  }
}
</style>

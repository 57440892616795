<template>
  <div
    :data-testid="formInputTestId"
    :class="[typeRef !== 'hidden' ? '' : 'hidden']"
  >
    <div class="mb-1">
      <label
        :for="id"
        class="mb-1 block text-xs font-semibold"
        :class="labelClasses"
      >
        {{ label }} <span v-if="required" class="text-error">*</span>
      </label>

      <div
        class="w-full border focus-within:border-transparent rounded focus-within:ring-1 flex items-center gap-x-1"
        :class="borderClasses"
      >
        <Field
          :id="id"
          ref="field"
          data-testid="field"
          :name="name"
          :rules="rules"
          :class="classesFiled"
          :type="typeRef"
          :placeholder="placeholder"
          :validate-on-blur="validateOnBlur"
          :validate-on-input="!isEmail"
          :label="label"
          :value="modelValue"
          :autocomplete="autocomplete"
          @update:model-value="handleUpdateValue"
        />

        <button
          v-if="enableCopyToClipboard"
          data-testid="copy-to-clipboard-button"
          class="w-7 h-7 flex items-center justify-center rounded"
          :class="{
            'bg-p-50 border-[1.5px] border-p-700': copied,
          }"
          @click="copy(modelValue)"
        >
          <Icon class="text-s-900" name="icon-copy-alt" />
        </button>

        <div v-if="type === 'password'" class="text-s-900">
          <div
            class="flex h-7 w-7 cursor-pointer items-center"
            @click="toggleType"
          >
            <Icon v-if="visibleType" name="icon-eye-off" />
            <Icon v-else name="icon-eye-on" />
          </div>
        </div>
      </div>
    </div>

    <div class="h-4">
      <ErrorMessage class="text-xxs text-error block" :name="name" />
    </div>
  </div>
</template>
<script setup lang="ts">
/** packages */
import { useClipboard } from '@vueuse/core'
import type { VueInstance } from '@vueuse/core'
import kebabCase from 'lodash/kebabCase'
import { Field, ErrorMessage, useFieldError, validate } from 'vee-validate'
import { twMerge } from 'tailwind-merge'

/** components */
import Icon from '~/components/common/Icon.vue'
import { useEventBus } from '~/composables/useEventBus'

/** components */

const props = withDefaults(
  defineProps<{
    label: string
    type?: string
    placeholder?: string
    isFocused?: boolean
    name: string
    modelValue?: string
    rules?: string
    required?: boolean
    validateOnBlur?: boolean
    defaultValue?: string
    enableCopyToClipboard?: boolean
    labelClasses?: string
    useFieldValidationColor?: boolean
    passwordsDoNotMatch?: boolean
    autocomplete?: string
  }>(),
  {
    type: 'text',
    placeholder: '',
    isFocused: false,
    rules: '',
    required: false,
    validateOnBlur: true,
    defaultValue: '',
    enableCopyToClipboard: false,
    labelClasses: '',
    useFieldValidationColor: false,
    passwordsDoNotMatch: null,
    autocomplete: 'off',
  },
)

const error = useFieldError(props.name)

const id = useId()
const typeRef = ref(props.type)
const field = ref<VueInstance>()
const hasValidationColor = ref<boolean>(false)

const hasError = computed(() => error.value)
const formInputTestId = computed(() => kebabCase(`form-input-${props.label}`))

const { copy, copied } = useClipboard()

const { emit: emitCustom } = useEventBus()

const visibleType = computed(() => typeRef.value === 'text')
const isEmail = computed(
  () => props.type === 'email' || props.rules.match(/email/),
)

const hasClassesError = computed(() => {
  if (props.passwordsDoNotMatch) {
    return true
  }
  return !!hasError.value
})

const classesFiled = computed(() => {
  const classBorder = hasClassesError.value ? 'border-error' : 'border-good'
  return twMerge(
    'w-full border-none !outline-0 focus:ring-0 rounded !shadow-none text-grey-900 border-transparent py-1',
    classBorder,
  )
})

const toggleType = () => {
  typeRef.value = typeRef.value === 'password' ? 'text' : 'password'
}

const emit = defineEmits(['update:modelValue'])

const handleUpdateValue = (value: string) => {
  emitCustom('fieldNameActive', props.name)
  emit('update:modelValue', value)
}

const borderClasses = computed(() => {
  if (!props.useFieldValidationColor || !props.modelValue) {
    return 'focus-within:ring-p-500 border-s-500'
  }

  if (hasValidationColor.value && !hasClassesError.value) {
    return 'focus-within:ring-good valid:border-good border-good'
  }

  return 'focus-within:ring-error valid:border-error border-error'
})

watch(
  () => props.modelValue,
  async (value) => {
    if (props.useFieldValidationColor) {
      const { valid } = await validate(value, props.rules)
      hasValidationColor.value = valid
    }

    if (!isEmail.value) return

    const { valid } = await validate(value, props.rules)

    if (valid) {
      field.value.$el.dispatchEvent(new Event('blur'))
    }
  },
)

onMounted(() => {
  props.isFocused && field.value.$el.focus()

  if (props.defaultValue) {
    handleUpdateValue(props.defaultValue)
  }
})
</script>

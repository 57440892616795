import {
  ANODE,
  CATHODE,
  COBALT,
  LITHIUM,
  RARE_EARTHS,
  LITHIUM_ION_BATTERIES,
  NATURAL_GRAPHITE,
  NICKEL,
  PRICE_DASHBOARD,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ANODE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_CATHODE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_COBALT,
  SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM,
  SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES,
  SUBSCRIPTION_PRICE_ASSESSMENTS_NATURAL_GRAPHITE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_NICKEL,
  SUBSCRIPTION_PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_RARE_EARTHS,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
  SUBSCRIPTION_PRICING_ALL_PRICES,
  SYNTHETIC_GRAPHITE,
  BLACK_MASS,
  SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS,
  RECYCLING,
  PRICING_ALL_PRICES,
} from '~/constants/services/constants'
import {
  getChildrenSidebar,
  getMenu,
  getRoute,
  getRoutes,
} from '~/constants/services/methods'

export const PRICE_ASSESSMENTS_PRICING_ALL_PRICES = {
  subscription: SUBSCRIPTION_PRICING_ALL_PRICES,
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(`price-assessments/${PRICING_ALL_PRICES.slug}`),
  ),
}

export const PRICE_ASSESSMENTS_PRICE_DASHBOARD = {
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
  menu: getMenu(
    PRICE_DASHBOARD.menu,
    PRICE_DASHBOARD.slug,
    '',
    true,
    'chart-builder',
  ),
  routes: getRoutes(PRICE_DASHBOARD.slug, 'chart-builder', false),
}

export const PRICE_ASSESSMENTS_LITHIUM = {
  checkbox: LITHIUM.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM,
  routes: getRoutes(LITHIUM.slug, '', false, `${LITHIUM.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(LITHIUM.slug),
  ),
}

export const PRICE_ASSESSMENTS_COBALT = {
  checkbox: COBALT.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_COBALT,
  routes: getRoutes(COBALT.slug, '', false, `${COBALT.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(COBALT.slug),
  ),
}

export const PRICE_ASSESSMENTS_NICKEL = {
  checkbox: NICKEL.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_NICKEL,
  routes: getRoutes(NICKEL.slug, '', false, `${NICKEL.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(NICKEL.slug),
  ),
}

export const PRICE_ASSESSMENTS_NATURAL_GRAPHITE = {
  checkbox: NATURAL_GRAPHITE.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_NATURAL_GRAPHITE,
  routes: getRoutes(
    NATURAL_GRAPHITE.slug,
    '',
    false,
    `${NATURAL_GRAPHITE.slug}-prices`,
  ),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(NATURAL_GRAPHITE.slug),
  ),
}

export const PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE = {
  checkbox: SYNTHETIC_GRAPHITE.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE,
  routes: getRoutes(
    SYNTHETIC_GRAPHITE.slug,
    '',
    false,
    `${SYNTHETIC_GRAPHITE.slug}-prices`,
  ),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(SYNTHETIC_GRAPHITE.slug),
  ),
}

export const PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES = {
  name: LITHIUM_ION_BATTERIES.name,
  slug: LITHIUM_ION_BATTERIES.slug,
  icon: LITHIUM_ION_BATTERIES.iconColor,
  checkbox: 'battery-cell',
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES,
  routes: getRoutes(
    LITHIUM_ION_BATTERIES.slug,
    '',
    false,
    `${LITHIUM_ION_BATTERIES.slug}-prices`,
  ),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(LITHIUM_ION_BATTERIES.slug),
  ),
}

export const PRICE_ASSESSMENTS_ANODE = {
  checkbox: ANODE.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_ANODE,
  routes: getRoutes(ANODE.slug, '', false, `${ANODE.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(ANODE.slug),
  ),
}

export const PRICE_ASSESSMENTS_CATHODE = {
  checkbox: CATHODE.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_CATHODE,
  routes: getRoutes(CATHODE.slug, '', false, `${CATHODE.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(CATHODE.slug),
  ),
}

export const PRICE_ASSESSMENTS_BLACK_MASS = {
  checkbox: BLACK_MASS.slug,
  name: BLACK_MASS.name,
  slug: BLACK_MASS.slug,
  icon: BLACK_MASS.iconColor,
  iconLine: BLACK_MASS.iconLine,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS,
  routes: getRoutes(RECYCLING.slug, '', false, `${RECYCLING.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(BLACK_MASS.reportSlug),
  ),
}

export const PRICE_ASSESSMENTS_RARE_EARTHS = {
  checkbox: RARE_EARTHS.slug,
  subscription: SUBSCRIPTION_PRICE_ASSESSMENTS_RARE_EARTHS,
  routes: getRoutes(RARE_EARTHS.slug, '', false, `${RARE_EARTHS.slug}-prices`),
  overview: getChildrenSidebar(
    [{ name: 'View Product', path: '' }],
    getRoute(RARE_EARTHS.slug),
  ),
}

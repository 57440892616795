<template>
  <div class="flex w-full items-center justify-between">
    <div class="px-4" @click="manageMenuMobileClick">
      <IconCloseMenu
        v-if="$modal.getModal('mobileMenu')"
        class="size-6 cursor-pointer"
        data-testid="menu-mobile-close-icon"
      />
      <IconHamburgerMenu
        v-else
        class="size-6 cursor-pointer"
        data-testid="menu-mobile-icon"
      />
    </div>
    <NuxtLink to="/" class="py-3" no-prefetch>
      <IconBenchmarkLogoMobile />
    </NuxtLink>
    <div
      v-if="!authenticated"
      class="bg-p-100 cursor-pointer text-xs font-semibold py-3 px-4"
      @click="manageSingInClick"
    >
      {{ SIGN_IN }}
    </div>
    <div
      v-else
      class="flex cursor-pointer select-none items-center justify-end py-3 px-4"
      @click="manageMobileAccountClick"
    >
      <IconUser class="h-6 w-6" />
    </div>

    <Teleport to="body">
      <NavigationMobileMenu
        :open="$modal.getModal('mobileMenu')"
        :menu="itemsMenu"
        modal-name="mobileMenu"
      />
      <NavigationMobileMenu
        :open="$modal.getModal('mobileAccountMenu')"
        :menu="ACCOUNT_MENU"
        initial-menu-title="Account"
        modal-name="mobileAccountMenu"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
/** constants */
import { ACCOUNT_MENU, MOBILE_MENU } from '~/constants/main-menu'
import { SIGN_IN } from '~/constants/services/constants'

/** composables */
import { useScreenSize } from '~/composables/useScreenSize'

/** stores */
import useAuthStore from '~/stores/auth'

/** components */
import NavigationMobileMenu from '~/components/navigation/MobileMenu.vue'
import IconBenchmarkLogoMobile from '~/assets/icons/BenchmarkLogoBlackMobile.svg'
import IconCloseMenu from '~/assets/icons/Close.svg'
import IconHamburgerMenu from '~/assets/icons/Hamburger.svg'
import IconUser from '~/assets/icons/User.svg'

/** app */
const { $config, $modal } = useNuxtApp()
const authStore = useAuthStore()
const { isNotDesktop } = useScreenSize()

/** refs */
const sourceLink = $config.public.sourceUrl

/** computed */
const authenticated = computed(() => authStore.authenticated)
const itemsMenu = computed(() => {
  return MOBILE_MENU.map((item) => {
    return {
      ...item,
      groups: item.groups?.map((group) => {
        return {
          ...group,
          children: group.children?.map((item) => {
            return {
              ...item,
              href:
                item.name?.toLowerCase() === 'source' ? sourceLink : item.href,
            }
          }),
        }
      }),
    }
  })
})

/** methods */
const manageMenuMobileClick = () => {
  if ($modal.getModal('mobileMenu')) {
    $modal.close('mobileMenu')
  } else {
    $modal.open('mobileMenu')
    $modal.close('mobileAccountMenu')
  }
}
const manageMobileAccountClick = () => {
  if ($modal.getModal('mobileAccountMenu')) {
    $modal.close('mobileAccountMenu')
  } else {
    $modal.open('mobileAccountMenu')
    $modal.close('mobileMenu')
  }
}
const manageSingInClick = () => {
  if ($modal.getModal('mobileMenu')) {
    $modal.close('mobileMenu')
  }
  $modal.open('signIn')
}

watch(isNotDesktop, (value) => {
  if (!value) {
    $modal.close('mobileMenu')
    $modal.close('mobileAccountMenu')
  }
})
</script>
